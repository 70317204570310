import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { lastValueFrom } from 'rxjs';
import { BottomsheetService } from 'src/app/services/bottomsheet.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from 'src/app/components/dialogs/help-dialog/help-dialog.component';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  showPass: boolean = false;
  username: string = "";
  password: string = "";
  otpCode: string = "";
  newPass: string = "";
  confirmPass: string = "";
  verificationCode: string = "";
  isLoading: boolean = false;
  isDemo: boolean = environment.demo;
  logo: string = environment.demo ? "/assets/logo/full-logo-s2e.png" : "/assets/logo/mini-logo-intesa-sanpaolo.png";
  logged!: boolean;
  orderId: any;
  formInvalid: boolean = false;
  isSpinnerActive: boolean = false;
  cognitoUser!: CognitoUser;
  otpRequired: boolean = false;
  authenticationDetails!: AuthenticationDetails;
  otpLoading: boolean = false;
  isSpinnerLoginReady: boolean = false;
  forgottenPass: boolean = false;
  poolData!: {
    UserPoolId: string; // Your user pool id here
    ClientId: string;
  };
  userPool!: CognitoUserPool | any;
  verificationCodeSent: boolean = false;
  checkVerificationCode: boolean = false;
  checkedPassword: boolean = false;
  showErrorPassMismatch: boolean = false;
  otpExpDate!: string;

  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _snackBar: MatSnackBar,
    private apiS: ApiService,
    private readonly authS: AuthService,
    private sharedVariables: SharedVariablesService,
    private router: Router,
    private bottomsheetS: BottomsheetService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    localStorage.clear();
    this.dialog.closeAll();
    if (this.authS.stopLogout) this.authS.stopLogout = false;
    if (environment.demo || this._auth.isUserLoggedIn) {
      this._router.navigate(["/dashboard"]);
    }
    this.poolData = {
      UserPoolId: environment.variables.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.variables.cognitoAppClientId, // Your client id here
    };
    this.userPool = new CognitoUserPool(this.poolData);
  }

  onSignIn() {
    if(this.username !== "" && this.password !== "" && (!this.otpLoading)) {
      this.otpLoading = true;
      this.formInvalid = false;

      this.username = this.username.trim();
      this.password = this.password.trim();

      this.authenticationDetails = new AuthenticationDetails({
        Username: this.username,
        Password: this.password,
      });

      let userData = {Username: this.username, Pool: this.userPool};
      this.cognitoUser = new CognitoUser(userData);

      if (environment.customAuth) this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
      else this.cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH');

      this.isSpinnerLoginReady = true;
      let that = this;
      this.cognitoUser.authenticateUser(this.authenticationDetails, {
        onSuccess: this.onSuccessFn(),
        onFailure: this.onFailureFn(),
        newPasswordRequired: function(userAttributes, requiredAttributes) {
          that._auth.setLocalUsername(that.username);
          that._snackBar.open("E' necessario cambiare la password temporanea prima di procedere con il login", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
          that.sharedVariables.changePasswordForceStatus = "active";
          that._router.navigate(["changepassword"]);
        },
        customChallenge: function(challengeParameters) {
          that.otpExpDate = challengeParameters.tokenExpiration;
          that.otpRequired = true;
          that.otpLoading = false;
          that.isSpinnerLoginReady = false;
        }

      });

    } else if (this.username === "" || this.password === "") this.formInvalid = true;
  }

  verifyOtp() {
    if (!this.otpLoading) {
      if (this.otpCode !== "") {
        if (new Date().getTime() >= new Date(this.otpExpDate).getTime()) {
          const sb = this._snackBar.open("Il codice OTP è scaduto", 'Chiudi', {duration: Infinity, panelClass: ['auth-snackbar']})
          sb.onAction().subscribe(() => {
            if(this.otpRequired) this.goBackToLogin();
          })
        } else {
          this.isSpinnerLoginReady = true;
          this.otpCode = this.otpCode.trim();
          this.formInvalid = false;
          this.cognitoUser.sendCustomChallengeAnswer(this.otpCode, {
            onSuccess: this.onSuccessFn(),
            onFailure: this.onFailureFn()
          });
        }
      } else this.formInvalid = true;
    }
  }

  onSuccessFn(){
    let that = this;
    return (result:any) => {
      this.isSpinnerLoginReady = true;
      that.isSpinnerActive = true;
      that._auth.isUserLoggedIn = true;
      that._auth.setLocalUsername(that.username);
      var accessToken = result.getAccessToken().getJwtToken();
      /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
      var idToken = result.idToken;
      that.sharedVariables.userData = {
        firstName: idToken.payload.given_name,
        lastName: idToken.payload.family_name,
        email: idToken.payload.email,
        role: idToken.payload["custom:role"],
        operationalUnit: idToken.payload["custom:operationalUnit"]
      }

      that.apiS.postAuthLogin(accessToken).subscribe({
        next: this.nextPostAuthLogin(that),
        error: async err => {
          if (err.status == 401) {
            await lastValueFrom(this.apiS.getRefreshToken()).then(
              (resp) => {
                that.apiS.postAuthLogin(accessToken).subscribe({
                  next: this.nextPostAuthLogin(that),
                  error: (err) => {
                    that.isSpinnerActive = false;
                    this.bottomsheetS.openBottomSheet(err, err);
                  }
                })
              },
              (err) => {
                this.authS.stopLogout = true;
                this.authS.logout(true);
                that.isSpinnerActive = false;
              }
            )
          } else if (String(err.status).slice(0, 2) === "50") {
            this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
            if (this.sharedVariables.onMaintenance) {
              that.isSpinnerActive = false;
              this.router.navigate(["/manutenzione"]);
            } else {
              that.isSpinnerActive = false;
              this.bottomsheetS.openBottomSheet(err, err);
            }
          } else {
            that.isSpinnerActive = false;
            this.bottomsheetS.openBottomSheet(err, err);
          }
        }
      })
    }
  }

  nextPostAuthLogin (that: any) {
    return (data: any) => {
      localStorage.clear()
      localStorage.setItem('user', that.username)
      localStorage.setItem('firstName', that.sharedVariables.userData.firstName)
      localStorage.setItem('lastName', that.sharedVariables.userData.lastName)
      localStorage.setItem('email', that.sharedVariables.userData.email)
      localStorage.setItem('role', that.sharedVariables.userData.role)
      localStorage.setItem('operationalUnit', that.sharedVariables.userData.operationalUnit)
      localStorage.setItem('isCognitoLogin', "true")
      that.isSpinnerActive = false;
      that.authS.subjectStart.next();
      that._router.navigate(["home"]);
      that.otpLoading = false;
      that.isSpinnerActive = false;
      this.isSpinnerLoginReady = false;
    }
  }

  onFailureFn() {
    let that = this;
    return (err: any) => {
      that.otpLoading = false;
      this.isSpinnerLoginReady = false;
      console.log(err);
      if (err.code == "UserNotConfirmedException") {
        that._snackBar.open("E' necessario verificare l'email prima di procedere con il login", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
        that._router.navigate(["verify"]);
      }
      else if (err.code == "NotAuthorizedException" || err.status == 400) {
        if (!that.otpRequired) {
          that._snackBar.open("Il nome utente e la password inserite non combaciano. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
        } else if (that.otpRequired) {
          that.otpRequired = false;
          that.username = '';
          that.password = '';
          that.otpCode = '';
          that._snackBar.open("Il codice OTP inserito non è corretto. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
        }
        that.isLoading = false;
        this.isSpinnerLoginReady = false;
      }
      else if (err.message == 'callback.customChallenge is not a function') {
        that._snackBar.open("Il codice OTP inserito non è corretto. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
      }
      else if (err.message == 'a.customChallenge is not a function') {
        that._snackBar.open("Il codice OTP inserito non è corretto. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
      }
      else {
        that._snackBar.open((err.message || JSON.stringify(err)), 'Chiudi',{duration: 3000, panelClass: ['auth-snackbar']});
        that.isLoading = false;
        this.isSpinnerLoginReady = false;
      }
    }
  }

  refreshOtp(){
    let that = this;
    if (environment.customAuth) this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
    else this.cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH');

    this.cognitoUser.authenticateUser(this.authenticationDetails, {
      onSuccess: this.onSuccessFn(),
      onFailure: this.onFailureFn(),
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        that._auth.setLocalUsername(that.username);
        that._snackBar.open("E' necessario cambiare la password temporanea prima di procedere con il login", 'Close', {duration: 3000, panelClass: ['auth-snackbar']})
        that.sharedVariables.changePasswordForceStatus = "active";
        that._router.navigate(["changepassword"]);
      },
      customChallenge: function(challengeParameters) {
        that.otpExpDate = challengeParameters.tokenExpiration;
        that._snackBar.open("Un nuovo codice è stato mandato alla tua email.", "", { duration: 3000, panelClass: ['auth-snackbar']})
        that.otpRequired = true;
        that.otpLoading = false;
      }
    });
  }

  forgotPassword(){
    let that = this;
    let userData = {Username: this.username, Pool: this.userPool};
    this.cognitoUser = new CognitoUser(userData);
    that.checkVerificationCode = true

    this.cognitoUser.forgotPassword({
      onSuccess: function(data) {
        // successfully initiated reset password request
        that._snackBar.open("Un codice è stato mandato alla tua email", "chiudi", { duration: 3000, panelClass: ['auth-snackbar'] })
        that.checkVerificationCode = false
        that.verificationCodeSent = true;
      },
      onFailure: function(err) {
        that.checkVerificationCode = false
          alert(err.message || JSON.stringify(err));
      },
      inputVerificationCode: function(data) {
        that._snackBar.open("Un codice è stato mandato alla tua email", "chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
        that.verificationCodeSent = true;
      },
    });
  }

  confirmForgotPass() {
    if (this.confirmPass !== '' || this.newPass !== '' || this.verificationCode !== '') {
      this.formInvalid = false;
      this.confirmPass = this.confirmPass.trim();
      this.newPass = this.newPass.trim();
      this.verificationCode = this.verificationCode.trim();
      if (this.confirmPass === this.newPass) {
        let that = this;
        that.checkedPassword = true;
        that.cognitoUser.confirmPassword(that.verificationCode, that.newPass, {
          onSuccess() {
            that.checkedPassword = false;
            that.changeStatusForgottenPass()
          },
          onFailure(err) {
            that.checkedPassword = false;
            that._snackBar.open(err.message, "chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
          },
        });
      }
    } else this.formInvalid = true;
  }

  goBackToLogin() {
    this.otpCode = "";
    this._snackBar.dismiss();
    this.otpRequired = false;
    this.isSpinnerLoginReady = false;
    this.password = '';
  }

  goBackToLoginFromForgotPw() {
    this.forgottenPass = !this.forgottenPass;
    this.verificationCodeSent = false;
    this.checkVerificationCode = false;
    this.password = '';
  }

  changeStatusForgottenPass() {
    this.formInvalid = false;
    this.forgottenPass = !this.forgottenPass;
    if (!this.forgottenPass) {
      this.username = "";
      this.verificationCodeSent = false;
    }
  }

  onFocusOutEvent(event: any){
    if (this.confirmPass !== this.newPass) {
      this.showErrorPassMismatch = true;
    } else this.showErrorPassMismatch = false;
  }

  showPassword() {
    this.showPass = !this.showPass;
  }

  openHelpDialog() {
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '400px'

    });
  }
}

