import { environment } from 'src/environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
// import { ViewportRuler } from '@angular/cdk/scrolling';
import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  configurationDemo: boolean = environment.demo;

  constructor() { }
}
