<mat-card class="faq-card" [class.mat-elevation-z0]="isFAQChecked">
    <mat-card-title style="margin-left: .4em;">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon class="icon" color="primary">picture_as_pdf</mat-icon>
                <span>Manuali e FAQ</span>
            </div>

            <div style="padding: 1em;">
                <mat-button-toggle-group [(ngModel)]="viewFAQClicked" (change)="handleChangeView()">
                    <mat-button-toggle value="table"><mat-icon>table_rows</mat-icon></mat-button-toggle>
                    <mat-button-toggle value="grid"><mat-icon>grid_view</mat-icon></mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </mat-card-title>

    <div fxLayout="row" fxLayoutAlign="center" id="spinner">
        <mat-spinner *ngIf="!isGetFaqApiReady"></mat-spinner>
    </div>

    <!-- TABLE -->
    <mat-card-content *ngIf="!isFAQChecked && isGetFaqApiReady">
        <table mat-table [dataSource]="faqDataSource" style="width: 100%;" aria-label="taballa FAQ e Documenti">

            <ng-container matColumnDef="Key">
                <th id="nome" mat-header-cell *matHeaderCellDef>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon color="accent" class="table-icon">insert_drive_file</mat-icon>
                        <span>Nome</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let faq">
                    <span (mouseenter)="isHovered = true" (mouseleave)="isHovered = false" (click)="downloadFAQ(faq, $event)"
                        class="pointer-link">
                        {{faq["Key"]}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="LastModified">
                <th id="ultima_modifica" mat-header-cell *matHeaderCellDef>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon color="accent" class="table-icon">edit_calendar</mat-icon>
                        <span>Ultima Modifica</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let faq">
                    {{faq["LastModified"]}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Download">
                <th id="dowload_icon" mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let faq">
                    <button mat-button>
                        <mat-icon color="accent" class="table-icon hover-download-icon">{{faq["Download"]}}</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr class="theader-color" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="example-element-row" mat-row *matRowDef="let myRowData; columns: displayedColumns"
                (click)="downloadFAQ(myRowData, $event)" class="pointer-link"></tr>
        </table>
    </mat-card-content>

    <!-- CARDs -->
    <mat-card-content *ngIf="isFAQChecked && isGetFaqApiReady">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
            <mat-card class="example-card" *ngFor="let item of faqDataSource.filteredData" style="margin: 2em"
                fxLayout="column" fxLayoutAlign="space-between start" (click)="downloadFAQ(item, $event)" class="pointer-link-card">
                <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: .5em;">
                    <mat-icon color="accent" style="padding-right: .5em;">picture_as_pdf</mat-icon>
                    <span class="pointer-link" style="font-size: small; word-break: break-word; font-weight: bold;">
                        {{item.Key}}
                    </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                    <span style="font-size: smaller; color: grey;">{{item.LastModified}}</span>
                    <button mat-button><mat-icon color="accent">download</mat-icon></button>
                </div>

            </mat-card>
        </div>
    </mat-card-content>
</mat-card>