<div class="general" fxLayout="column" fxLayoutAlign="center center">
  <mat-card class="login-container" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="space-between center"
    >
      <img class="logo-box" src="/assets/logo/mini-logo-intesa-sanpaolo.png" alt="" />
      <h1>Scegli una nuova password</h1>
    </div>

    <form
      *ngIf="!otpRequired"
      (submit)="onConfirm()"
      #signInForm="ngForm"
      fxLayout="column"
    >

    <mat-form-field class="example-form-field" appearance="outline">
      <mat-label>Inserisci la vecchia password</mat-label>
      <mat-icon (click)="showPassword()" matSuffix> {{showPass ? 'visibility_off' : 'visibility'}} </mat-icon>
      <input
      matInput
      id="first-input"
      name="old_password"
      [type]="showPass ? 'text' : 'password'"
      [(ngModel)]="oldPassword"
      required
      />
    </mat-form-field>

    <mat-form-field class="example-form-field" appearance="outline">
      <mat-label>Inserisci la nuova password</mat-label>
      <mat-icon (click)="showPassword()" matSuffix> {{showPass ? 'visibility_off' : 'visibility'}} </mat-icon>
      <input
      matInput
      name="new_password"
      [type]="showPass ? 'text' : 'password'"
      minlength="8"
      [(ngModel)]="newPassword"
      required
      />
    </mat-form-field>

    <mat-form-field class="example-form-field" appearance="outline">
      <mat-label>Conferma la nuova password</mat-label>
      <mat-icon (click)="showPassword()" matSuffix> {{showPass ? 'visibility_off' : 'visibility'}} </mat-icon>
      <input
      matInput
      name="password"
      [type]="showPass ? 'text' : 'password'"
      minlength="8"
      [(ngModel)]="confirmNewPassword"
      required
      />
    </mat-form-field>

      <button mat-raised-button type="submit" color="primary" [ngClass]="{'login-btn': !isSpinnerChangePassReady}" [disabled]="isSpinnerChangePassReady">
        <span *ngIf="!isSpinnerChangePassReady" class="login-label">Verifica</span>
        <div class="sk-circle" style="margin: 10px auto; height: 20px; width: 20px;" *ngIf="isSpinnerChangePassReady">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
      </button>

    </form>

    <form *ngIf="otpRequired"
      (submit)="verifyOtp()"
      fxLayout="column"
    >

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>Inserisci OTP</mat-label>
        <mat-icon matSuffix>vpn_key</mat-icon>
        <input
        matInput
        name="otp"
        type="text"
        [(ngModel)]="otpCode"
        required
        />
      </mat-form-field>

      <button mat-raised-button type="submit" color="primary" class="login-btn">
        <span class="login-label">Verifica</span>
      </button>

    </form>

    <div *ngIf="otpRequired" class="fake-link" (click)="refreshOtp()">
      Non hai ricevuto il codice per email? Clicca qui.
    </div>

    <span style="font-size: 12px;">
      <ul style="list-style: none; padding: 0;">
        <strong>La Password deve avere:</strong>
        <div style="display: flex;">
          <mat-icon [ngStyle]="newPassword.length >= 12 ? {'color':'green', 'font-size':'18px'} : {'color':'red', 'font-size':'18px'}">
            {{newPassword.length >= 12 ? 'done' : 'close'}}
          </mat-icon>
          <span>Lunghezza minima di dodici caratteri</span>
        </div>
        <div style="display: flex;">
          <mat-icon [ngStyle]="hasNumber(newPassword) ? {'color':'green', 'font-size':'18px'} : {'color':'red', 'font-size':'18px'}">
            {{hasNumber(newPassword) ? 'done' : 'close'}}
          </mat-icon>
          <span>Almeno un numero</span>
        </div>
        <div style="display: flex;">
          <mat-icon [ngStyle]="hasSpecialChar(newPassword) ? {'color':'green', 'font-size':'18px'} : {'color':'red', 'font-size':'18px'}">
            {{hasSpecialChar(newPassword) ? 'done' : 'close'}}
          </mat-icon>
          <span>Almeno un carattere speciale</span>
        </div>
        <div style="display: flex;">
          <mat-icon [ngStyle]="hasUppercase(newPassword) ? {'color':'green', 'font-size':'18px'} : {'color':'red', 'font-size':'18px'}">
            {{hasUppercase(newPassword) ? 'done' : 'close'}}
          </mat-icon>
          <span>Almeno una lettera maiuscola</span>
        </div>
      </ul>
    </span>

    <div fxLayout="column" fxLayoutAlign="end center" class="footer">
      <span>powered by</span>
      <img src="/assets/logo/mini-logo-s2e.png" alt="" class="logos2e" />
    </div>

  </mat-card>
</div>
