import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedVariablesService } from './../../services/shared-variables.service';
import { environment } from 'src/environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SettingsService } from 'src/app/services/settings.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { select } from 'd3';
import { NgxIndexedDBService } from 'ngx-indexed-db';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  dataReady: boolean = false;

  // nomi accettati = controvalori, prodotti-in-evidenza
  controllerElement: string[] = ["controvalori"]
  listaPolizze: any;
  statsPolizze: any;
  ctvSplit: any;
  dataAggiornamento: any;

  countClienti: any;
  countPolizze: any;
  ctvAllaData: any;
  ctvSottoscrizione: any;
  sintesiPortafoglio: any;
  subscription: Subscription;
  eventsSubject: Subject<number> = new Subject<number>();
  sidenavWidth: number = 175;
  dataBarChart: any;
  dashboardAllData: any;

  subOnInit!: Subscription;

  constructor(
    private apiS: ApiService,
    private sidenavServ: SidenavService,
    public readonly settingsServ: SettingsService,
    private titleService: Title,
    private sharedVariables: SharedVariablesService,
    private notifyServ: NotificationService,
    private route: ActivatedRoute,
    private dbService: NgxIndexedDBService

  ) {
    this.titleService.setTitle(environment.title + " | Dashboard");

    this.subscription = this.sidenavServ.receiveMessage().subscribe((margin: number) => {
      this.sidenavWidth = margin;
      this.eventsSubject.next(margin);
    })
  }

  async ngOnInit(): Promise<void> {

    this.subOnInit = this.notifyServ.onSubInitHome().subscribe(() => {
      this.toExecOnInit();
    })

    this.notifyServ.sendBackInitHome();
  }

  toExecOnInit() {
    this.dashboardAllData = this.sharedVariables.dashboardDataCache;
    this.countClienti = this.dashboardAllData.clienti_attivi;
    this.countPolizze = this.dashboardAllData.numero_polizze_attive;
    this.ctvAllaData = this.dashboardAllData.controvalore_polizze;
    this.ctvSottoscrizione = this.dashboardAllData.totale_premi_versati;
    this.sintesiPortafoglio = this.dashboardAllData.andamento_controvalore;
    this.dataAggiornamento = this.dashboardAllData.data_ultima_estrazione;
    this.dataBarChart = this.sharedVariables.barChartCache;

    this.dataReady = true;

    //if isLoginCognito is undefined the user logged via SSO
    let isLoginCognito = localStorage.getItem('isCognitoLogin');
    if (!isLoginCognito){
      localStorage.setItem('isCognitoLogin', "false")
    }

    //wait for element creation
    setTimeout(() => {
      for (const key in this.sharedVariables.activeTheme.properties) {
        //with d3
        select('#d3-tooltip').style(key, this.sharedVariables.activeTheme.properties[key]);

        // without d3
        // document.querySelectorAll("[class='d3-tooltip-barchart']").forEach((el: any) => {
        //   el.style.setProperty(key, this.sharedVariables.activeTheme.properties[key]);
        // });
      }
    }, 1);
  }

  ngOnDestroy(): void {
    if (this.subOnInit) this.subOnInit.unsubscribe();
    if (this.subscription) this.subscription.unsubscribe();
  }


  public get statusReady(): boolean {
    return this.sharedVariables.statusReady;
  }

  dataLineChart: {date: Date, value: number, premi_versati: number}[] = [
    {date: new Date('2010-01-01'), value: 210.73, premi_versati: 0},
    {date: new Date('2010-01-04'), value: 214.01, premi_versati: 0},
    {date: new Date('2010-01-05'), value: 214.38, premi_versati: 0},
    {date: new Date('2010-01-06'), value: 210.97, premi_versati: 0},
    {date: new Date('2010-01-07'), value: 210.58, premi_versati: 0},
    {date: new Date('2010-01-08'), value: 211.98, premi_versati: 0},
    {date: new Date('2010-01-11'), value: 210.11, premi_versati: 0},
    {date: new Date('2010-01-12'), value: 207.72, premi_versati: 0},
    {date: new Date('2010-01-13'), value: 210.65, premi_versati: 0},
    {date: new Date('2010-01-14'), value: 209.43, premi_versati: 0},
    {date: new Date('2010-01-15'), value: 205.93, premi_versati: 0},
    {date: new Date('2010-01-18'), value: 205.93, premi_versati: 0},
    {date: new Date('2010-01-19'), value: 215.04, premi_versati: 0},
    {date: new Date('2010-01-20'), value: 211.72, premi_versati: 0},
    {date: new Date('2010-01-21'), value: 208.07, premi_versati: 0},
    {date: new Date('2010-01-22'), value: 197.75, premi_versati: 0},
    {date: new Date('2010-01-25'), value: 203.08, premi_versati: 0},
    {date: new Date('2010-01-26'), value: 205.94, premi_versati: 0},
    {date: new Date('2010-01-27'), value: 207.88, premi_versati: 0},
    {date: new Date('2010-01-28'), value: 199.29, premi_versati: 0},
    {date: new Date('2010-01-29'), value: 192.06, premi_versati: 0},
    {date: new Date('2010-02-01'), value: 194.73, premi_versati: 0},
    {date: new Date('2010-02-02'), value: 195.86, premi_versati: 0},
    {date: new Date('2010-02-03'), value: 199.23, premi_versati: 0},
    {date: new Date('2010-02-04'), value: 192.05, premi_versati: 0},
    {date: new Date('2010-02-05'), value: 195.46, premi_versati: 0},
    {date: new Date('2010-02-08'), value: 194.12, premi_versati: 0},
    {date: new Date('2010-02-09'), value: 196.19, premi_versati: 0},
    {date: new Date('2010-02-10'), value: 195.12, premi_versati: 0},
    {date: new Date('2010-02-11'), value: 198.67, premi_versati: 0},
    {date: new Date('2010-02-12'), value: 200.38, premi_versati: 0},
    {date: new Date('2010-02-15'), value: 200.38, premi_versati: 0},
    {date: new Date('2010-02-16'), value: 203.40, premi_versati: 0},
    {date: new Date('2010-02-17'), value: 202.55, premi_versati: 0},
    {date: new Date('2010-02-18'), value: 202.93, premi_versati: 0},
    {date: new Date('2010-02-19'), value: 201.67, premi_versati: 0},
    {date: new Date('2010-02-22'), value: 200.42, premi_versati: 0},
    {date: new Date('2010-02-23'), value: 197.06, premi_versati: 0},
    {date: new Date('2010-02-24'), value: 200.66, premi_versati: 0},
    {date: new Date('2010-02-25'), value: 202.00, premi_versati: 0},
    {date: new Date('2010-02-26'), value: 204.62, premi_versati: 0},
    {date: new Date('2010-03-01'), value: 208.99, premi_versati: 0},
    {date: new Date('2010-03-02'), value: 208.85, premi_versati: 0},
    {date: new Date('2010-03-03'), value: 209.33, premi_versati: 0},
    {date: new Date('2010-03-04'), value: 210.71, premi_versati: 0},
    {date: new Date('2010-03-05'), value: 218.95, premi_versati: 0},
    {date: new Date('2010-03-08'), value: 219.08, premi_versati: 0},
    {date: new Date('2010-03-09'), value: 223.02, premi_versati: 0},
    {date: new Date('2010-03-10'), value: 224.84, premi_versati: 0},
    {date: new Date('2010-03-11'), value: 225.50, premi_versati: 0},
    {date: new Date('2010-03-12'), value: 226.60, premi_versati: 0},
    {date: new Date('2010-03-15'), value: 223.84, premi_versati: 0},
    {date: new Date('2010-03-16'), value: 224.45, premi_versati: 0},
    {date: new Date('2010-03-17'), value: 224.12, premi_versati: 0},
    {date: new Date('2010-03-18'), value: 224.65, premi_versati: 0},
    {date: new Date('2010-03-19'), value: 222.25, premi_versati: 0},
    {date: new Date('2010-03-22'), value: 224.75, premi_versati: 0},
    {date: new Date('2010-03-23'), value: 228.36, premi_versati: 0},
    {date: new Date('2010-03-24'), value: 229.37, premi_versati: 0},
    {date: new Date('2010-03-25'), value: 226.65, premi_versati: 0},
    {date: new Date('2010-03-26'), value: 230.90, premi_versati: 0},
    {date: new Date('2010-03-29'), value: 232.39, premi_versati: 0},
    {date: new Date('2010-03-30'), value: 235.84, premi_versati: 0},
    {date: new Date('2010-03-31'), value: 235.00, premi_versati: 0},
    {date: new Date('2010-04-01'), value: 235.97, premi_versati: 0},
    {date: new Date('2010-04-02'), value: 235.97, premi_versati: 0},
    {date: new Date('2010-04-05'), value: 238.49, premi_versati: 0},
    {date: new Date('2010-04-06'), value: 239.54, premi_versati: 0},
    {date: new Date('2010-04-07'), value: 240.60, premi_versati: 0},
    {date: new Date('2010-04-08'), value: 239.95, premi_versati: 0},
    {date: new Date('2010-04-09'), value: 241.79, premi_versati: 0},
    {date: new Date('2010-04-12'), value: 242.29, premi_versati: 0},
    {date: new Date('2010-04-13'), value: 242.43, premi_versati: 0},
    {date: new Date('2010-04-14'), value: 245.69, premi_versati: 0},
    {date: new Date('2010-04-15'), value: 248.92, premi_versati: 0},
    {date: new Date('2010-04-16'), value: 247.40, premi_versati: 0},
    {date: new Date('2010-04-19'), value: 247.07, premi_versati: 0},
    {date: new Date('2010-04-20'), value: 244.59, premi_versati: 0},
    {date: new Date('2010-04-21'), value: 259.22, premi_versati: 0},
    {date: new Date('2010-04-22'), value: 266.47, premi_versati: 0},
    {date: new Date('2010-04-23'), value: 270.83, premi_versati: 0},
    {date: new Date('2010-04-26'), value: 269.50, premi_versati: 0},
    {date: new Date('2010-04-27'), value: 262.04, premi_versati: 0},
    {date: new Date('2010-04-28'), value: 261.60, premi_versati: 0},
    {date: new Date('2010-04-29'), value: 268.64, premi_versati: 0},
    {date: new Date('2010-04-30'), value: 261.09, premi_versati: 0},
    {date: new Date('2010-05-03'), value: 266.35, premi_versati: 0},
    {date: new Date('2010-05-04'), value: 258.68, premi_versati: 0},
    {date: new Date('2010-05-05'), value: 255.98, premi_versati: 0},
    {date: new Date('2010-05-06'), value: 246.25, premi_versati: 0},
    {date: new Date('2010-05-07'), value: 235.86, premi_versati: 0},
    {date: new Date('2010-05-10'), value: 253.99, premi_versati: 0},
    {date: new Date('2010-05-11'), value: 256.52, premi_versati: 0},
    {date: new Date('2010-05-12'), value: 262.09, premi_versati: 0},
    {date: new Date('2010-05-13'), value: 258.36, premi_versati: 0},
    {date: new Date('2010-05-14'), value: 253.82, premi_versati: 0},
    {date: new Date('2010-05-17'), value: 254.22, premi_versati: 0},
    {date: new Date('2010-05-18'), value: 252.36, premi_versati: 0},
    {date: new Date('2010-05-19'), value: 248.34, premi_versati: 0},
    {date: new Date('2010-05-20'), value: 237.76, premi_versati: 0},
    {date: new Date('2010-05-21'), value: 242.32, premi_versati: 0},
    {date: new Date('2010-05-24'), value: 246.76, premi_versati: 0},
    {date: new Date('2010-05-25'), value: 245.22, premi_versati: 0},
    {date: new Date('2010-05-26'), value: 244.11, premi_versati: 0},
    {date: new Date('2010-05-27'), value: 253.35, premi_versati: 0},
    {date: new Date('2010-05-28'), value: 256.88, premi_versati: 0},
    {date: new Date('2010-05-31'), value: 256.88, premi_versati: 0},
    {date: new Date('2010-06-01'), value: 260.83, premi_versati: 0},
    {date: new Date('2010-06-02'), value: 263.95, premi_versati: 0},
    {date: new Date('2010-06-03'), value: 263.12, premi_versati: 0},
    {date: new Date('2010-06-04'), value: 255.96, premi_versati: 0},
    {date: new Date('2010-06-07'), value: 250.94, premi_versati: 0},
    {date: new Date('2010-06-08'), value: 249.33, premi_versati: 0},
    {date: new Date('2010-06-09'), value: 243.20, premi_versati: 0},
    {date: new Date('2010-06-10'), value: 250.51, premi_versati: 0},
    {date: new Date('2010-06-11'), value: 253.51, premi_versati: 0},
    {date: new Date('2010-06-14'), value: 254.28, premi_versati: 0},
    {date: new Date('2010-06-15'), value: 259.69, premi_versati: 0},
    {date: new Date('2010-06-16'), value: 267.25, premi_versati: 0},
    {date: new Date('2010-06-17'), value: 271.87, premi_versati: 0},
    {date: new Date('2010-06-18'), value: 274.07, premi_versati: 0},
    {date: new Date('2010-06-21'), value: 270.17, premi_versati: 0},
    {date: new Date('2010-06-22'), value: 273.85, premi_versati: 0},
    {date: new Date('2010-06-23'), value: 270.97, premi_versati: 0},
    {date: new Date('2010-06-24'), value: 269.00, premi_versati: 0},
    {date: new Date('2010-06-25'), value: 266.70, premi_versati: 0},
    {date: new Date('2010-06-28'), value: 268.30, premi_versati: 0},
    {date: new Date('2010-06-29'), value: 256.17, premi_versati: 0},
    {date: new Date('2010-06-30'), value: 251.53, premi_versati: 0},
    {date: new Date('2010-07-01'), value: 248.48, premi_versati: 0},
    {date: new Date('2010-07-02'), value: 246.94, premi_versati: 0},
    {date: new Date('2010-07-05'), value: 246.94, premi_versati: 0},
    {date: new Date('2010-07-06'), value: 248.63, premi_versati: 0},
    {date: new Date('2010-07-07'), value: 258.66, premi_versati: 0},
    {date: new Date('2010-07-08'), value: 258.09, premi_versati: 0},
    {date: new Date('2010-07-09'), value: 259.62, premi_versati: 0},
    {date: new Date('2010-07-12'), value: 257.28, premi_versati: 0},
    {date: new Date('2010-07-13'), value: 251.80, premi_versati: 0},
    {date: new Date('2010-07-14'), value: 252.73, premi_versati: 0},
    {date: new Date('2010-07-15'), value: 251.45, premi_versati: 0},
    {date: new Date('2010-07-16'), value: 249.90, premi_versati: 0},
    {date: new Date('2010-07-19'), value: 245.58, premi_versati: 0},
    {date: new Date('2010-07-20'), value: 251.89, premi_versati: 0},
    {date: new Date('2010-07-21'), value: 254.24, premi_versati: 0},
    {date: new Date('2010-07-22'), value: 259.02, premi_versati: 0},
    {date: new Date('2010-07-23'), value: 259.94, premi_versati: 0},
    {date: new Date('2010-07-26'), value: 259.28, premi_versati: 0},
    {date: new Date('2010-07-27'), value: 264.08, premi_versati: 0},
    {date: new Date('2010-07-28'), value: 260.96, premi_versati: 0},
    {date: new Date('2010-07-29'), value: 258.11, premi_versati: 0},
    {date: new Date('2010-07-30'), value: 257.25, premi_versati: 0},
    {date: new Date('2010-08-02'), value: 261.85, premi_versati: 0},
    {date: new Date('2010-08-03'), value: 261.93, premi_versati: 0},
    {date: new Date('2010-08-04'), value: 262.98, premi_versati: 0},
    {date: new Date('2010-08-05'), value: 261.70, premi_versati: 0},
    {date: new Date('2010-08-06'), value: 260.09, premi_versati: 0},
    {date: new Date('2010-08-09'), value: 261.75, premi_versati: 0},
    {date: new Date('2010-08-10'), value: 259.41, premi_versati: 0},
    {date: new Date('2010-08-11'), value: 250.19, premi_versati: 0},
    {date: new Date('2010-08-12'), value: 251.79, premi_versati: 0},
    {date: new Date('2010-08-13'), value: 249.10, premi_versati: 0},
    {date: new Date('2010-08-16'), value: 247.64, premi_versati: 0},
    {date: new Date('2010-08-17'), value: 251.97, premi_versati: 0},
    {date: new Date('2010-08-18'), value: 253.07, premi_versati: 0},
    {date: new Date('2010-08-19'), value: 249.88, premi_versati: 0},
    {date: new Date('2010-08-20'), value: 249.64, premi_versati: 0},
    {date: new Date('2010-08-23'), value: 245.80, premi_versati: 0},
    {date: new Date('2010-08-24'), value: 239.93, premi_versati: 0},
    {date: new Date('2010-08-25'), value: 242.89, premi_versati: 0},
    {date: new Date('2010-08-26'), value: 240.28, premi_versati: 0},
    {date: new Date('2010-08-27'), value: 241.62, premi_versati: 0},
    {date: new Date('2010-08-30'), value: 242.50, premi_versati: 0},
    {date: new Date('2010-08-31'), value: 243.10, premi_versati: 0},
    {date: new Date('2010-09-01'), value: 250.33, premi_versati: 0},
    {date: new Date('2010-09-02'), value: 252.17, premi_versati: 0},
    {date: new Date('2010-09-03'), value: 258.77, premi_versati: 0},
    {date: new Date('2010-09-06'), value: 258.77, premi_versati: 0},
    {date: new Date('2010-09-07'), value: 257.81, premi_versati: 0},
    {date: new Date('2010-09-08'), value: 262.92, premi_versati: 0},
    {date: new Date('2010-09-09'), value: 263.07, premi_versati: 0},
    {date: new Date('2010-09-10'), value: 263.41, premi_versati: 0},
    {date: new Date('2010-09-13'), value: 267.04, premi_versati: 0},
    {date: new Date('2010-09-14'), value: 268.06, premi_versati: 0},
    {date: new Date('2010-09-15'), value: 270.22, premi_versati: 0},
    {date: new Date('2010-09-16'), value: 276.57, premi_versati: 0},
    {date: new Date('2010-09-17'), value: 275.37, premi_versati: 0},
    {date: new Date('2010-09-20'), value: 283.23, premi_versati: 0},
    {date: new Date('2010-09-21'), value: 283.77, premi_versati: 0},
    {date: new Date('2010-09-22'), value: 287.75, premi_versati: 0},
    {date: new Date('2010-09-23'), value: 288.92, premi_versati: 0},
    {date: new Date('2010-09-24'), value: 292.32, premi_versati: 0},
    {date: new Date('2010-09-27'), value: 291.16, premi_versati: 0},
    {date: new Date('2010-09-28'), value: 286.86, premi_versati: 0},
    {date: new Date('2010-09-29'), value: 287.37, premi_versati: 0},
    {date: new Date('2010-09-30'), value: 283.75, premi_versati: 0},
    {date: new Date('2010-10-01'), value: 282.52, premi_versati: 0},
    {date: new Date('2010-10-04'), value: 278.64, premi_versati: 0},
    {date: new Date('2010-10-05'), value: 288.94, premi_versati: 0},
    {date: new Date('2010-10-06'), value: 289.19, premi_versati: 0},
    {date: new Date('2010-10-07'), value: 289.22, premi_versati: 0},
    {date: new Date('2010-10-08'), value: 294.07, premi_versati: 0},
    {date: new Date('2010-10-11'), value: 295.36, premi_versati: 0},
    {date: new Date('2010-10-12'), value: 298.54, premi_versati: 0},
    {date: new Date('2010-10-13'), value: 300.14, premi_versati: 0},
    {date: new Date('2010-10-14'), value: 302.31, premi_versati: 0},
    {date: new Date('2010-10-15'), value: 314.74, premi_versati: 0},
    {date: new Date('2010-10-18'), value: 318.00, premi_versati: 0},
    {date: new Date('2010-10-19'), value: 309.49, premi_versati: 0},
    {date: new Date('2010-10-20'), value: 310.53, premi_versati: 0},
    {date: new Date('2010-10-21'), value: 309.52, premi_versati: 0},
    {date: new Date('2010-10-22'), value: 307.47, premi_versati: 0},
    {date: new Date('2010-10-25'), value: 308.84, premi_versati: 0},
    {date: new Date('2010-10-26'), value: 308.05, premi_versati: 0},
    {date: new Date('2010-10-27'), value: 307.83, premi_versati: 0},
    {date: new Date('2010-10-28'), value: 305.24, premi_versati: 0},
    {date: new Date('2010-10-29'), value: 300.98, premi_versati: 0},
    {date: new Date('2010-11-01'), value: 304.18, premi_versati: 0},
    {date: new Date('2010-11-02'), value: 309.36, premi_versati: 0},
    {date: new Date('2010-11-03'), value: 312.80, premi_versati: 0},
    {date: new Date('2010-11-04'), value: 318.27, premi_versati: 0},
    {date: new Date('2010-11-05'), value: 317.13, premi_versati: 0},
    {date: new Date('2010-11-08'), value: 318.62, premi_versati: 0},
    {date: new Date('2010-11-09'), value: 316.08, premi_versati: 0},
    {date: new Date('2010-11-10'), value: 318.03, premi_versati: 0},
    {date: new Date('2010-11-11'), value: 316.66, premi_versati: 0},
    {date: new Date('2010-11-12'), value: 308.03, premi_versati: 0},
    {date: new Date('2010-11-15'), value: 307.04, premi_versati: 0},
    {date: new Date('2010-11-16'), value: 301.59, premi_versati: 0},
    {date: new Date('2010-11-17'), value: 300.50, premi_versati: 0},
    {date: new Date('2010-11-18'), value: 308.43, premi_versati: 0},
    {date: new Date('2010-11-19'), value: 306.73, premi_versati: 0},
    {date: new Date('2010-11-22'), value: 313.36, premi_versati: 0},
    {date: new Date('2010-11-23'), value: 308.73, premi_versati: 0},
    {date: new Date('2010-11-24'), value: 314.80, premi_versati: 0},
    {date: new Date('2010-11-26'), value: 315.00, premi_versati: 0},
    {date: new Date('2010-11-29'), value: 316.87, premi_versati: 0},
    {date: new Date('2010-11-30'), value: 311.15, premi_versati: 0},
    {date: new Date('2010-12-01'), value: 316.40, premi_versati: 0},
    {date: new Date('2010-12-02'), value: 318.15, premi_versati: 0},
    {date: new Date('2010-12-03'), value: 317.44, premi_versati: 0},
    {date: new Date('2010-12-06'), value: 320.15, premi_versati: 0},
    {date: new Date('2010-12-07'), value: 318.21, premi_versati: 0},
    {date: new Date('2010-12-08'), value: 321.01, premi_versati: 0},
    {date: new Date('2010-12-09'), value: 319.76, premi_versati: 0},
    {date: new Date('2010-12-10'), value: 320.56, premi_versati: 0},
    {date: new Date('2010-12-13'), value: 321.67, premi_versati: 0},
    {date: new Date('2010-12-14'), value: 320.29, premi_versati: 0},
    {date: new Date('2010-12-15'), value: 320.36, premi_versati: 0},
    {date: new Date('2010-12-16'), value: 321.25, premi_versati: 0},
    {date: new Date('2010-12-17'), value: 320.61, premi_versati: 0},
    {date: new Date('2010-12-20'), value: 322.21, premi_versati: 0},
    {date: new Date('2010-12-21'), value: 324.20, premi_versati: 0},
    {date: new Date('2010-12-22'), value: 325.16, premi_versati: 0},
    {date: new Date('2010-12-23'), value: 323.60, premi_versati: 0},
    {date: new Date('2010-12-27'), value: 324.68, premi_versati: 0},
    {date: new Date('2010-12-28'), value: 325.47, premi_versati: 0},
    {date: new Date('2010-12-29'), value: 325.29, premi_versati: 0},
    {date: new Date('2010-12-30'), value: 323.66, premi_versati: 0},
    {date: new Date('2010-12-31'), value: 322.56, premi_versati: 0},
    {date: new Date('2011-01-03'), value: 329.57, premi_versati: 0},
    {date: new Date('2011-01-04'), value: 331.29, premi_versati: 0},
    {date: new Date('2011-01-05'), value: 334.00, premi_versati: 0},
    {date: new Date('2011-01-06'), value: 333.73, premi_versati: 0},
    {date: new Date('2011-01-07'), value: 336.12, premi_versati: 0},
    {date: new Date('2011-01-10'), value: 342.46, premi_versati: 0},
    {date: new Date('2011-01-11'), value: 341.64, premi_versati: 0},
    {date: new Date('2011-01-12'), value: 344.42, premi_versati: 0},
    {date: new Date('2011-01-13'), value: 345.68, premi_versati: 0},
    {date: new Date('2011-01-14'), value: 348.48, premi_versati: 0},
    {date: new Date('2011-01-18'), value: 340.65, premi_versati: 0},
    {date: new Date('2011-01-19'), value: 338.84, premi_versati: 0},
    {date: new Date('2011-01-20'), value: 332.68, premi_versati: 0},
    {date: new Date('2011-01-21'), value: 326.72, premi_versati: 0},
    {date: new Date('2011-01-24'), value: 337.45, premi_versati: 0},
    {date: new Date('2011-01-25'), value: 341.40, premi_versati: 0},
    {date: new Date('2011-01-26'), value: 343.85, premi_versati: 0},
    {date: new Date('2011-01-27'), value: 343.21, premi_versati: 0},
    {date: new Date('2011-01-28'), value: 336.10, premi_versati: 0},
    {date: new Date('2011-01-31'), value: 339.32, premi_versati: 0},
    {date: new Date('2011-02-01'), value: 345.03, premi_versati: 0},
    {date: new Date('2011-02-02'), value: 344.32, premi_versati: 0},
    {date: new Date('2011-02-03'), value: 343.44, premi_versati: 0},
    {date: new Date('2011-02-04'), value: 346.50, premi_versati: 0},
    {date: new Date('2011-02-07'), value: 351.88, premi_versati: 0},
    {date: new Date('2011-02-08'), value: 355.20, premi_versati: 0},
    {date: new Date('2011-02-09'), value: 358.16, premi_versati: 0},
    {date: new Date('2011-02-10'), value: 354.54, premi_versati: 0},
    {date: new Date('2011-02-11'), value: 356.85, premi_versati: 0},
    {date: new Date('2011-02-14'), value: 359.18, premi_versati: 0},
    {date: new Date('2011-02-15'), value: 359.90, premi_versati: 0},
    {date: new Date('2011-02-16'), value: 363.13, premi_versati: 0},
    {date: new Date('2011-02-17'), value: 358.30, premi_versati: 0},
    {date: new Date('2011-02-18'), value: 350.56, premi_versati: 0},
    {date: new Date('2011-02-22'), value: 338.61, premi_versati: 0},
    {date: new Date('2011-02-23'), value: 342.62, premi_versati: 0},
    {date: new Date('2011-02-24'), value: 342.88, premi_versati: 0},
    {date: new Date('2011-02-25'), value: 348.16, premi_versati: 0},
    {date: new Date('2011-02-28'), value: 353.21, premi_versati: 0},
    {date: new Date('2011-03-01'), value: 349.31, premi_versati: 0},
    {date: new Date('2011-03-02'), value: 352.12, premi_versati: 0},
    {date: new Date('2011-03-03'), value: 359.56, premi_versati: 0},
    {date: new Date('2011-03-04'), value: 360.00, premi_versati: 0},
    {date: new Date('2011-03-07'), value: 355.36, premi_versati: 0},
    {date: new Date('2011-03-08'), value: 355.76, premi_versati: 0},
    {date: new Date('2011-03-09'), value: 352.47, premi_versati: 0},
    {date: new Date('2011-03-10'), value: 346.67, premi_versati: 0},
    {date: new Date('2011-03-11'), value: 351.99, premi_versati: 0},
    {date: new Date('2011-03-14'), value: 353.56, premi_versati: 0},
    {date: new Date('2011-03-15'), value: 345.43, premi_versati: 0},
    {date: new Date('2011-03-16'), value: 330.01, premi_versati: 0},
    {date: new Date('2011-03-17'), value: 334.64, premi_versati: 0},
    {date: new Date('2011-03-18'), value: 330.67, premi_versati: 0},
    {date: new Date('2011-03-21'), value: 339.30, premi_versati: 0},
    {date: new Date('2011-03-22'), value: 341.20, premi_versati: 0},
    {date: new Date('2011-03-23'), value: 339.19, premi_versati: 0},
    {date: new Date('2011-03-24'), value: 344.97, premi_versati: 0},
    {date: new Date('2011-03-25'), value: 351.54, premi_versati: 0},
    {date: new Date('2011-03-28'), value: 350.44, premi_versati: 0},
    {date: new Date('2011-03-29'), value: 350.96, premi_versati: 0},
    {date: new Date('2011-03-30'), value: 348.63, premi_versati: 0},
    {date: new Date('2011-03-31'), value: 348.51, premi_versati: 0},
    {date: new Date('2011-04-01'), value: 344.56, premi_versati: 0},
    {date: new Date('2011-04-04'), value: 341.19, premi_versati: 0},
    {date: new Date('2011-04-05'), value: 338.89, premi_versati: 0},
    {date: new Date('2011-04-06'), value: 338.04, premi_versati: 0},
    {date: new Date('2011-04-07'), value: 338.08, premi_versati: 0},
    {date: new Date('2011-04-08'), value: 335.06, premi_versati: 0},
    {date: new Date('2011-04-11'), value: 330.80, premi_versati: 0},
    {date: new Date('2011-04-12'), value: 332.40, premi_versati: 0},
    {date: new Date('2011-04-13'), value: 336.13, premi_versati: 0},
    {date: new Date('2011-04-14'), value: 332.42, premi_versati: 0},
    {date: new Date('2011-04-15'), value: 327.46, premi_versati: 0},
    {date: new Date('2011-04-18'), value: 331.85, premi_versati: 0},
    {date: new Date('2011-04-19'), value: 337.86, premi_versati: 0},
    {date: new Date('2011-04-20'), value: 342.41, premi_versati: 0},
    {date: new Date('2011-04-21'), value: 350.70, premi_versati: 0},
    {date: new Date('2011-04-25'), value: 353.01, premi_versati: 0},
    {date: new Date('2011-04-26'), value: 350.42, premi_versati: 0},
    {date: new Date('2011-04-27'), value: 350.15, premi_versati: 0},
    {date: new Date('2011-04-28'), value: 346.75, premi_versati: 0},
    {date: new Date('2011-04-29'), value: 350.13, premi_versati: 0},
    {date: new Date('2011-05-02'), value: 346.28, premi_versati: 0},
    {date: new Date('2011-05-03'), value: 348.20, premi_versati: 0},
    {date: new Date('2011-05-04'), value: 349.57, premi_versati: 0},
    {date: new Date('2011-05-05'), value: 346.75, premi_versati: 0},
    {date: new Date('2011-05-06'), value: 346.66, premi_versati: 0},
    {date: new Date('2011-05-09'), value: 347.60, premi_versati: 0},
    {date: new Date('2011-05-10'), value: 349.45, premi_versati: 0},
    {date: new Date('2011-05-11'), value: 347.23, premi_versati: 0},
    {date: new Date('2011-05-12'), value: 346.57, premi_versati: 0},
    {date: new Date('2011-05-13'), value: 340.50, premi_versati: 0},
    {date: new Date('2011-05-16'), value: 333.30, premi_versati: 0},
    {date: new Date('2011-05-17'), value: 336.14, premi_versati: 0},
    {date: new Date('2011-05-18'), value: 339.87, premi_versati: 0},
    {date: new Date('2011-05-19'), value: 340.53, premi_versati: 0},
    {date: new Date('2011-05-20'), value: 335.22, premi_versati: 0},
    {date: new Date('2011-05-23'), value: 334.40, premi_versati: 0},
    {date: new Date('2011-05-24'), value: 332.19, premi_versati: 0},
    {date: new Date('2011-05-25'), value: 336.78, premi_versati: 0},
    {date: new Date('2011-05-26'), value: 335.00, premi_versati: 0},
    {date: new Date('2011-05-27'), value: 337.41, premi_versati: 0},
    {date: new Date('2011-05-31'), value: 347.83, premi_versati: 0},
    {date: new Date('2011-06-01'), value: 345.51, premi_versati: 0},
    {date: new Date('2011-06-02'), value: 346.10, premi_versati: 0},
    {date: new Date('2011-06-03'), value: 343.44, premi_versati: 0},
    {date: new Date('2011-06-06'), value: 338.04, premi_versati: 0},
    {date: new Date('2011-06-07'), value: 332.04, premi_versati: 0},
    {date: new Date('2011-06-08'), value: 332.24, premi_versati: 0},
    {date: new Date('2011-06-09'), value: 331.49, premi_versati: 0},
    {date: new Date('2011-06-10'), value: 325.90, premi_versati: 0},
    {date: new Date('2011-06-13'), value: 326.60, premi_versati: 0},
    {date: new Date('2011-06-14'), value: 332.44, premi_versati: 0},
    {date: new Date('2011-06-15'), value: 326.75, premi_versati: 0},
    {date: new Date('2011-06-16'), value: 325.16, premi_versati: 0},
    {date: new Date('2011-06-17'), value: 320.26, premi_versati: 0},
    {date: new Date('2011-06-20'), value: 315.32, premi_versati: 0},
    {date: new Date('2011-06-21'), value: 325.30, premi_versati: 0},
    {date: new Date('2011-06-22'), value: 322.61, premi_versati: 0},
    {date: new Date('2011-06-23'), value: 331.23, premi_versati: 0},
    {date: new Date('2011-06-24'), value: 326.35, premi_versati: 0},
    {date: new Date('2011-06-27'), value: 332.04, premi_versati: 0},
    {date: new Date('2011-06-28'), value: 335.26, premi_versati: 0},
    {date: new Date('2011-06-29'), value: 334.04, premi_versati: 0},
    {date: new Date('2011-06-30'), value: 335.67, premi_versati: 0},
    {date: new Date('2011-07-01'), value: 343.26, premi_versati: 0},
    {date: new Date('2011-07-05'), value: 349.43, premi_versati: 0},
    {date: new Date('2011-07-06'), value: 351.76, premi_versati: 0},
    {date: new Date('2011-07-07'), value: 357.20, premi_versati: 0},
    {date: new Date('2011-07-08'), value: 359.71, premi_versati: 0},
    {date: new Date('2011-07-11'), value: 354.00, premi_versati: 0},
    {date: new Date('2011-07-12'), value: 353.75, premi_versati: 0},
    {date: new Date('2011-07-13'), value: 358.02, premi_versati: 0},
    {date: new Date('2011-07-14'), value: 357.77, premi_versati: 0},
    {date: new Date('2011-07-15'), value: 364.92, premi_versati: 0},
    {date: new Date('2011-07-18'), value: 373.80, premi_versati: 0},
    {date: new Date('2011-07-19'), value: 376.85, premi_versati: 0},
    {date: new Date('2011-07-20'), value: 386.90, premi_versati: 0},
    {date: new Date('2011-07-21'), value: 387.29, premi_versati: 0},
    {date: new Date('2011-07-22'), value: 393.30, premi_versati: 0},
    {date: new Date('2011-07-25'), value: 398.50, premi_versati: 0},
    {date: new Date('2011-07-26'), value: 403.41, premi_versati: 0},
    {date: new Date('2011-07-27'), value: 392.59, premi_versati: 0},
    {date: new Date('2011-07-28'), value: 391.82, premi_versati: 0},
    {date: new Date('2011-07-29'), value: 390.48, premi_versati: 0},
    {date: new Date('2011-08-01'), value: 396.75, premi_versati: 0},
    {date: new Date('2011-08-02'), value: 388.91, premi_versati: 0},
    {date: new Date('2011-08-03'), value: 392.57, premi_versati: 0},
    {date: new Date('2011-08-04'), value: 377.37, premi_versati: 0},
    {date: new Date('2011-08-05'), value: 373.62, premi_versati: 0},
    {date: new Date('2011-08-08'), value: 353.21, premi_versati: 0},
    {date: new Date('2011-08-09'), value: 374.01, premi_versati: 0},
    {date: new Date('2011-08-10'), value: 363.69, premi_versati: 0},
    {date: new Date('2011-08-11'), value: 373.70, premi_versati: 0},
    {date: new Date('2011-08-12'), value: 376.99, premi_versati: 0},
    {date: new Date('2011-08-15'), value: 383.41, premi_versati: 0},
    {date: new Date('2011-08-16'), value: 380.48, premi_versati: 0},
    {date: new Date('2011-08-17'), value: 380.44, premi_versati: 0},
    {date: new Date('2011-08-18'), value: 366.05, premi_versati: 0},
    {date: new Date('2011-08-19'), value: 356.03, premi_versati: 0},
    {date: new Date('2011-08-22'), value: 356.44, premi_versati: 0},
    {date: new Date('2011-08-23'), value: 373.60, premi_versati: 0},
    {date: new Date('2011-08-24'), value: 376.18, premi_versati: 0},
    {date: new Date('2011-08-25'), value: 373.72, premi_versati: 0},
    {date: new Date('2011-08-26'), value: 383.58, premi_versati: 0},
    {date: new Date('2011-08-29'), value: 389.97, premi_versati: 0},
    {date: new Date('2011-08-30'), value: 389.99, premi_versati: 0},
    {date: new Date('2011-08-31'), value: 384.83, premi_versati: 0},
    {date: new Date('2011-09-01'), value: 381.03, premi_versati: 0},
    {date: new Date('2011-09-02'), value: 374.05, premi_versati: 0},
    {date: new Date('2011-09-06'), value: 379.74, premi_versati: 0},
    {date: new Date('2011-09-07'), value: 383.93, premi_versati: 0},
    {date: new Date('2011-09-08'), value: 384.14, premi_versati: 0},
    {date: new Date('2011-09-09'), value: 377.48, premi_versati: 0},
    {date: new Date('2011-09-12'), value: 379.94, premi_versati: 0},
    {date: new Date('2011-09-13'), value: 384.62, premi_versati: 0},
    {date: new Date('2011-09-14'), value: 389.30, premi_versati: 0},
    {date: new Date('2011-09-15'), value: 392.96, premi_versati: 0},
    {date: new Date('2011-09-16'), value: 400.50, premi_versati: 0},
    {date: new Date('2011-09-19'), value: 411.63, premi_versati: 0},
    {date: new Date('2011-09-20'), value: 413.45, premi_versati: 0},
    {date: new Date('2011-09-21'), value: 412.14, premi_versati: 0},
    {date: new Date('2011-09-22'), value: 401.82, premi_versati: 0},
    {date: new Date('2011-09-23'), value: 404.30, premi_versati: 0},
    {date: new Date('2011-09-26'), value: 403.17, premi_versati: 0},
    {date: new Date('2011-09-27'), value: 399.26, premi_versati: 0},
    {date: new Date('2011-09-28'), value: 397.01, premi_versati: 0},
    {date: new Date('2011-09-29'), value: 390.57, premi_versati: 0},
    {date: new Date('2011-09-30'), value: 381.32, premi_versati: 0},
    {date: new Date('2011-10-03'), value: 374.60, premi_versati: 0},
    {date: new Date('2011-10-04'), value: 372.50, premi_versati: 0},
    {date: new Date('2011-10-05'), value: 378.25, premi_versati: 0},
    {date: new Date('2011-10-06'), value: 377.37, premi_versati: 0},
    {date: new Date('2011-10-07'), value: 369.80, premi_versati: 0},
    {date: new Date('2011-10-10'), value: 388.81, premi_versati: 0},
    {date: new Date('2011-10-11'), value: 400.29, premi_versati: 0},
    {date: new Date('2011-10-12'), value: 402.19, premi_versati: 0},
    {date: new Date('2011-10-13'), value: 408.43, premi_versati: 0},
    {date: new Date('2011-10-14'), value: 422.00, premi_versati: 0},
    {date: new Date('2011-10-17'), value: 419.99, premi_versati: 0},
    {date: new Date('2011-10-18'), value: 422.24, premi_versati: 0},
    {date: new Date('2011-10-19'), value: 398.62, premi_versati: 0},
    {date: new Date('2011-10-20'), value: 395.31, premi_versati: 0},
    {date: new Date('2011-10-21'), value: 392.87, premi_versati: 0},
    {date: new Date('2011-10-24'), value: 405.77, premi_versati: 0},
    {date: new Date('2011-10-25'), value: 397.77, premi_versati: 0},
    {date: new Date('2011-10-26'), value: 400.60, premi_versati: 0},
    {date: new Date('2011-10-27'), value: 404.69, premi_versati: 0},
    {date: new Date('2011-10-28'), value: 404.95, premi_versati: 0},
    {date: new Date('2011-10-31'), value: 404.78, premi_versati: 0},
    {date: new Date('2011-11-01'), value: 396.51, premi_versati: 0},
    {date: new Date('2011-11-02'), value: 397.41, premi_versati: 0},
    {date: new Date('2011-11-03'), value: 403.07, premi_versati: 0},
    {date: new Date('2011-11-04'), value: 400.24, premi_versati: 0},
    {date: new Date('2011-11-07'), value: 399.73, premi_versati: 0},
    {date: new Date('2011-11-08'), value: 406.23, premi_versati: 0},
    {date: new Date('2011-11-09'), value: 395.28, premi_versati: 0},
    {date: new Date('2011-11-10'), value: 385.22, premi_versati: 0},
    {date: new Date('2011-11-11'), value: 384.62, premi_versati: 0},
    {date: new Date('2011-11-14'), value: 379.26, premi_versati: 0},
    {date: new Date('2011-11-15'), value: 388.83, premi_versati: 0},
    {date: new Date('2011-11-16'), value: 384.77, premi_versati: 0},
    {date: new Date('2011-11-17'), value: 377.41, premi_versati: 0},
    {date: new Date('2011-11-18'), value: 374.94, premi_versati: 0},
    {date: new Date('2011-11-21'), value: 369.01, premi_versati: 0},
    {date: new Date('2011-11-22'), value: 376.51, premi_versati: 0},
    {date: new Date('2011-11-23'), value: 366.99, premi_versati: 0},
    {date: new Date('2011-11-25'), value: 363.57, premi_versati: 0},
    {date: new Date('2011-11-28'), value: 376.12, premi_versati: 0},
    {date: new Date('2011-11-29'), value: 373.20, premi_versati: 0},
    {date: new Date('2011-11-30'), value: 382.20, premi_versati: 0},
    {date: new Date('2011-12-01'), value: 387.93, premi_versati: 0},
    {date: new Date('2011-12-02'), value: 389.70, premi_versati: 0},
    {date: new Date('2011-12-05'), value: 393.01, premi_versati: 0},
    {date: new Date('2011-12-06'), value: 390.95, premi_versati: 0},
    {date: new Date('2011-12-07'), value: 389.09, premi_versati: 0},
    {date: new Date('2011-12-08'), value: 390.66, premi_versati: 0},
    {date: new Date('2011-12-09'), value: 393.62, premi_versati: 0},
    {date: new Date('2011-12-12'), value: 391.84, premi_versati: 0},
    {date: new Date('2011-12-13'), value: 388.81, premi_versati: 0},
    {date: new Date('2011-12-14'), value: 380.19, premi_versati: 0},
    {date: new Date('2011-12-15'), value: 378.94, premi_versati: 0},
    {date: new Date('2011-12-16'), value: 381.02, premi_versati: 0},
    {date: new Date('2011-12-19'), value: 382.21, premi_versati: 0},
    {date: new Date('2011-12-20'), value: 395.95, premi_versati: 0},
    {date: new Date('2011-12-21'), value: 396.44, premi_versati: 0},
    {date: new Date('2011-12-22'), value: 398.55, premi_versati: 0},
    {date: new Date('2011-12-23'), value: 403.43, premi_versati: 0},
    {date: new Date('2011-12-27'), value: 406.53, premi_versati: 0},
    {date: new Date('2011-12-28'), value: 402.64, premi_versati: 0},
    {date: new Date('2011-12-29'), value: 405.12, premi_versati: 0},
    {date: new Date('2011-12-30'), value: 405.00, premi_versati: 0},
    {date: new Date('2012-01-03'), value: 411.23, premi_versati: 0},
    {date: new Date('2012-01-04'), value: 413.44, premi_versati: 0},
    {date: new Date('2012-01-05'), value: 418.03, premi_versati: 0},
    {date: new Date('2012-01-06'), value: 422.40, premi_versati: 0},
    {date: new Date('2012-01-09'), value: 421.73, premi_versati: 0},
    {date: new Date('2012-01-10'), value: 423.24, premi_versati: 0},
    {date: new Date('2012-01-11'), value: 422.55, premi_versati: 0},
    {date: new Date('2012-01-12'), value: 421.39, premi_versati: 0},
    {date: new Date('2012-01-13'), value: 419.81, premi_versati: 0},
    {date: new Date('2012-01-17'), value: 424.70, premi_versati: 0},
    {date: new Date('2012-01-18'), value: 429.11, premi_versati: 0},
    {date: new Date('2012-01-19'), value: 427.75, premi_versati: 0},
    {date: new Date('2012-01-20'), value: 420.30, premi_versati: 0},
    {date: new Date('2012-01-23'), value: 427.41, premi_versati: 0},
    {date: new Date('2012-01-24'), value: 420.41, premi_versati: 0},
    {date: new Date('2012-01-25'), value: 446.66, premi_versati: 0},
    {date: new Date('2012-01-26'), value: 444.63, premi_versati: 0},
    {date: new Date('2012-01-27'), value: 447.28, premi_versati: 0},
    {date: new Date('2012-01-30'), value: 453.01, premi_versati: 0},
    {date: new Date('2012-01-31'), value: 456.48, premi_versati: 0},
    {date: new Date('2012-02-01'), value: 456.19, premi_versati: 0},
    {date: new Date('2012-02-02'), value: 455.12, premi_versati: 0},
    {date: new Date('2012-02-03'), value: 459.68, premi_versati: 0},
    {date: new Date('2012-02-06'), value: 463.97, premi_versati: 0},
    {date: new Date('2012-02-07'), value: 468.83, premi_versati: 0},
    {date: new Date('2012-02-08'), value: 476.68, premi_versati: 0},
    {date: new Date('2012-02-09'), value: 493.17, premi_versati: 0},
    {date: new Date('2012-02-10'), value: 493.42, premi_versati: 0},
    {date: new Date('2012-02-13'), value: 502.60, premi_versati: 0},
    {date: new Date('2012-02-14'), value: 509.46, premi_versati: 0},
    {date: new Date('2012-02-15'), value: 497.67, premi_versati: 0},
    {date: new Date('2012-02-16'), value: 502.21, premi_versati: 0},
    {date: new Date('2012-02-17'), value: 502.12, premi_versati: 0},
    {date: new Date('2012-02-21'), value: 514.85, premi_versati: 0},
    {date: new Date('2012-02-22'), value: 513.04, premi_versati: 0},
    {date: new Date('2012-02-23'), value: 516.39, premi_versati: 0},
    {date: new Date('2012-02-24'), value: 522.41, premi_versati: 0},
    {date: new Date('2012-02-27'), value: 525.76, premi_versati: 0},
    {date: new Date('2012-02-28'), value: 535.41, premi_versati: 0},
    {date: new Date('2012-02-29'), value: 542.44, premi_versati: 0},
    {date: new Date('2012-03-01'), value: 544.47, premi_versati: 0},
    {date: new Date('2012-03-02'), value: 545.18, premi_versati: 0},
    {date: new Date('2012-03-05'), value: 533.16, premi_versati: 0},
    {date: new Date('2012-03-06'), value: 530.26, premi_versati: 0},
    {date: new Date('2012-03-07'), value: 530.69, premi_versati: 0},
    {date: new Date('2012-03-08'), value: 541.99, premi_versati: 0},
    {date: new Date('2012-03-09'), value: 545.17, premi_versati: 0},
    {date: new Date('2012-03-12'), value: 552.00, premi_versati: 0},
    {date: new Date('2012-03-13'), value: 568.10, premi_versati: 0},
    {date: new Date('2012-03-14'), value: 589.58, premi_versati: 0},
    {date: new Date('2012-03-15'), value: 585.56, premi_versati: 0},
    {date: new Date('2012-03-16'), value: 585.57, premi_versati: 0},
    {date: new Date('2012-03-19'), value: 601.10, premi_versati: 0},
    {date: new Date('2012-03-20'), value: 605.96, premi_versati: 0},
    {date: new Date('2012-03-21'), value: 602.50, premi_versati: 0},
    {date: new Date('2012-03-22'), value: 599.34, premi_versati: 0},
    {date: new Date('2012-03-23'), value: 596.05, premi_versati: 0},
    {date: new Date('2012-03-26'), value: 606.98, premi_versati: 0},
    {date: new Date('2012-03-27'), value: 614.48, premi_versati: 0},
    {date: new Date('2012-03-28'), value: 617.62, premi_versati: 0},
    {date: new Date('2012-03-29'), value: 609.86, premi_versati: 0},
    {date: new Date('2012-03-30'), value: 599.55, premi_versati: 0},
    {date: new Date('2012-04-02'), value: 618.63, premi_versati: 0},
    {date: new Date('2012-04-03'), value: 629.32, premi_versati: 0},
    {date: new Date('2012-04-04'), value: 624.31, premi_versati: 0},
    {date: new Date('2012-04-05'), value: 633.68, premi_versati: 0},
    {date: new Date('2012-04-09'), value: 636.23, premi_versati: 0},
    {date: new Date('2012-04-10'), value: 628.44, premi_versati: 0},
    {date: new Date('2012-04-11'), value: 626.20, premi_versati: 0},
    {date: new Date('2012-04-12'), value: 622.77, premi_versati: 0},
    {date: new Date('2012-04-13'), value: 605.23, premi_versati: 0},
    {date: new Date('2012-04-16'), value: 580.13, premi_versati: 0},
    {date: new Date('2012-04-17'), value: 609.70, premi_versati: 0},
    {date: new Date('2012-04-18'), value: 608.34, premi_versati: 0},
    {date: new Date('2012-04-19'), value: 587.44, premi_versati: 0},
    {date: new Date('2012-04-20'), value: 572.98, premi_versati: 0},
    {date: new Date('2012-04-23'), value: 571.70, premi_versati: 0},
    {date: new Date('2012-04-24'), value: 560.28, premi_versati: 0},
    {date: new Date('2012-04-25'), value: 610.00, premi_versati: 0},
    {date: new Date('2012-04-26'), value: 607.70, premi_versati: 0},
    {date: new Date('2012-04-27'), value: 603.00, premi_versati: 0},
    {date: new Date('2012-04-30'), value: 583.98, premi_versati: 0},
    {date: new Date('2012-05-01'), value: 582.13, premi_versati: 0},
  ]

}
