import { AuthService } from './../../../auth/auth.service';
import { Component, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-token-deadline',
  templateUrl: './snack-bar-token-deadline.component.html',
  styleUrls: ['./snack-bar-token-deadline.component.scss']
})
export class SnackBarTokenDeadlineComponent {
  deadlineCounter!: number;
  snackBarRef = inject(MatSnackBarRef);
  constructor(private authS: AuthService) { }

  getMessage() {
    let value = Math.ceil(+this.deadlineCounter/60)
    if (value > 0) {
      return "Logout automatico per inattività tra " + value + " minuti";
    } else if (value === 0){
      return "Logout automatico per inattività tra " + (60 + this.deadlineCounter) + " secondi";
    } else {
      this.authS.clearIndexedDb();
      return "Utente sconnesso per inattività";
    }
  }

}
