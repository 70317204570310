import { Router } from '@angular/router';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(
    private sharedVariables: SharedVariablesService,
    private router: Router,
    private apiS: ApiService,
    private authS: AuthService
  ) { }

  ngOnInit() {
    if(!this.sharedVariables.onMaintenance) {
      this.authS.onFirstInit = false;
      this.router.navigate([""]);
    }

    if (this.router.url == '/manutenzione') {
      let intervalId = setInterval(() => {
        this.apiS.getMaintenanceRefresh().subscribe((status: any) => {
          this.sharedVariables.onMaintenance = status.manutenzione;

          if (this.sharedVariables.onMaintenance === false) {
            this.authS.onFirstInit = false;
            this.router.navigate(["dashboard"]);
            clearInterval(intervalId)
          }
        })
      }, 600000); // Intervallo settato a 10 minuti
    }
  }

}
