<div class="component">
  <div class="text">
    <h1>Al momento siamo in manutenzione e stiamo lavorando per migliorare il sito.</h1>
    <h2>Ci scusiamo per l'inconveniente e torneremo presto online!</h2>
  </div>
  <div class="cont">
    <div class="skyline">
      <div class="building-left"></div>
      <div class="building-right"></div>
    </div>

    <div class="pc-wrapper">
      <div class="monitor">
        <div class="gears-container">
          <div class="gear-rotate"></div>
          <div class="gear-rotate-left"></div>
          <div class="progress">
            <div class="color"></div>
          </div>
        </div>
          <div class="ricerca">
            <mat-icon style="color: white;" class="search-icon">search</mat-icon>
          </div>
          <div class="card card-tl"></div>
          <div class="card card-tr"></div>
          <div class="card card-bl"></div>
          <div class="card card-br"></div>
      </div>
      <div class="keyboard"></div>
    </div>

    <div class="cone1"></div>
    <div class="cone2"></div>
    <div class="sign-wrapper"></div>

    <div class="front-ground"></div>

    <div class="runner"></div>
  </div>


</div>



