import { DemoFakeBeService } from './../../../services/demo-fake-be.service';
import { environment } from './../../../../environments/environment';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { BottomsheetService } from 'src/app/services/bottomsheet.service';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';

@Component({
  selector: 'app-delete-preferiti',
  templateUrl: './delete-preferiti.component.html',
  styleUrls: ['./delete-preferiti.component.scss']
})
export class DeletePreferitiComponent implements OnInit {

  isDeleting: boolean = false;

  constructor(
    private apiS: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private fakeBeDemo: DemoFakeBeService,
    private router: Router,
    private bottomsheetS: BottomsheetService,
    private authS: AuthService,
    private sharedVariables: SharedVariablesService
  ) { }

  ngOnInit(): void {
  }

  onClickYes() {
    if (environment.demo) {
      this.data.polizze_preferite.splice(this.data.polizze_preferite.map((e: any) => e.id).indexOf(this.data.id), 1);
      this.fakeBeDemo.deletepolizzaPreferita(this.data.id);
      this.dialogRef.close(this.data)
    } else {
      this.isDeleting = true;
      this.apiS.deletepolizzaPreferita(this.data.id).subscribe({
        next: this.nextDeletePolizzaPreferita(),
        error: async err => {
          if (err.status == 401) {
            await lastValueFrom(this.apiS.getRefreshToken()).then(
              (resp) => {
                this.apiS.deletepolizzaPreferita(this.data.id).subscribe({
                  next: this.nextDeletePolizzaPreferita(),
                  error: (err) => {
                    this.isDeleting = false;
                    this.bottomsheetS.openBottomSheet(err, err);
                  }
                })
              },
              (err) => {
                this.authS.stopLogout = true;
                this.authS.logout(true);
              }
            )
          } else if (String(err.status).slice(0, 2) === "50") {
            this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
            if (this.sharedVariables.onMaintenance) {
              this.isDeleting = false;
              this.router.navigate(["/manutenzione"]);
            } else {
              this.isDeleting = false;
              this.bottomsheetS.openBottomSheet(err, err);
            }
          } else {
            this.isDeleting = false;
            this.bottomsheetS.openBottomSheet(err, err);
          }
        }
      })
    }
  }

  nextDeletePolizzaPreferita () {
    return (data: any) => {
      this.data.polizze_preferite.splice(this.data.polizze_preferite.map((e: any) => e.id).indexOf(this.data.id), 1);
      this.dialogRef.close(this.data);
    }
  }

}
