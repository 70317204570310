import { MatPaginatorIntl } from '@angular/material/paginator';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();
  const italianRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 di ${length}`; }
    
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} di ${length}`;
  }

  customPaginatorIntl.itemsPerPageLabel = 'Elementi per pagina: ';

  customPaginatorIntl.previousPageLabel = 'Pagina precedente'
  customPaginatorIntl.nextPageLabel = 'Pagina successiva'
  
  customPaginatorIntl.firstPageLabel = 'Prima pagina'
  customPaginatorIntl.lastPageLabel = 'Ultima Pagina'

  customPaginatorIntl.getRangeLabel = italianRangeLabel

  return customPaginatorIntl;
}