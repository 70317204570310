import { AuthService } from './../../auth/auth.service';
import { Theme } from './../../theme/symbols';
import { Component, ElementRef, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, lastValueFrom, Subject, Subscription } from 'rxjs';
import { ColumnController } from 'src/app/models/column-controller.model';
import { DettaglioPolizza } from 'src/app/models/dettaglio-polizza.model';
import { LibraryController } from 'src/app/models/library-controller.model';
import { DettaglioPolizzaLabelFormatterPipe } from 'src/app/pipes/dettaglio-polizza-label-formatter.pipe';
import { FormatMovimentiLabelPipe } from 'src/app/pipes/format-movimenti-label.pipe';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsService } from 'src/app/services/settings.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { environment } from 'src/environments/environment';
import { Title } from "@angular/platform-browser";
import { AggregatoService } from 'src/app/services/aggregato.service';
import { MatSnackBar } from '@angular/material/snack-bar';
const pako = require("pako")
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { DatePipe } from '@angular/common';
import { folder } from 'jszip';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as CryptoJS from 'crypto-js';
import { BottomsheetService } from 'src/app/services/bottomsheet.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * Questo componente visualizza il dettaglio della polizza precedentemente selezionata nell'HomeComponent.
 */

@Component({
  selector: 'app-dettaglio-polizza',
  templateUrl: './dettaglio-polizza.component.html',
  // encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dettaglio-polizza.component.scss']
})
export class DettaglioPolizzaComponent implements OnInit, OnDestroy {
  colorCounter: number = 0;
  graphsPerformanceColor: string = '#9146FF';

  polizzeAggregate: any[] = [];

  nPolizza: string[] = [];
  nCompagnia: string[] = [];

  clickedMovimento: any;

  ready: boolean = false;
  dettaglioPolizzaColumnsController: ColumnController[] = [];
  anagraficaColumnsController: ColumnController[] = [];
  pdf: any;

  // fondiMovimentati: any;
  dataDettaglioPolizza!: DettaglioPolizza[]
  dataLettere: any;
  dataFondi: any;
  dataMovimenti: any;
  movimentiPolizzaController: any;
  KID: any;
  filteredKID: any;
  graphData: any;
  storicoFondi: any;

  dettaglioPolActive: boolean = false;
  fondiActive: boolean = false;
  pdfActive: boolean = false;
  graficiActive: boolean = false;
  movimentiActive: boolean = false;
  documentiActive: boolean = false;
  kidActive: boolean = false;
  isDemo: any = environment.demo;
  areCostsCashFlows!: boolean;

  subscription!: Subscription
  chipSubscription!: Subscription

  eventsSubject: Subject<void> = new Subject<void>();

  onInitEvent: Subject<void> = new Subject<void>();

  eventsDataDialogSubject: Subject<any> = new Subject<any>();

  clickChartSubject: Subject<any> = new Subject<any>();
  eventsMSToken: Subject<any> = new Subject<any>();

  eventsPdf: Subject<any> = new Subject<any>();

  dateFormat!: string;
  modPolizzeAggregate!: boolean;

  alphabet: string[] = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

  easterEgg = false;

  @ViewChild('dettaglio_pol_container') dettPolContainer!: ElementRef;
  @ViewChild('analisiPortafoglio') analisiPortafoglioContainer!: ElementRef;
  graphReady: boolean = false;
  stopScrollListener = false;

  arrayIdsUniverso: any[] = [];
  isIdUniversoTrue: boolean = false;

  constructor(
    public apiService: ApiService,
    private authS: AuthService,
    private route: ActivatedRoute,
    private labelFormat: DettaglioPolizzaLabelFormatterPipe,
    public movFormatter: FormatMovimentiLabelPipe,
    public notifyService: NotificationService,
    public sidenavServ: SidenavService,
    private apiS: ApiService,
    private settingsServ: SettingsService,
    private titleService: Title,
    public notificaAggregato: AggregatoService,
    private bottomsheetS: BottomsheetService,
    private router: Router,
    public _snackBar: MatSnackBar,
    private datePipe: DatePipe,
    public sharedVariables: SharedVariablesService,
    private dbService: NgxIndexedDBService,
    private http: HttpClient,
  ) {
    this.subscription = this.sidenavServ.receiveMessage().subscribe(() => this.emitSidenavWidhtChanged());
    this.chipSubscription = this.sidenavServ.receiveChipMessage().subscribe(() => this.sidenavServ.sendChipEvtToChild())
  }

  ngOnInit() {
    if (!environment.demo) {
      this.apiS.getMSTokenAPI().subscribe((ms: any) => {
        this.apiService.setMSToken(ms.token);
      })
    }

    document.getElementById("sidenav-content")?.addEventListener("scroll", () => {
      if (this.graphReady && !this.stopScrollListener) {
        const rect = this.analisiPortafoglioContainer.nativeElement.getBoundingClientRect();
        if (rect.top <= (window.innerHeight || document.documentElement.clientHeight)) {
          this.stopScrollListener = true;
          setTimeout(() => {
            this.onInitEvent.next();
          }, 1);
        }

      }
    })

    if (this.route.snapshot.queryParams['easterEgg'] === "yes") {
      this.easterEgg = true
    };

    this.dateFormat = this.settingsServ.setting_config.date_settings.date_format;
    this.areCostsCashFlows = this.settingsServ.setting_config.areCostsCashFlows;

    this.route.paramMap.subscribe(async (params: any) => {
      this.stopScrollListener = false;
      this.graphReady = false;
      this.ready = false;
      let polizze = params.get('nPolizza').split(',');
      let compagnie = params.get('nCompagnia').split(',');
      this.nPolizza = polizze;
      this.nCompagnia = compagnie;
      this.titleService.setTitle(environment.title + " | Polizza " + polizze[0]);

      this.modPolizzeAggregate = polizze.length === 1 ? false : true;

      console.log("INIT: ", this.sharedVariables);
      console.log("POLIZZE: ", polizze);

      if (Object.keys(this.sharedVariables.dettPolizzaTempCacheData).length === 0) {
        await lastValueFrom(this.dbService.getAll("dett_polizza")).then(async (d: any) => {
          if (d.length > 0 && sessionStorage.getItem("dbKeyDettPol")) {
            // Decrypt
            var bytes = CryptoJS.AES.decrypt(d[0].data, sessionStorage.getItem("dbKeyDettPol")!);
            var parsed = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            console.log("PARSED: ", parsed);

            if (d[0].update_date && d[0].update_date === this.sharedVariables.last_update_date_flussi) {
              this.sharedVariables.dettPolizzaTempCacheData = parsed;
            } else {
              await lastValueFrom(this.dbService.clear('dett_polizza')).then(() => { });
            }
          }

          if (!this.sharedVariables.dettPolizzaTempCacheData) this.sharedVariables.dettPolizzaTempCacheData = {};
        })
      }

      if (!this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza]) {
        console.log("dettPolizzaTempCacheData of company doesn't exist: ", this.sharedVariables.dettPolizzaTempCacheData);
        let forkjoinBody = this.generateArrayApiToCall(polizze, compagnie);
        forkJoin(forkjoinBody).subscribe({
          next: this.nextFn(polizze, compagnie),
          error: (async error => {
            if (error.status == 401) {
              await lastValueFrom(this.apiS.getRefreshToken()).then(
                (resp) => {
                  forkJoin(forkjoinBody).subscribe({
                    next: this.nextFn(polizze, compagnie),
                    error: err => {
                      this.bottomsheetS.openBottomSheet(err, err);
                    },
                    complete: this.completeFn
                  })
                },
                (err) => {
                  this.authS.stopLogout = true;
                  this.authS.logout(true);
                  this.sharedVariables.statusReady = true;
                  this.sharedVariables.showElementsLoggedIn = false;
                }
              )
            } else if (String(error.status).slice(0, 2) === "50") {
              this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
              if (this.sharedVariables.onMaintenance) {
                this.router.navigate(["/manutenzione"]);
              } else {
                this.bottomsheetS.openBottomSheet(error, error);
              }
            } else {
              this.bottomsheetS.openBottomSheet(error, error);
            }
            console.log(
              '\n',
              "LAMBDA error: ", error.status, '\n',
              "LAMBDA url: ", error.url.replace(environment.baseUrl, '')
            )
          }),
          complete: this.completeFn
        })

        console.log(this.dataDettaglioPolizza, 'this.dataDettaglioPolizza nell if');

      } else {
        this.dataDettaglioPolizza = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].dataDettaglioPolizza;
        this.dataMovimenti = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].dataMovimenti;
        this.movimentiPolizzaController = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].movimentiPolizzaController;
        this.dataFondi = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].dataFondi;
        this.storicoFondi = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].storicoFondi;
        this.dataLettere = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].dataLettere;
        this.KID = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].KID;
        this.filteredKID = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].filteredKID;
        this.graphData = JSON.parse(this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].graphData);
        this.polizzeAggregate = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].identificatori;
        this.dettaglioPolizzaColumnsController = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].dettaglioPolizzaColumnsController;
        this.anagraficaColumnsController = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].anagraficaColumnsController;
        this.dettaglioPolActive = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].dettaglioPolActive;
        this.fondiActive = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].fondiActive;
        this.graficiActive = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].graficiActive;
        this.movimentiActive = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].movimentiActive;
        this.documentiActive = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].documentiActive;
        this.kidActive = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].kidActive;
        this.pdfActive = this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza].pdfActive;

        console.log(this.nPolizza, 'this.nPolizza nell else');
        console.log(this.dataDettaglioPolizza, 'this.dataDettaglioPolizza nell else');
        console.log(this.nCompagnia, 'this.nCompagnia nell else');

        this.dataDettaglioPolizza.forEach((data: any) => {
          if (data.id_universo) {
            this.arrayIdsUniverso.push(data.id_universo)
            this.isIdUniversoTrue = true;
          }
        })

        if (this.isIdUniversoTrue) {
          localStorage.setItem('id_universo', JSON.stringify(this.arrayIdsUniverso))
        }

        setTimeout(() => {
          this.ready = true;
          setTimeout(() => {

            this.notifyService.sendOK({ polizza: (this.dataDettaglioPolizza as any), modAggregata: this.modPolizzeAggregate, arrIdentificatori: this.polizzeAggregate });

            const rect = this.analisiPortafoglioContainer.nativeElement.getBoundingClientRect();
            if (rect.top <= (window.innerHeight || document.documentElement.clientHeight)) {
              setTimeout(() => {
                this.onInitEvent.next();
              }, 1);
            } else this.graphReady = true;

            if (
              (this.dettaglioPolActive && this.dataDettaglioPolizza.length === 0) ||
              (this.fondiActive && this.dataFondi.length === 0 && this.storicoFondi.length === 0) ||
              (this.graficiActive && !this.checkDataGraphEmpty()) ||
              (this.movimentiActive && this.dataMovimenti.length === 0)
            ) {
              let message = ""
              if (this.dataDettaglioPolizza.length === 0) message += " Dettaglio Polizza,"
              if (this.dataFondi.length === 0 && this.storicoFondi.length === 0) message += " Lista Fondi,"
              if (!this.checkDataGraphEmpty()) message += " Analisi Portafoglio,"
              if (this.dataMovimenti.length === 0) message += " Lista Movimenti,"
              message = message.substring(0, message.length - 1)
              this._snackBar.open("Impossibile recuperare dati di " + message, 'Chiudi', { duration: 10000, panelClass: ['auth-snackbar'] })
            }
          }, 10);
        }, 1);

        console.log(this.dataDettaglioPolizza, 'dataDettaglioPolizza 1');

      }
    })
  }

  nextFn(polizze: any, compagnie: any) {
    return (resp: any) => {
      this.colorCounter = 0
      let dettaglio: any = [],
        fondi: any = [],
        movimenti: any = [],
        lettere: any = [],
        KID: any = [],
        _graphData: any = [],
        filteredKID: any = [],
        storicoFondi: any = [],
        pdf: any = []
      // fondiMovimentati: any = []

      this.polizzeAggregate = []
      let index = 0;
      polizze.forEach((polizza: any, i: number) => {
        let res = i;
        if (i > 51) {
          res = index++;
        };
        this.polizzeAggregate.push({
          numPolizza: polizza,
          text: this.makeString(res),
          color: this.randomColor()
        })
      })

      polizze.forEach((numPol: any, i: number) => {
        const transformedObject = {
          ...resp["dettaglio_polizza-" + i].polizza,
          ...resp["dettaglio_polizza-" + i].polizza.rendimento,
        };
        delete transformedObject.rendimento;
        resp["dettaglio_polizza-" + i].polizza = transformedObject;
      })


      this.dettaglioPolizzaColumnsController = this.labelFormat.transform(resp["dettaglio_polizza-0"].polizza)
      if ("assicurato" in resp["dettaglio_polizza-0"].polizza || "contraente" in resp["dettaglio_polizza-0"].polizza) {
        if (resp["dettaglio_polizza-0"].polizza.assicurato) {
          this.anagraficaColumnsController = this.labelFormat.transform(resp["dettaglio_polizza-0"].polizza.assicurato);
        }
        if (resp["dettaglio_polizza-0"].polizza.contraente) {
          this.anagraficaColumnsController = this.labelFormat.transform(resp["dettaglio_polizza-0"].polizza.contraente);
        }
      }

      console.log("in next fn, polizze: ", polizze);
      console.log("in next fn, resp: ", resp);

      polizze.forEach((numPol: any, i: number) => {
        if (resp["dettaglio_polizza-" + i] && resp["dettaglio_polizza-" + i].polizza) dettaglio.push(resp["dettaglio_polizza-" + i].polizza);
        if (resp["fondi-" + i] && resp["fondi-" + i].length > 0) fondi.push(resp["fondi-" + i]);
        if (resp["storico_fondi-" + i] && resp["storico_fondi-" + i].length > 0) {
          let sFondi: any = resp["storico_fondi-" + i]
          sFondi.forEach((el: any) => {
            el["codPolizza"] = numPol
          });
          storicoFondi.push(sFondi)
        };
        if (resp["movimenti-" + i] && resp["movimenti-" + i].movimenti.length > 0) {
          resp["movimenti-" + i].movimenti.forEach((el: any) => {
            el["dataDecorrenza"] = resp["dettaglio_polizza-" + i].polizza.dataDecorrenza;
            el["controvalore"] = resp["dettaglio_polizza-" + i].polizza.controvalore;
          })
          movimenti.push(resp["movimenti-" + i].movimenti);
        }
        // if (resp["fondi_movimentati-" + i] && resp["fondi_movimentati-" + i].length !== 0) fondiMovimentati.push(resp["fondi_movimentati-" + i]);
        if (resp["comunicazioni-" + i] && resp["comunicazioni-" + i].length > 0) lettere.push(resp["comunicazioni-" + i]);
        if (resp["kid-" + i] && resp["kid-" + i].length > 0) KID.push(resp["kid-" + i]);
        if (resp["filtered_kid-" + i] && resp["filtered_kid-" + i].length > 0) filteredKID.push(resp["filtered_kid-" + i]);
        if (resp["pdf-" + i] && resp["pdf-" + i].length > 0) pdf.push(resp["pdf-" + i]);
      })

      console.log("In next fn, dettaglio: ", dettaglio);

      this.dataDettaglioPolizza = dettaglio;
      this.dataMovimenti = movimenti;
      this.movimentiPolizzaController = this.movFormatter.transform(movimenti[0] ? movimenti[0][0] : []);
      this.dataFondi = fondi;
      this.storicoFondi = storicoFondi;
      this.dataLettere = lettere;
      this.KID = KID;
      this.filteredKID = filteredKID;

      console.log(this.dataDettaglioPolizza, 'this.dataDettaglioPolizza = dettaglio');

      this.notifyService.sendOK({ polizza: dettaglio, modAggregata: this.modPolizzeAggregate, arrIdentificatori: this.polizzeAggregate });
      this.ready = true;

      this.dataDettaglioPolizza.forEach((data: any) => {
        if (data.id_universo) {
          this.arrayIdsUniverso.push(data.id_universo);
          this.isIdUniversoTrue = true;
        }
      })

      if (this.isIdUniversoTrue) {
        localStorage.setItem('id_universo', JSON.stringify(this.arrayIdsUniverso))
      }

      //BLOCCO SEPARATO PER GRAFICI
      polizze.forEach((numPol: any, i: number) => {
        if (resp["grafici-" + i]) {

          var b64Data = resp["grafici-" + i].data;
          // Decode base64 (convert ascii to binary)
          var strData = atob(b64Data);
          // Convert binary string to character-number array
          var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
          // Turn number array into byte-array
          var binData = new Uint8Array(charData);
          // Pako inflate
          var data = pako.inflate(binData, { to: "string" });
          let res = JSON.parse(data)

          _graphData.push(res)
          _graphData[i]["numero_polizza"] = numPol
          _graphData[i]["codice_compagnia"] = compagnie[i]
        };
      })

      this.graphData = _graphData;

    }
  }

  completeFn = async () => {
    console.log(this, 'this');
    console.log(this.dataDettaglioPolizza, 'dataDettaglioPolizza');
    console.log(this.nCompagnia, 'this.nCompagnia');
    console.log(this.nPolizza, 'this.nPolizza');
    
    this.sharedVariables.dettPolizzaTempCacheData[this.nCompagnia + "_" + this.nPolizza] = {
      dataDettaglioPolizza: [...this.dataDettaglioPolizza],
      dataMovimenti: [...this.dataMovimenti],
      movimentiPolizzaController: [...this.movimentiPolizzaController],
      dataFondi: [...this.dataFondi],
      storicoFondi: [...this.storicoFondi],
      dataLettere: this.dataLettere,
      KID: [...this.KID],
      filteredKID: [...this.filteredKID],
      graphData: JSON.stringify(this.graphData),
      identificatori: [...this.polizzeAggregate],
      dettaglioPolizzaColumnsController: [...this.dettaglioPolizzaColumnsController],
      anagraficaColumnsController: [...this.anagraficaColumnsController],
      dettaglioPolActive: this.dettaglioPolActive,
      fondiActive: this.fondiActive,
      graficiActive: this.graficiActive,
      movimentiActive: this.movimentiActive,
      documentiActive: this.documentiActive,
      kidActive: this.kidActive,
      pdfActive: this.pdfActive,
    };

    var key;
    // Encrypt
    if (sessionStorage.getItem("dbKeyDettPol")) {
      key = sessionStorage.getItem("dbKeyDettPol");
    } else {
      key = Array.from(window.crypto.getRandomValues(new Uint8Array((40))), (dec: any) => dec.toString(16).padStart(2, "0")).join('');
      sessionStorage.setItem("dbKeyDettPol", key);
    }
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(this.sharedVariables.dettPolizzaTempCacheData), key!).toString();


    await lastValueFrom(this.dbService.update('dett_polizza', {
      id: 0,
      data: ciphertext,
      update_date: this.sharedVariables.last_update_date_flussi
    })).then(() => {
    });

    const rect = this.analisiPortafoglioContainer.nativeElement.getBoundingClientRect();
    if (rect.top <= (window.innerHeight || document.documentElement.clientHeight)) {
      setTimeout(() => {
        this.onInitEvent.next();
      }, 1);
    } else this.graphReady = true;

    if (
      (this.dettaglioPolActive && this.dataDettaglioPolizza.length === 0) ||
      (this.fondiActive && this.dataFondi.length === 0 && this.storicoFondi.length === 0) ||
      (this.graficiActive && !this.checkDataGraphEmpty()) ||
      (this.movimentiActive && this.dataMovimenti.length === 0)
    ) {
      let message = "";
      if (this.dataDettaglioPolizza.length === 0) message += " Dettaglio Polizza,"
      if (this.dataFondi.length === 0 && this.storicoFondi.length === 0) message += " Lista Fondi,"
      if (!this.checkDataGraphEmpty()) message += " Analisi Portafoglio,"
      if (this.dataMovimenti.length === 0) message += " Lista Movimenti,"
      message = message.substring(0, message.length - 1)
      this._snackBar.open("Impossibile recuperare dati di " + message, 'Chiudi', { duration: 10000, panelClass: ['auth-snackbar'] })
    };

    if (location.hostname === "localhost") {
      console.log(
        "VISUALIZZATO SOLO IN LOCALHOST \n",
        "dettaglio = ", this.dataDettaglioPolizza, '\n',
        "movimenti = ", this.dataMovimenti, '\n',
        "fondi = ", this.dataFondi, '\n',
        "storicoFondi = ", this.storicoFondi, '\n',
        "lettere = ", this.dataLettere, '\n',
        "KID = ", this.KID, '\n',
        "filteredKID = ", this.filteredKID, '\n',
        "graphData = ", this.graphData, '\n',
        "Controller Polizze Aggregate = ", this.polizzeAggregate
      );
    };
  }

  generateArrayApiToCall(polizze: string[], compagnie: string[]) {
    let apiToCall: any = {};
    polizze.forEach((polizza: any, i: number) => {
      this.settingsServ.availableLibraries.forEach((element: LibraryController) => {
        switch (element.library) {

          case "Dettaglio-Polizza": {
            this.dettaglioPolActive = true;
            apiToCall["dettaglio_polizza-" + i] = this.apiService.getDettaglioPolizza(compagnie[i], polizza)
          }; break;

          case "Fondi": {
            this.fondiActive = true;
            apiToCall["fondi-" + i] = this.apiService.getSaldoFondiPerPolizza(compagnie[i], polizza)
            apiToCall["storico_fondi-" + i] = this.apiService.getStoricoFondi(compagnie[i], polizza)
          }; break;

          case "Analisi-Portafoglio": {
            this.graficiActive = true;
            apiToCall["grafici-" + i] = this.apiService.getDatiGrafici(compagnie[i], polizza)
          }; break;

          case "Movimenti": {
            this.movimentiActive = true;
            apiToCall["movimenti-" + i] = this.apiService.getMovimentiPolizza(compagnie[i], polizza)
            // apiToCall["fondi_movimentati-" + i] = this.apiService.getFondiMovimentati(compagnie[i], polizza)
          }; break;

          case "Comunicazioni": {
            this.documentiActive = true;
            apiToCall["comunicazioni-" + i] = this.apiService.getLetterePerPolizza(compagnie[i], polizza)
          }; break;

          case "Informative": {
            this.kidActive = true;
            apiToCall["kid-" + i] = this.apiService.getInformative(compagnie[i], polizza);
            apiToCall["filtered_kid-" + i] = this.apiService.getFilteredMockedKID(compagnie[i], polizza);
          }; break;

          case "Pdf": {
            this.pdfActive = true;
            apiToCall["pdf-" + i] = this.apiService.getPdf(compagnie[i], polizza)
          }; break;
        }
      })

    })
    return apiToCall;
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.chipSubscription) this.chipSubscription.unsubscribe();
  }

  checkDataGraphEmpty() {
    let res = false
    if (this.ready && this.graphData && this.graphData.length > 0) {
      for (const iter of this.graphData) {
        if (iter.crossfilter && Object.keys(iter.crossfilter).length > 0) {
          res = true
          break;
        }
      }
    }
    return res;
  }

  getDettPolH() {
    if (!this.dettPolContainer) return 0
    else return (this.dettPolContainer.nativeElement.offsetHeight / 2) - 100
  }

  emitSidenavWidhtChanged() {
    this.eventsSubject.next();
  }

  emitClickedMovimento(dataRow: any) {
    this.clickedMovimento = dataRow;
  }

  getToken() {
    return this.apiService.generateToken()
  }

  getEndPointFondiMovimentati() {
    return environment.endPoints.getFondiMovimentati;
  }

  getEnvDemo() {
    return environment.demo;
  }

  getEndPointPdf() {
    return environment.endPoints.getPdf;
  }

  getEndPointMsToken() {
    return environment.endPoints.getMSToken;
  }

  getEndPointClickedFondiCharts() {
    return environment.endPoints.getSaldoFondiPerPolizza;
  }

  getBaseUrl() {
    return environment.baseUrl;
  }

  // 60A3D9 B1D4E0
  randomColor() {
    if (this.colorCounter === 0) {
      this.colorCounter++
      return environment.demo ? "#72D1DD" : '#fec261'
    } else if (this.colorCounter === 1) {
      this.colorCounter++
      return environment.demo ? "#FFF6A2" : '#78bd77'
    } else if (this.colorCounter === 2) {
      this.colorCounter++
      return environment.demo ? "#F77400" : '#578dff'
    } else if (this.colorCounter === 3) {
      this.colorCounter++
      return environment.demo ? "#578DFF" : '#b9a8dd'
    } else if (this.colorCounter === 4) {
      this.colorCounter++
      return environment.demo ? "#27BF63" : '#FF7F7F'
    } else if (this.colorCounter === 5) {
      this.colorCounter++
      return environment.demo ? "#EBE4B9" : '#a1fbec'
    } else if (this.colorCounter === 6) {
      this.colorCounter++
      return environment.demo ? "#A0895E" : '#f4d6e7'
    } else if (this.colorCounter === 7) {
      this.colorCounter++
      return environment.demo ? "#FFA2A2" : '#a0895e'
    } else if (this.colorCounter === 8) {
      this.colorCounter++
      return environment.demo ? "#C2EDDC" : '#ffa146'
    } else if (this.colorCounter === 9) {
      this.colorCounter++
      return environment.demo ? "#E71312" : '#c5c7de'
    } else {
      var hex = '0123456789ABC'.split('');
      var color = '#';
      for (let i = 0; i < 6; i++) {
        color = color + hex[Math.floor(Math.random() * 13)];
      }
      return color;
    }
  }

  makeString(index: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (var i = 0; i <= length; i++) {
      result += characters.charAt(index);
    }
    return result;
  }

  transfer(evt: string[]) {
    this.notificaAggregato.sendOK(evt);
  }

  transferChangePreferiti() {
    this.sidenavServ.sendChangePreferitiFromDettaglio();
  }

  exportCsvPerformance(csv: any) {
    let dataCsvPerf = csv;
    let headersCsvPerf = Object.keys(dataCsvPerf[0]);
    let x = 0, y = 0, settledX = false;

    let filePerfomanceCsv: any;
    let folderName: string | any = 'report_analisi_portafoglio_' + this.datePipe.transform(new Date(), this.dateFormat);

    dataCsvPerf.forEach((el: any, i: number) => {
      if (el.nome_fondo === "Portafoglio") {
        if (!settledX) {
          settledX = true
          x = i
        } else if (settledX && i > y) {
          y = i + 1
        }
        // dataCsvPerf.push(dataCsvPerf.splice(i, 1)[0]);
      }
    });

    let subArray = dataCsvPerf.slice(x, y);
    dataCsvPerf.splice(x, y - x);
    dataCsvPerf = dataCsvPerf.concat(subArray);

    dataCsvPerf.forEach((data: any, i: number) => {
      if (!data.ctv_iniziale_totale) {
        dataCsvPerf[i].ctv_iniziale_totale = 0
      }
      if (!data.somma_flussi) {
        dataCsvPerf[i].somma_flussi = 0
      }
      if (!data.performance) {
        dataCsvPerf[i].performance = 0
      }
      if (!data.somma_flussi_pesati) {
        dataCsvPerf[i].somma_flussi_pesati = 0
      }
      if (!data.quote) {
        dataCsvPerf[i].quote = 0
      }
      if (!data.prezzo_nav) {
        dataCsvPerf[i].prezzo_nav = 0
      }
    })

    filePerfomanceCsv = new ngxCsv(dataCsvPerf, folderName.replaceAll('/', '_'), {
      fieldSeparator: ';',
      headers: headersCsvPerf,
      noDownload: false,
      decimalseparator: ','
    })
  }

  exportCsvAttuale(that: any) {
    let today: any | Date = that.datePipe.transform(
      new Date(),
      that.dateFormat
    );

    let fondiAttualiCsv: any;

    let fondiAttualiCsvAggregati: any;

    let fondiAttualiCopy: any = [...that.fondi];
    let headersfondiAttuali: any;

    if (!that.modPolizzeAggregate) {
      //FONDI SINGOLI

      fondiAttualiCopy.forEach((fondo: any) => {
        fondo['codIsin'] === null ? ' ' : fondo['codisin']
        delete fondo.Performance;
        delete fondo.performanceRi;
        delete fondo.performanceTwrr;
        delete fondo.categoriaCustom;
        delete fondo.StarRating;
        delete fondo.AdministratorCompanyName;
        delete fondo.GlobalCategoryName;
        delete fondo.ExpenseRatio,
        delete fondo.dati_mwrr

        delete fondo.controvaloreUltimaRivalutazione,
        delete fondo.dataUltimaRivalutazione
      });
      headersfondiAttuali = Object.keys(fondiAttualiCopy[0]);
      fondiAttualiCopy.forEach((fondo: any) => {
        for (const key in fondo) {
          if (fondo.hasOwnProperty.call(fondo, key)) {
            let elementFondo = fondo[key].toString().replaceAll('.', ',');
            fondo[key] = elementFondo;
          }
        }
      })
      fondiAttualiCsv = new ngxCsv(fondiAttualiCopy, 'Fondi_Attuali_' + today, {
        fieldSeparator: ';',
        headers: headersfondiAttuali,
        noDownload: false,
        decimalseparator: ','

      });
    } else {
      //FONDI AGGREGATI
      let fondiAttualiCopy: any = [...that.fondi];
      //TODO: FONDI ATTUALI

      that.TotaldataSource1And2LevelAttuali =
        that.dataSourceLista2LivelloAttuale.concat(fondiAttualiCopy);
      that.dataSourceLista2LivelloAttuale.forEach((fondo2Livello: any) => {
        let tempObj: any = {
          'Numero Polizza': '',
          'Codice Fondo': '',
          'Nome': '',
          'Isin': '',
          'Data Inserimento': '',
          'Percentuale investita sulla polizza': '',
          'Controvalore sulla polizza': '',
          'Quote': '',
          'Performance MWRR': '',
          'Fondo': '',
          'Controvalore Totale': '',
          'Percentuale Investita sul Totale': '',
          'Quote Totali': '',
          'Prezzo Nav': '',
          'Data Prezzo': '',
          'Rating': '',
        };
        for (const key in fondo2Livello) {
          if (Object.prototype.hasOwnProperty.call(fondo2Livello, key)) {
            const element = fondo2Livello[key];
            switch (key) {
              case 'controvalore':
                tempObj['Controvalore sulla polizza'] = element;
                break;
              case 'performanceMwrr':
                tempObj['Performance MWRR'] = element;
                break;
              case 'nomeFondo':
                tempObj['Nome'] = element;
                break;
              case 'dataInserimento':
                tempObj['Data Inserimento'] = element === null ? ' ' : element;
                break;
              case 'numPolizza':
                tempObj['Numero Polizza'] = element;
                break;
              case 'codFondo':
                tempObj['Codice Fondo'] = element;
                break;
              case 'codIsin':
                tempObj['Isin'] = element === null ? ' ' : element;
                break;
              case 'Fondo':
                tempObj['Fondo'] = element;
                break;
              case 'numeroQuote':
                tempObj['Quote'] = element;
                break;
              case 'Controvalore Totale':
                tempObj['Controvalore Totale'] = element;
                break;
              case 'Percentuale Investita sul Totale':
                tempObj['Percentuale Investita sul Totale'] = element;
                break;
              case 'Quote Totali':
                tempObj['Quote Totali'] = element;
                break;
              case 'Prezzo Nav':
                tempObj['Prezzo Nav'] = element;
                break;
              case 'Data Prezzo':
                tempObj['Data Prezzo'] = element;
                break;
              case 'percInvestita':
                tempObj['Percentuale investita sulla polizza'] = element;
                break;
              case 'StarRating':
                tempObj['Rating'] = element;
                break;
              case 'descTipoFondo':
                tempObj['Fondo'] = element;
                break;
            }
          }
        }
        that.fondi.forEach((fondo1Livello: any) => {
          if (tempObj['Codice Fondo'] === fondo1Livello['codFondo']) {
            tempObj['Percentuale Investita sul Totale'] =
              fondo1Livello['percInvestita'];
            tempObj['Controvalore Totale'] =
              fondo1Livello['controvalore'];
            tempObj['Prezzo Nav'] = fondo1Livello['prezzoNav'];
            tempObj['Quote Totali'] = fondo1Livello['numeroQuote'];
            tempObj['Data Prezzo'] = fondo1Livello['dataPrezzo'];
          }
        });
        that.finalCsvAttuali.push(tempObj);
      });
      let headersAggregati = Object.keys(that.finalCsvAttuali[0]);
      that.finalCsvAttuali.forEach((fondo: any) => {
        for (const key in fondo) {
          if (fondo.hasOwnProperty.call(fondo, key)) {
            let elementFondo = fondo[key].toString().replaceAll('.', ',');
            fondo[key] = elementFondo;
          }
        }
      })
      fondiAttualiCsvAggregati = new ngxCsv(
        that.finalCsvAttuali,
        'Fondi_Attuali_aggregazione_polizze_'+today,
        { fieldSeparator: ';', headers: headersAggregati, noDownload: false, decimalseparator: ',' }
      );
    }
  }

  exportCsvStorico(that: any) {
    let today: any | Date = that.datePipe.transform(
      new Date(),
      that.dateFormat
    );

    let fondiPassatiCsv: any;

    let fondiPassatiCsvAggregati: any;

    let fondiPassatiCopy: any = [...that.storicoFondi];
    let headersFondiPassati: any;

    if (!that.modPolizzeAggregate) {
      //FONDI SINGOLI
      fondiPassatiCopy.forEach((fondo: any) => {
        if (fondo['codIsin'] === null) {
          fondo['codIsin'] = ' ';
        } else {
          fondo['codIsin'] = fondo['codIsin'];
        }
        fondo["PerformanceMwrr"] = fondo.performance === undefined ? fondo.PerformanceMwrr : (fondo.performance.mwrr == null ? ' ' : fondo.performance.mwrr);
        delete fondo.performance;
        delete fondo.AdministratorCompanyName;
        delete fondo.GlobalCategoryName;
        delete fondo.ExpenseRatio
        delete fondo.StarRating;

        delete fondo.controvaloreUltimaRivalutazione,
        delete fondo.dataUltimaRivalutazione
      });
      headersFondiPassati = Object.keys(fondiPassatiCopy[0]);
      fondiPassatiCopy.forEach((fondo: any) => {
        for (const key in fondo) {
          if (fondo.hasOwnProperty.call(fondo, key)) {
            let elementFondo = fondo[key].toString().replaceAll('.', ',');
            fondo[key] = elementFondo;
          }
        }
      })
      fondiPassatiCsv = new ngxCsv(fondiPassatiCopy, 'Storico_Fondi_'+today, {
        fieldSeparator: ';',
        headers: headersFondiPassati,
        noDownload: false,
        decimalseparator: ','
      });
    } else {
      //FONDI AGGREGATI
      let fondiPassatiCopy: any = [...that.storicoFondi];
      //TODO: FONDI PASSATI

      that.TotaldataSource1And2LevelPassati =
        that.dataSourceLista2LivelloPassate.concat(fondiPassatiCopy);

      that.dataSourceLista2LivelloPassate.forEach((fondo2Livello: any) => {
        let tempObj: any = {
          'Numero Polizza': '',
          'Performance MWRR': '',
          'Codice Fondo': '',
          Isin: '',
          Fondo: '',
          Nome: '',
          Rating: '',
          'Data Inizio': '',
          'Data Fine': '',
        };
        for (const key in fondo2Livello) {
          if (Object.prototype.hasOwnProperty.call(fondo2Livello, key)) {
            const element = fondo2Livello[key];
            switch (key) {
              case 'StarRating':
                tempObj['Rating'] = element;
                break;
              case 'performance':
                tempObj['Performance MWRR'] = element.mwrr;
                break;
              case 'codFondo':
                tempObj['Codice Fondo'] = element;
                break;
              case 'codIsin':
                tempObj['Isin'] = element === null ? ' ' : element;
                break;
              case 'descTipoFondo':
                tempObj['Fondo'] = element;
                break;
              case 'nomeFondo':
                tempObj['Nome'] = element;
                break;
              case 'codPolizza':
                tempObj['Numero Polizza'] = element;
                break;
              case 'dataFine':
                tempObj['Data Fine'] = element;
                break;
              case 'dataInizio':
                tempObj['Data Inizio'] = element;
                break;
            }
          }
        }
        that.finalCsvPassate.push(tempObj);
      });
      that.finalCsvPassate.sort(function (a: any, b: any) {
        return (
          +b['Codice Fondo'].replace(/\D/g, '') -
          +a['Codice Fondo'].replace(/\D/g, '')
        );
      });
      let headersAggregatiPassati = Object.keys(that.finalCsvPassate[0]);
      that.finalCsvPassate.forEach((fondo: any) => {
        for (const key in fondo) {
          if (fondo.hasOwnProperty.call(fondo, key)) {
            let elementFondo = fondo[key].toString().replaceAll('.', ',');
            fondo[key] = elementFondo;
          }
        }
      })
      fondiPassatiCsvAggregati = new ngxCsv(
        that.finalCsvPassate,
        'Storico_Fondi_aggregazione_polizze_'+today,
        {
          fieldSeparator: ';',
          headers: headersAggregatiPassati,
          noDownload: false,
          decimalseparator: ','
        }
      );
    }
  }

  async exportMSToken() {
    let msToken: any;

    await lastValueFrom(this.apiS.getMSTokenAPI()).then(
      (resp) => {
        msToken = resp;
        this.eventsMSToken.next(msToken);
      },
      async (err) => {
        if (err.status == 401) {
          await lastValueFrom(this.apiS.getRefreshToken()).then(
            async (resp) => {
              await lastValueFrom(this.apiS.getRefreshToken()).then(
                (resp) => {
                  msToken = resp;
                  this.eventsMSToken.next(msToken);
                },
                (err) => this.bottomsheetS.openBottomSheet(err, err)
              )
            },
            (err) => {
              this.authS.stopLogout = true;
              this.authS.logout(true);
            }
          )
        } else if (String(err.status).slice(0, 2) === "50") {
          this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
          if (this.sharedVariables.onMaintenance) {
            this.router.navigate(["/manutenzione"]);
          } else {
            this.bottomsheetS.openBottomSheet(err, err);
          }
        } else {
          this.bottomsheetS.openBottomSheet(err, err);
        }
      }
    );
  }

  async exportDataDialog(rowData: any) {
    let headers = new HttpHeaders({
      "X-Api-Key": "2iOBC3f3kVW9TMaqUPaJv5T2Kf1hgDUJnRW0R11rk0CMxERPaRYJo7NKApRsM5XT7o5qeqTEybEPGDjAZZkN2lij8pjw4qzPKtWKJeVFLtu0b9iKNG8VkVzK",
    })
    let config = {
      headers: headers,
      withCredentials: true,
    }
    let [d, m, y] = rowData.dataOperazione.split('/')
    let res = [y, m, d].join('- ')

    let clickedMovimentoData = new Date(res)

    let date = this.datePipe.transform(clickedMovimentoData, 'yyyy-MM-dd');

    if (this.isDemo) {
      return this.http.get(environment.baseUrl + environment.endPoints.getFondiMovimentati, config)
    } else {
      return await lastValueFrom(this.http.get(environment.baseUrl + environment.endPoints.getFondiMovimentati + rowData.compagnia + '/' + rowData.numeroPolizza + '?nProgressivo=' + rowData.nProgressivo + '&dataOperazione=' + date, config))
        .then(
          (resp) => this.eventsDataDialogSubject.next(resp),
          async (error) => {
            if (error.status == 401) {
              await lastValueFrom(this.apiS.getRefreshToken()).then(
                async (resp) => {
                  await lastValueFrom(this.http.get(environment.baseUrl + environment.endPoints.getFondiMovimentati + rowData.compagnia + '/' + rowData.numeroPolizza + '?nProgressivo=' + rowData.nProgressivo + '&dataOperazione=' + date, config))
                    .then(
                      (resp) => this.eventsDataDialogSubject.next(resp),
                      (err) => this.bottomsheetS.openBottomSheet(err, err)
                    )
                },
                (err) => {
                  this.authS.stopLogout = true;
                  this.authS.logout(true);
                }
              )
            } else if (String(error.status).slice(0, 2) === "50") {
              this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
              if (this.sharedVariables.onMaintenance) {
                this.router.navigate(["/manutenzione"]);
              } else {
                this.bottomsheetS.openBottomSheet(error, error);
              }
            } else {
              this.bottomsheetS.openBottomSheet(error, error);
            }
          }
        )
    }
  }

  async clickChart(data: any) {

    if (this.isDemo) {
      await lastValueFrom(this.apiS.getClickChartsFondi(data.codCompagnia, data.numPolizza, data.date)).then(
        (resp: any) => {
          this.clickChartSubject.next(resp);
        },
        async (error: any) => {
          if (error.status == 401) {
            await lastValueFrom(this.apiS.getRefreshToken()).then(
              async (resp: any) => {
                await lastValueFrom(this.apiS.getClickChartsFondi(data.codCompagnia, data.numPolizza, data.date)).then(
                  (resp: any) => {
                    this.clickChartSubject.next(resp);
                  },
                  (error) => {
                    this.clickChartSubject.next(undefined)
                    this.bottomsheetS.openBottomSheet(error, error);
                  }
                )
              },
              (err) => {
                this.authS.stopLogout = true;
                this.authS.logout(true);
              }
            )
          } else if (String(error.status).slice(0, 2) === "50") {
            this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
            if (this.sharedVariables.onMaintenance) {
              this.clickChartSubject.next(undefined)
              this.router.navigate(["/manutenzione"]);
            } else {
              this.clickChartSubject.next(undefined)
              this.bottomsheetS.openBottomSheet(error, error);
            }
          } else {
            this.clickChartSubject.next(undefined)
            this.bottomsheetS.openBottomSheet(error, error);
          }
        }
      );
    } else {
      await lastValueFrom(
        forkJoin({
          fondi: this.apiS.getClickChartsFondi(data.codCompagnia, data.numPolizza, data.date),
          token: this.apiS.getMSTokenAPI()
        })
      ).then(
        (resp: any) => {
          localStorage.setItem('apiGatewayToken', resp.token);
          this.clickChartSubject.next(resp.fondi)
        },
        async (error: any) => {
          if (error.status == 401) {
            await lastValueFrom(this.apiS.getRefreshToken()).then(
              async (resp: any) => {
                await lastValueFrom(
                  forkJoin({
                    fondi: this.apiS.getClickChartsFondi(data.codCompagnia, data.numPolizza, data.date),
                    token: this.apiS.getMSTokenAPI()
                  })
                ).then(
                  (resp: any) => {
                    localStorage.setItem('apiGatewayToken', resp.token);
                    this.clickChartSubject.next(resp.fondi)
                  },
                  (error) => {
                    this.clickChartSubject.next(undefined)
                    this.bottomsheetS.openBottomSheet(error, error);
                  }
                )
              },
              (err) => {
                this.authS.stopLogout = true;
                this.authS.logout(true);
              }
            )
          } else if (String(error.status).slice(0, 2) === "50") {
            this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
            if (this.sharedVariables.onMaintenance) {
              this.clickChartSubject.next(undefined)
              this.router.navigate(["/manutenzione"]);
            } else {
              this.clickChartSubject.next(undefined)
              this.bottomsheetS.openBottomSheet(error, error);
            }
          } else {
            this.clickChartSubject.next(undefined)
            this.bottomsheetS.openBottomSheet(error, error);
          }
        }
      )
    }
  }

  ckeckRole() {
    if (this.sharedVariables.userData) {
      return this.sharedVariables.userData.role
    } else return 'backoffice'
  }

  async exportPdf(response: any) {
    let resGetPdf: any;
    let headers = new HttpHeaders({
      "X-Api-Key": "2iOBC3f3kVW9TMaqUPaJv5T2Kf1hgDUJnRW0R11rk0CMxERPaRYJo7NKApRsM5XT7o5qeqTEybEPGDjAZZkN2lij8pjw4qzPKtWKJeVFLtu0b9iKNG8VkVzK",
      "Accept": "application/pdf",
    })

    let config: any = {
      headers: headers,
      withCredentials: true,
      responseType: 'blob'
    }

    await lastValueFrom(this.http.get<Blob | any>(environment.baseUrl + environment.endPoints.getPdf + (this.modPolizzeAggregate ? response.polizza.compagnia : this.nCompagnia[0]) + '/' + (this.modPolizzeAggregate ? response.polizza.numPolizza : response.polizza), config)).then(
      (res) => {
        resGetPdf = res

        let eventObj = {
          event: response,
          responseGetPdf: resGetPdf
        }

        this.eventsPdf.next(eventObj)
      },
      async (err) => {
        if (err.status == 401) {
          await lastValueFrom(this.apiS.getRefreshToken()).then(
            async (resp) => {
              await lastValueFrom(this.http.get<Blob | any>(environment.baseUrl + environment.endPoints.getPdf + (this.modPolizzeAggregate ? response.polizza.compagnia : this.nCompagnia[0]) + '/' + (this.modPolizzeAggregate ? response.polizza.numPolizza : response.polizza), config)).then(
                (res) => {
                  resGetPdf = res;

                  let eventObj = {
                    event: response,
                    responseGetPdf: resGetPdf
                  }

                  this.eventsPdf.next(eventObj)
                },
                (err) => {
                  this.bottomsheetS.openBottomSheet(err, err);
                }
              )
            },
            (err) => {
              this.authS.stopLogout = true;
              this.authS.logout(true);
            }
          )
        } else if (String(err.status).slice(0, 2) === "50") {
          this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
          if (this.sharedVariables.onMaintenance) {
            this.router.navigate(["/manutenzione"]);
          } else {
            this.bottomsheetS.openBottomSheet(err, err);
          }
        } else {
          this.bottomsheetS.openBottomSheet(err, err);
        }
      }
    )
  }
}
