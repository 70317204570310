import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-polizze-preferite',
  templateUrl: './edit-polizze-preferite.component.html',
  styleUrls: ['./edit-polizze-preferite.component.scss'],
})
export class EditPolizzePreferiteComponent implements OnInit {
  editSection!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.editSection = new FormGroup({
      descrizione: new FormControl(this.data.polizze_preferite.descrizione, [
        Validators.required,
      ]),
    });
  }

  public get descrizione() : string {
    return this.editSection.get("descrizione")!.value;
  }
}
