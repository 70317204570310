import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  showPass: boolean = false;
  oldPassword: string = "";
  newPassword: string = "";
  confirmNewPassword: string = "";
  isSpinnerChangePassReady: boolean = false;
  otpCode: string = "";
  otpRequired: boolean = false;
  otpExpDate!: string;
  authenticationDetails!: AuthenticationDetails;
  cognitoUser!: CognitoUser;
  user: string = "";

  constructor(
    private router: Router,
    private auth: AuthService,
    private _snackBar: MatSnackBar,
    private sharedVariables: SharedVariablesService
  ) { }

  ngOnInit(): void {
    if (this.sharedVariables.changePasswordForceStatus !== "active" && this.sharedVariables.changePasswordStatus !== "active") {
      this.router.navigate(['']);
    } else {
      this.user = this.auth.getLocalUsername() ? this.auth.getLocalUsername()! : "";

      let poolData = {
        UserPoolId: environment.variables.cognitoUserPoolId,
        ClientId: environment.variables.cognitoAppClientId
      };

      let userPool = new CognitoUserPool(poolData);

      let userData = {
        Username: this.user,
        Pool: userPool
      };
      this.cognitoUser = new CognitoUser(userData);
    }
  }

  onConfirm(){
    if (this.newPassword !== "" && this.confirmNewPassword !== "" && this.oldPassword !== "") {
      this.newPassword = this.newPassword.trim();
      this.confirmNewPassword = this.confirmNewPassword.trim();

      if (this.newPassword !== this.confirmNewPassword){
        this._snackBar.open("La nuova password e la conferma della nuova password non concidono", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
      } else {
        let authenticationData =  {
          Username: this.user,
          Password: this.oldPassword
        }
        this.authenticationDetails = new AuthenticationDetails(authenticationData);

        let that = this;
        this.isSpinnerChangePassReady = true

        if (environment.customAuth) this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
        else this.cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH');

        this.cognitoUser.authenticateUser(this.authenticationDetails, {
          newPasswordRequired: function (userAttributes, requiredAttributes) {
              // User was signed up by an admin and must provide new
              // password and required attributes, if any, to complete
              // authentication.

              that.cognitoUser.completeNewPasswordChallenge(that.newPassword, {} , {
                  onSuccess: function (result) {
                    that._snackBar.open("Password cambiata correttamente", "Chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
                    that.sharedVariables.changePasswordForceStatus = "";
                    that.isSpinnerChangePassReady = false;
                    that.router.navigate(['/login']);
                  },
                  onFailure: function (err) {
                    console.log(err);
                    that.isSpinnerChangePassReady = false;
                    if (err.code == "NotAuthorizedException") {
                      that._snackBar.open("La vecchia password inserita è errata. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
                    } else if (err.code == "InvalidPasswordException") {
                      that._snackBar.open("La nuova password inserita non rispetta i requisiti", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
                    } else {
                      that._snackBar.open(err.message, "Chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
                    }
                  }
              });
          },
          onSuccess: function (result) {
            that.cognitoUser.changePassword(that.oldPassword, that.newPassword, function(err, result) {
              that.isSpinnerChangePassReady = false;
              if (err) {
                alert(err.message || JSON.stringify(err));
                return;
              } else {
                that._snackBar.open("Password cambiata correttamente", "chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
                that.router.navigate(['']);
              }
            });
          },
          onFailure: function (err) {
            console.log(err.code);
            that.isSpinnerChangePassReady = false;
            if (err.code == "NotAuthorizedException") {
                that._snackBar.open("La vecchia password inserita è errata. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
              } else if (err.code == "InvalidPasswordException") {
                that._snackBar.open("La nuova password inserita non rispetta i requisiti", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
              } else {
                that._snackBar.open(err.message, "Chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
              }
          },
          customChallenge: () => {
            that.otpRequired = true;
          }
        });

      }
    } else {
      this._snackBar.open("Compilare tutti i campi", "chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
    }
  }

  verifyOtp() {
    if (this.otpCode !== "") {
      if (new Date().getTime() >= new Date(this.otpExpDate).getTime()) {
        const sb = this._snackBar.open("Il codice OTP è scaduto", 'Chiudi', {duration: Infinity, panelClass: ['auth-snackbar']})
        sb.onAction().subscribe(() => {
          if(this.otpRequired) this.goBackToLogin();
        })
      } else {
        this.otpCode = this.otpCode.trim();
        let that = this;
        this.cognitoUser.sendCustomChallengeAnswer(this.otpCode, {
          onSuccess: function (result) {
            that.cognitoUser.changePassword(that.oldPassword, that.newPassword, function(err, result) {
              that.isSpinnerChangePassReady = false;
              that.otpRequired = false;
              if (err) {
                alert(err.message || JSON.stringify(err));
                return;
              } else {
                that._snackBar.open("Password cambiata correttamente", "chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
                that.router.navigate(['']);
              }
            });
          },
          onFailure: function (err) {
            console.log(err.code);
            that.isSpinnerChangePassReady = false;
            if (err.code == "NotAuthorizedException") {
                that._snackBar.open("La vecchia password inserita è errata. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
              } else if (err.code == "InvalidPasswordException") {
                that._snackBar.open("La nuova password inserita non rispetta i requisiti", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
              } else {
                that._snackBar.open(err.message, "Chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
              }
          },
          customChallenge: function(challengeParameters) {
            that.otpExpDate = challengeParameters.tokenExpiration;
           }
        });
      }
    } else {
      this._snackBar.open("Inserire il codice ricevuto per email", "chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
    }
  }

  refreshOtp(){
    let that = this;
    if (environment.customAuth) this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
    else this.cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH');

    this.cognitoUser.authenticateUser(this.authenticationDetails, {
      newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          that.cognitoUser.completeNewPasswordChallenge(that.newPassword, {} , {
              onSuccess: function (result) {
                that._snackBar.open("Password cambiata correttamente", "Chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
                that.sharedVariables.changePasswordForceStatus = "";
                that.isSpinnerChangePassReady = false;
                that.router.navigate(['/login']);
              },
              onFailure: function (err) {
                console.log(err);
                that.isSpinnerChangePassReady = false;
                if (err.code == "NotAuthorizedException") {
                  that._snackBar.open("La vecchia password inserita è errata. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
                } else if (err.code == "InvalidPasswordException") {
                  that._snackBar.open("La nuova password inserita non rispetta i requisiti", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
                } else {
                  that._snackBar.open(err.message, "Chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
                }
              }
          });
      },
      onSuccess: function (result) {
        that.cognitoUser.changePassword(that.oldPassword, that.newPassword, function(err, result) {
          that.isSpinnerChangePassReady = false;
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          } else {
            that._snackBar.open("Password cambiata correttamente", "chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
            that.router.navigate(['']);
          }
        });
      },
      onFailure: function (err) {
        console.log(err.code);
        that.isSpinnerChangePassReady = false;
        if (err.code == "NotAuthorizedException") {
            that._snackBar.open("La vecchia password inserita è errata. Per favore, verifica e riprova", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
          } else if (err.code == "InvalidPasswordException") {
            that._snackBar.open("La nuova password inserita non rispetta i requisiti", 'Chiudi', {duration: 3000, panelClass: ['auth-snackbar']})
          } else {
            that._snackBar.open(err.message, "Chiudi", {duration: 3000, panelClass: ['auth-snackbar']})
          }
      },
      customChallenge: (challengeParameters) => {
        that.otpExpDate = challengeParameters.tokenExpiration;
        that._snackBar.open("Un nuovo codice è stato mandato alla tua email.", "", { duration: 3000, panelClass: ['auth-snackbar']})
      }
    });
  }

  showPassword() {
    this.showPass = !this.showPass;
  }

  goBackToLogin() {
    this.otpCode = "";
    this.oldPassword= "";
    this.newPassword= "";
    this.confirmNewPassword= "";
    this.isSpinnerChangePassReady = false;
    this._snackBar.dismiss();
    this.otpRequired = false;
  }

  hasNumber(input: string): boolean {
    return /\d/.test(input);
  }

  hasSpecialChar(input: string): boolean {
    return /[^a-zA-Z0-9]/.test(input);
  }

  hasUppercase(input: string): boolean {
    return /[A-Z]/.test(input);
  }

}
