<form [formGroup]="editSection" theme>
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 2em">
    <mat-icon color="primary">edit</mat-icon>
    <span style="font-size: 20px; padding-left: 0.2em"
      >Modifica descrizione</span
    >
  </div>
  <div style="margin-left: 10px">
    <mat-form-field class="example-form-field" style="width: 100%">
      <mat-label>Descrizione Polizza</mat-label>
      <input
        formControlName="descrizione"
        matInput
        type="text"
        autofocus
        onfocus="this.select()"
      />
    </mat-form-field>
  </div>

  <div align="center">
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="descrizione"
      style="margin-right: 0.5em"
      type="submit"
      [disabled]="descrizione === ''"
    >
      <mat-icon style="transform: scale(0.7)">done</mat-icon>Salva
    </button>
    <button [mat-dialog-close]="this.data.polizze_preferite.descrizione" mat-stroked-button>
      <mat-icon style="transform: scale(0.7)">clear</mat-icon>No
    </button>
  </div>
</form>
