<div class="component">

  <app-spinner fxFlex="100" fxLayout="row" fxLayoutAlign="end center" *ngIf="(!dataReady) || (!statusReady)"></app-spinner>

  <div fxFlex="100" class="m-top">
    <div *ngIf="dataReady && statusReady">

      <lib-s2e-pi-component-home-dashboard
        [nListaPolizze]="listaPolizze"
        [statsPolizze]="statsPolizze"
        [controllerElement]="controllerElement"

        [countClienti]="countClienti"
        [countPolizze]="countPolizze"
        [ctvAllaData]="ctvAllaData"
        [ctvSottoscrizione]="ctvSottoscrizione"
        [sintesiPortafoglio]="sintesiPortafoglio"

        [eventSidenav]="eventsSubject"

        [sidenavWidth]="sidenavWidth"
        [toolbarMargin]="63"
        [dateFormat] = "settingsServ.setting_config.date_settings.date_format"

        [dataBarChart]="dataBarChart"
        [dataLineChart]="dataLineChart"

        [dataAggiornamento]="dataAggiornamento"
      ></lib-s2e-pi-component-home-dashboard>

    </div>
  </div>

</div>


