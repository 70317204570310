import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { AuthService } from './../../auth/auth.service';
import { BottomsheetService } from './../../services/bottomsheet.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, timer } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-investimenti',
  templateUrl: './investimenti.component.html',
  styleUrls: ['./investimenti.component.scss']
})
export class InvestimentiComponent implements OnInit {

  ready: boolean = false;
  columnsController: any;
  MSToken: any;
  msTokenready: boolean = false;

  constructor(
    private titleService: Title,
    private apiS: ApiService,
    private router: Router,
    private bottomsheetS: BottomsheetService,
    private authS: AuthService,
    private sharedVariables: SharedVariablesService,
    private sanitizer: DomSanitizer
  ) {
    this.titleService.setTitle(environment.title + " | Fondi");
  }

  ngOnInit(): void {
    if (!environment.demo) {
      this.apiS.getMSTokenAPI().subscribe({
        next: this.nextFn,
        error: async error => {
          if (error.status == 401) {
            await lastValueFrom(this.apiS.getRefreshToken()).then(
              (resp) => {
                this.apiS.getMSTokenAPI().subscribe({
                  next: this.nextFn,
                  error: err => {
                    this.bottomsheetS.openBottomSheet(err, err);
                  }
                })
              },
              (err) => {
                this.authS.stopLogout = true;
                this.authS.logout(true);
              }
            )
          } else if (String(error.status).slice(0, 2) === "50") {
            this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
            if (this.sharedVariables.onMaintenance) {
              this.router.navigate(["/manutenzione"]);
            } else {
              this.bottomsheetS.openBottomSheet(error, error);
            }
          } else {
            this.bottomsheetS.openBottomSheet(error, error);
          }
        }
      })
    }
  }

  nextFn = (el: any) => {
    this.MSToken = el.token;
    this.apiS.setMSToken(this.MSToken);
    this.msTokenready = true;

    let dateTokenMSExpires = new Date(el.expiration).getTime() - new Date().getTime();
    const timerLoginToken = timer(dateTokenMSExpires);

    const subscribeToken = timerLoginToken.subscribe((val: any) => {
      this.apiS.getMSTokenAPI().subscribe({
        next: () => { },
        error: async err => {
          if (err.status == 401) {
            await lastValueFrom(this.apiS.getRefreshToken()).then(
              (resp) => {
                this.apiS.getMSTokenAPI().subscribe({
                  next: () => { },
                  error: err => {
                    this.bottomsheetS.openBottomSheet(err, err);
                  }
                })
              },
              (err) => {
                this.authS.stopLogout = true;
                this.authS.logout(true);
              }
            )
          } else if (String(err.status).slice(0, 2) === "50") {
            this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
            if (this.sharedVariables.onMaintenance) {
              this.router.navigate(["/manutenzione"]);
            } else {
              this.bottomsheetS.openBottomSheet(err, err);
            }
          } else {
            this.bottomsheetS.openBottomSheet(err, err);
          }
        }
      })
    });
  }

  getCorrectMSiFrame(): any {
    if (!environment.demo) {
      return this.sanitizer.bypassSecurityTrustResourceUrl('./assets/MS_iFrame/Intesa_Sanpaolo.PID20921304.ecScreenerFund.html');
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl('./assets/MS_iFrame/S2E.Screener.html');
    }
  }

  isMsTokenReady(): boolean {
    if (environment.demo) {
      return true;
    } else {
      return this.msTokenready;
    }
  }
  
}
