import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AggregatoService {

  private subject = new Subject<string[]>();

  constructor() { }

  sendOK(polizza: string[]) {
    this.subject.next(polizza);
  }

  onOk(): Observable<string[]> {
    return this.subject.asObservable();
  }

}
