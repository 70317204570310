// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  demo: false,
  customAuth: true,
  title: "S2EIntesaPi",
  baseUrl: "https://isp.dev.api.portaleintermediari.it",
  endPoints: {
    // Dashboard
    getBarChartChart: '/polizze/statistiche-tipologie',
    getDashboardData: '/polizze/statistiche-dashboard',

    // Lista Polizze
    getListaPolizze: "/polizze/lista",

    // Dettaglio Polizza
    getDettaglioPolizza: "/polizze/dettaglio-polizza/",
    getMovimentiPolizza: '/polizze/movimenti/',
    getSaldoFondiPerPolizza: '/polizze/fondi/',
    getFondiMovimentati: '/polizze/movimenti-fondi/',
    getStoricoFondi: '/polizze/storico-fondi/',
    getPdf: '/polizze/riepilogo-pdf/',
    getInformative: '/anagrafiche/fondi-prodotto/',
    getDatiAllCharts: '/polizze/dati-grafici/',

    getRefreshToken: '/auth/refresh',
    getLogout: '/auth/logout',
    getMSToken: '/auth/ms-token',
    authLogin: '/auth/loginCognito',

    // Investimenti
    getInvestimenti: '/anagrafiche/fondi/',

    // Lista preferiti
    getListaPreferiti: '/polizze/preferite',
    postListaPreferiti: '/polizze/preferite',
    putListaPreferiti: '/polizze/preferite',
    deleteListaPreferiti: '/polizze/preferite',

    // Manutenzione
    getStatusManutenzione: "/utils/stato-sistema",

    // FAQ
    getFaqDocuments: "/documenti/lista/",
    downloadFaqDocument: "/documenti/download/"
  },
  variables: {
    cognitoUserPoolId: "eu-south-1_Sx2AMjClp",
    cognitoAppClientId: "dervd1bhtcrq4j8hqiukbaah9",
  },

  // Loghi
  pathLogo: "./assets/logo/logo-intesa-sanpaolo.svg",
  pathLogoLight: "./assets/logo/logo-intesa-sanpaolo.svg",
  pathLogoMini: "./assets/logo/mini-logo-intesa-sanpaolo-white.png",

  // Favicon
  favicon: "./assets/logo/favicon.png",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
