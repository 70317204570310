import { AuthService } from './auth.service';
import { ApiService } from 'src/app/services/api.service';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private sharedVariables: SharedVariablesService,
    private apiS: ApiService,
    private router: Router,
    private authS: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
    if (environment.demo) return true;

    if (this.authS.onFirstInit) {
      return new Promise((resolve) => {
        this.sharedVariables.guardReady$.pipe(take(1)).subscribe(async () => {
          this.authS.onFirstInit = false;
          if (this.sharedVariables.onMaintenance == undefined) {
            this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
          }

          if (this.sharedVariables.onMaintenance) {
            this.authS.clearIndexedDb();
            this.router.navigate(["/manutenzione"]);
          }

          return resolve(!this.sharedVariables.onMaintenance);

        });
      });
    } else {
      if (this.sharedVariables.onMaintenance) {
        this.authS.clearIndexedDb();
        this.router.navigate(["/manutenzione"]);
      }

      return !this.sharedVariables.onMaintenance;
    }
  }

}
