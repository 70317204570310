import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMovimentiLabel'
})
export class FormatMovimentiLabelPipe implements PipeTransform {

  transform(value: string[]): string[] {
    let columns: string[] = Object.keys(value)
    let result: any[] = []
    columns.forEach((element: string) => {
      switch (element) {
        // Movimenti.model.ts
        case 'compagnia': result.push({ value: element, label: 'Compagnia' }); break;
        case 'controvalore': result.push({ value: element, label: 'Controvalore' }); break;
        case 'numeroPolizza': result.push({ value: element, label: '' }); break;
        case 'dataOperazione': result.push({ value: element, label: 'Data Operazione' }); break;
        case 'descTipoOperazione': result.push({ value: element, label: 'Tipo Operazione' }); break;
        case 'importoRata': result.push({ value: element, label: 'Importo Rata' }); break;
        case 'dataDecorrenza': result.push({ value: element, label: 'Data Decorrenza' }); break;
        case 'nProgressivo': result.push({ value: element, label: 'N^ Progressivo' }); break;
        case 'visibileMovimentiWeb': result.push({ value: element, label: 'visibileMovimentiWeb' }); break;
        case 'visibilePDF': result.push({ value: element, label: 'visibilePDF' }); break;
        case 'tipoTitolo': result.push({ value: element, label: 'tipoTitolo' }); break;
        case 'descTipoTitolo': result.push({ value: element, label: 'descTipoTitolo' }); break;
      }
    });
    return result
  }

}
