<div class="general" fxLayout="column" fxLayoutAlign="center center">
  <mat-card
    class="login-container"
    fxLayout="column"
    *ngIf="!isSpinnerActive">
    <button mat-icon-button *ngIf="otpRequired" class="back-btn" (click)="goBackToLogin()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <button mat-icon-button *ngIf="forgottenPass" class="back-btn" (click)="goBackToLoginFromForgotPw()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <div fxLayout="column" fxLayoutAlign="space-between center" style="margin-bottom: 20px;">
      <span class="login100-form-logo">
        <img [ngClass]=" isDemo ? 'logo-s2e-box' : 'logo-box'" [src]="logo" alt="" />
      </span>
    </div>

    <form *ngIf="!otpRequired && !forgottenPass"
      (submit)="onSignIn();"
      fxLayout="column"
    >

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>Inserisci nome utente</mat-label>
        <mat-icon matSuffix>person</mat-icon>
        <input
        matInput
        name="username"
        [(ngModel)]="username"
        required
        />
      </mat-form-field>

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>Inserisci password</mat-label>
        <mat-icon (click)="showPassword()" matSuffix> {{showPass ? 'visibility_off' : 'visibility'}} </mat-icon>
        <input
        matInput
        name="password"
        [type]="showPass ? 'text' : 'password'"
        [(ngModel)]="password"
        minlength="8"
        required
        />
      </mat-form-field>

      <button mat-raised-button type="submit" color="primary" [ngClass]="{'login-btn': !isSpinnerLoginReady}" [disabled]="isSpinnerLoginReady">
        <span *ngIf="!isSpinnerLoginReady" class="login-label">Login</span>
        <div class="sk-circle" style="margin: 10px auto; height: 20px; width: 20px;" *ngIf="isSpinnerLoginReady">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
      </button>


    </form>

    <form *ngIf="otpRequired && !forgottenPass"
      (submit)="verifyOtp()"
      fxLayout="column"
    >

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>Inserisci OTP</mat-label>
        <mat-icon matSuffix>vpn_key</mat-icon>
        <input
        matInput
        name="otp"
        type="text"
        [(ngModel)]="otpCode"
        required
        />
      </mat-form-field>

      <button mat-raised-button type="submit" color="primary" [ngClass]="{'login-btn': !isSpinnerLoginReady}" [disabled]="isSpinnerLoginReady">
        <span class="login-label" *ngIf="!isSpinnerLoginReady">Conferma</span>
        <div class="sk-circle" style="margin: 10px auto; height: 20px; width: 20px;" *ngIf="isSpinnerLoginReady">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
      </button>

    </form>

    <form *ngIf="forgottenPass && !verificationCodeSent"
    (submit)="forgotPassword()"
    fxLayout="column"
    >

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>Inserisci username</mat-label>
        <mat-icon matSuffix>vpn_key</mat-icon>
        <input
        matInput
        name="name"
        type="text"
        [(ngModel)]="username"
        required
        />
      </mat-form-field>

      <button mat-raised-button type="submit" color="primary" [ngClass]="{'login-btn': !checkVerificationCode}" [disabled]="checkVerificationCode">
        <span class="login-label" *ngIf="!checkVerificationCode">Invia Codice</span>
        <div class="sk-circle" style="margin: 10px auto; height: 20px; width: 20px;" *ngIf="checkVerificationCode">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
      </button>

    </form>

    <form *ngIf="forgottenPass && verificationCodeSent"
      (submit)="confirmForgotPass()"
      fxLayout="column"
    >

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>Inserisci codice di verifica</mat-label>
        <mat-icon matSuffix>vpn_key</mat-icon>
        <input
        matInput
        name="verify-code"
        type="text"
        [(ngModel)]="verificationCode"
        required
        />
      </mat-form-field>

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>Inserisci una nuova password</mat-label>
        <mat-icon (click)="showPassword()" matSuffix> {{showPass ? 'visibility_off' : 'visibility'}} </mat-icon>
        <input
        matInput
        name="new-pass"
        [type]="showPass ? 'text' : 'password'"
        [(ngModel)]="newPass"
        required
        />
      </mat-form-field>

      <mat-form-field class="example-form-field" appearance="outline">
        <mat-label>conferma nuova password</mat-label>
        <mat-icon (click)="showPassword()" matSuffix> {{showPass ? 'visibility_off' : 'visibility'}} </mat-icon>
        <input
        matInput
        name="confirm-pass"
        [type]="showPass ? 'text' : 'password'"
        [(ngModel)]="confirmPass"
        required
        (focusout)="onFocusOutEvent($event)"
        />
      </mat-form-field>

      <mat-error style="color: red !important;" *ngIf="showErrorPassMismatch">Le password non coincidono</mat-error>

      <button mat-raised-button type="submit" color="primary" [ngClass]="{'login-btn': !checkedPassword}" [disabled]="checkedPassword">
        <span class="login-label" *ngIf="!checkedPassword">Conferma</span>
        <div class="sk-circle" style="margin: 10px auto; height: 20px; width: 20px;" *ngIf="checkedPassword">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
      </button>

    </form>

    <div style="margin-top: 1em;">
      <span *ngIf="!otpRequired" (click)="changeStatusForgottenPass()">
        <span class="fake-link" *ngIf="!forgottenPass && !isSpinnerLoginReady">Hai dimenticato la password? Clicca qui.</span>
        <!-- <span class="fake-link" *ngIf="forgottenPass">Torna indietro</span> -->
      </span>

      <span *ngIf="otpRequired" class="fake-link" (click)="refreshOtp()">
        Non hai ricevuto il codice per email? Clicca qui.
      </span>

      <div (click)="openHelpDialog()">
        <span class="fake-link">
          Hai bisogno di aiuto?
        </span>
      </div>
      
      <br>
    </div>

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
      <mat-error *ngIf="formInvalid" style="color: red !important;">Compilare tutti i campi correttamente</mat-error>
    </div>

    <div fxLayout="column" fxLayoutAlign="end center" class="footer">
      <span>powered by</span>
      <img src="/assets/logo/mini-logo-s2e.png" alt="" class="logos2e" />
    </div>

  </mat-card>

  <app-spinner
    class="spinner"
    *ngIf="isSpinnerActive"
  >
  </app-spinner>
</div>


