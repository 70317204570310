import { MatDialogRef } from '@angular/material/dialog';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dati-utente',
  templateUrl: './dati-utente.component.html',
  styleUrls: ['./dati-utente.component.scss']
})
export class DatiUtenteComponent implements OnInit {

  constructor(
    private router: Router,
    private sharedVariables: SharedVariablesService,
    private dialogRef: MatDialogRef<DatiUtenteComponent>
  ) { }

  ngOnInit(): void {
  }

  setAccountDatas(localStorageOutput: string) {
    if (localStorageOutput === 'nome') {
      return localStorage.getItem('firstName')
    } else if(localStorageOutput === 'cognome') {
      return localStorage.getItem('lastName')
    } else if(localStorageOutput === 'email') {
      return localStorage.getItem('email')
    } else if(localStorageOutput === 'operationalUnit'){
      let resp = localStorage.getItem('operationalUnit')
      if (!resp || resp == "undefined" || resp == undefined) {
        return undefined
      } else return localStorage.getItem('operationalUnit')
    } else {
      return  localStorage.getItem('role')
    }
  }

}
