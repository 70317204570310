import { SettingsService } from 'src/app/services/settings.service';
import { SharedVariablesService } from './../../services/shared-variables.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ListaPolizze } from 'src/app/models/lista-polizze.model';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-polizze',
  templateUrl: './polizze.component.html',
  styleUrls: ['./polizze.component.scss']
})
export class PolizzeComponent implements OnInit {
  dataReady: boolean = false;
  dateFormat!: string;
  /**
   * value e label delle colonne della tabella.
  */
  columnsController: any = [
    {
      value: "  ",
      label: "  "
    },
    {
      value: "descTariffa",
      label: "Prodotto"
    },
    {
      value: "numPolizza",
      label: "Numero Polizza"
    },
    {
      value: "contraente",
      label: "Contraente"
    },
    {
      value: "dataDecorrenza",
      label: "Decorrenza"
    },
    // {
    //   value: "compagnia",
    //   label: "Compagnia"
    // },
    {
      value: "descStato",
      label: "Stato"
    },
    {
      value: "controvalore",
      label: "Controvalore"
    },
    //  {
    //    value: "dataScadenza",
    //    label: "Data Scadenza"
    //  }
  ];

  correlationId!: string;
  maxAggregatoLength: number;
  getBEcall: Subject<Observable<any>> = new Subject<Observable<any>>()

  constructor(
    private router: Router,
    private apiService: ApiService,
    private titleService: Title,
    private notifyService: NotificationService,
    private sharedVariables: SharedVariablesService,
    private settingsServ: SettingsService,
    private apiS: ApiService
  ) {
    this.maxAggregatoLength = this.settingsServ.setting_config.max_length_aggregato;
    this.titleService.setTitle(environment.title + " | Lista Polizze");
  }

  ngOnInit(): void {
    this.dateFormat = this.sharedVariables.frontend_config.settings_config.date_settings.date_format;
    this.correlationId = this.apiService.correlationId;
    this.dataReady = true
  }

  /**
   * Naviga verso la polizza selezionata.
   *
   * @param {Polizza} data  Evento di tipo Polizza invocato al click.
   *
  */
  transfer(polizza: any) {
    if (polizza.event.type === 'auxclick') {
      if (!polizza.rowData.length) {
        this.resetDashboardSubcriptionState(polizza.rowData.compagnia, polizza.rowData.numPolizza);
        this.router.navigate([]).then(result => {
          window.open(`dettaglio/${polizza.rowData.compagnia}/${polizza.rowData.numPolizza}`, '_blank');
        });
      } else {
        let compagnia: string[] = [],
        nPolizza: string[] = [];
        polizza.rowData.forEach((element: any) => {
          compagnia.push(element.compagnia);
          nPolizza.push(element.numPolizza);
        });
        this.resetDashboardSubcriptionState(compagnia.join(), nPolizza.join());
        this.router.navigate([]).then(result => {
          window.open(`dettaglio/${compagnia.join()}/${nPolizza.join()}`, '_blank');
        });
      }
    }else{
      if (!polizza.rowData.length) {
        this.resetDashboardSubcriptionState(polizza.rowData.compagnia, polizza.rowData.numPolizza);
        this.router.navigate(['dettaglio/', polizza.rowData.compagnia, polizza.rowData.numPolizza]);
      } else {
        let compagnia: string[] = [],
        nPolizza: string[] = [];
        polizza.rowData.forEach((element: any) => {
          compagnia.push(element.compagnia);
          nPolizza.push(element.numPolizza);
        });
        this.resetDashboardSubcriptionState(compagnia.join(), nPolizza.join());
        this.router.navigate(['dettaglio/', compagnia.join(), nPolizza.join()]);
      }
    }
  }


  callData(data: any) {
    setTimeout(() => {
      this.getBEcall.next(this.apiS.getListaPolizze(data.sortChange, data.currentPage, data.currentFilter, data.sortCampo, data.sortDirection));
    }, 1);
  }


  resetDashboardSubcriptionState(compagnia: any, polizza: any){
    let url = window.location.origin + '/' + 'dettaglio' + '/' + compagnia + '/' + polizza;
    if (url !== window.location.href) {
      this.notifyService.dettaglioPolizzaSuscribeReady = false;
    }
  }

  getToken() {
    return this.apiService.generateToken()
  }
  getEndPoint() {
    return environment.endPoints.getListaPolizze;
  }
  getBaseUrl() {
    return environment.baseUrl
  }


  public get isDemo(): boolean {
    return environment.demo;
  }

}
