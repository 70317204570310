import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-api-error-sheet',
  templateUrl: './api-error-sheet.component.html',
  styleUrls: ['./api-error-sheet.component.scss']
})
export class ApiErrorSheetComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ApiErrorSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }

  close(){
    this._bottomSheetRef.dismiss()
  }
}
