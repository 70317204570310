import { SnackBarTokenDeadlineComponent } from './components/snackbars/snack-bar-token-deadline/snack-bar-token-deadline.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/material/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TabellaPortafoglioModule } from 's2e-pi-component-lista-polizze';
import { HomeComponent } from './pages/home/home.component';
import { DettaglioPolizzaComponent } from './pages/dettaglio-polizza/dettaglio-polizza.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { S2ePiComponentDettaglioPolizzaModule } from 's2e-pi-component-dettaglio-polizza';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { FormsModule } from '@angular/forms';
import { ChangepasswordComponent } from './auth/changepassword/changepassword.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { DettaglioPolizzaLabelFormatterPipe } from './pipes/dettaglio-polizza-label-formatter.pipe';
import { S2ePiComponentListaFondiModule } from 's2e-pi-component-lista-fondi';
import { FormatMovimentiLabelPipe } from './pipes/format-movimenti-label.pipe';
import { S2ePiComponentListaMovimentiModule } from 's2e-pi-component-lista-movimenti';
import { InvestimentiComponent } from './pages/investimenti/investimenti.component';
import { S2ePiComponentInvestimentiModule } from 's2e-pi-component-investimenti';
import { S2ePiComponentHomeDashboardModule } from 's2e-pi-component-home-dashboard';
import { S2ePiComponentListaKidModule } from 's2e-pi-component-lista-kid';
import { PolizzeComponent } from './pages/polizze/polizze.component';
import { SettingsDialogComponent } from './components/dialogs/settings-dialog/settings-dialog.component';
import { S2ePiComponentListaDocumentiModule } from 's2e-pi-component-lista-documenti';
import { ApiErrorSheetComponent } from './components/dialogs/api-error-sheet/api-error-sheet.component';
import { S2ePiComponentGraficiModule } from 's2e-pi-component-grafici';
import { DatiUtenteComponent } from './components/dialogs/dati-utente/dati-utente.component';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import ITA from '@angular/common/locales/it';
import it from 'date-fns/locale/it';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomPaginator } from './custom-configurations/custom-paginator-configuration';
import { InputMaskModule } from '@ngneat/input-mask';
import { ConfirmDeleteComponent } from './components/dialogs/confirm-delete/confirm-delete.component';
import { EditPolizzePreferiteComponent } from './components/dialogs/edit-polizze-preferite/edit-polizze-preferite.component';
import { DeletePreferitiComponent } from './components/dialogs/delete-preferiti/delete-preferiti.component';
import { BackOfficeComponent } from './pages/back-office/back-office.component';
import { darkTheme, lightTheme, ThemeModule } from './theme';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
registerLocaleData(ITA, 'it');
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { HelpDialogComponent } from './components/dialogs/help-dialog/help-dialog.component';
import { LogoutSsoComponent } from './auth/logout-sso/logout-sso.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const dbConfig: DBConfig = {
  name: 'tempCacheData',
  version: 1,
  objectStoresMeta: [
    {
      store: 'dett_polizza',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'dett_polizza', keypath: 'dett_polizza', options: { unique: true } }]
    },
    {
      store: 'dashboard',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'dashboard', keypath: 'dashboard', options: { unique: true } }]
    }
  ]
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DettaglioPolizzaComponent,
    SpinnerComponent,
    SignInComponent,
    ChangepasswordComponent,
    ErrorPageComponent,
    InvestimentiComponent,
    DettaglioPolizzaLabelFormatterPipe,
    FormatMovimentiLabelPipe,
    PolizzeComponent,
    SettingsDialogComponent,
    ApiErrorSheetComponent,
    DatiUtenteComponent,
    ConfirmDeleteComponent,
    EditPolizzePreferiteComponent,
    DeletePreferitiComponent,
    BackOfficeComponent,
    SnackBarTokenDeadlineComponent,
    MaintenanceComponent,
    HelpDialogComponent,
    LogoutSsoComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule,
    TabellaPortafoglioModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,

    // Librerie
    S2ePiComponentDettaglioPolizzaModule,
    S2ePiComponentListaDocumentiModule,
    S2ePiComponentGraficiModule,
    S2ePiComponentListaFondiModule,
    S2ePiComponentListaMovimentiModule,
    S2ePiComponentInvestimentiModule,
    S2ePiComponentHomeDashboardModule,
    S2ePiComponentListaKidModule,
    InputMaskModule,
    NgxIndexedDBModule.forRoot(dbConfig),

    ThemeModule.forRoot({
      themes: [lightTheme, darkTheme],
      active: 'light'
    })
  ],
  providers: [
    DettaglioPolizzaLabelFormatterPipe,
    FormatMovimentiLabelPipe,
    { provide: MAT_DATE_LOCALE, useValue: it },
    { provide: LOCALE_ID, useValue: "it" },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
