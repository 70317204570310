<!-- Left section -->
<div fxFlex="40">
  <mat-selection-list [multiple]="false">
    <mat-list-option>
      Impostazioni Data
    </mat-list-option>
  </mat-selection-list>
</div>

<!-- Right section -->
<div fxFlex="60">

</div>
