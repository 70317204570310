<div class="component">

  <app-spinner fxFlex="100" fxLayout="row" fxLayoutAlign="end center" *ngIf="!dataReady"></app-spinner>

  <div fxFlex="100" class="m-top">
    <div *ngIf="dataReady">

      <tabella-portafoglio
        [columnsController]="columnsController"
        [dateFormat]="dateFormat"
        (emitPolizza)="transfer($event)"
        [isDemo]="isDemo"
        [maxLengthSelection]="maxAggregatoLength"
        codPromotore=""
        codUniverso=""
        endPointDemo="../assets/mocked-data-demo/lista-polizze.json"
        [BEcall]="getBEcall"
        (callForData)="callData($event)"

        sortCampo="COGNOME_CONTRAENTE"
        sortDirection="ASC"
      >
      </tabella-portafoglio>

    </div>
  </div>

</div>
