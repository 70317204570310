import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DettaglioPolizzaComponent } from './pages/dettaglio-polizza/dettaglio-polizza.component';
import { ChangepasswordComponent } from './auth/changepassword/changepassword.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { HomeComponent } from './pages/home/home.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { InvestimentiComponent } from './pages/investimenti/investimenti.component';
import { PolizzeComponent } from './pages/polizze/polizze.component';
import { BackOfficeComponent } from './pages/back-office/back-office.component';
import { AuthGuard } from './auth/auth.guard';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { LogoutSsoComponent } from './auth/logout-sso/logout-sso.component';
import { FaqComponent } from './pages/faq/faq.component';

const routes: Routes = [
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard]  },
  { path: 'dettaglio/:nCompagnia/:nPolizza', component: DettaglioPolizzaComponent, canActivate: [AuthGuard] },
  { path: 'fondi', component: InvestimentiComponent, canActivate: [AuthGuard] },
  { path: 'polizze', component: PolizzeComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [AuthGuard]},

  { path: 'login', component: SignInComponent, canActivate: [AuthGuard] },
  { path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutSsoComponent },

  {path: 'page-not-found', component: ErrorPageComponent},
  {path: 'manutenzione', component: MaintenanceComponent},
  {path: '**', redirectTo: '/dashboard'}
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
