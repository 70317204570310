import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Polizza } from '../models/polizza.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor() {}

  dettaglioPolizzaSuscribeReady = false;

  subjectInitHome: Subject<void> = new Subject<void>();
  subjectBackInitHome: Subject<void> = new Subject<void>();

  subjectLogout: Subject<void> = new Subject<void>();

  private subject = new Subject<{polizza: Polizza[], modAggregata: boolean, arrIdentificatori: any[]}>();

  // In HomeComponent (click event da html)

  /**
   * Viene invocata dall'event "(emitPolizza)" situato in HomeComponent. Imposta lo status a true e prende la polizza specifica.
   * @param {Polizza} polizza  Polizza specifica selezionata
   */
  sendOK(polizza: {polizza: Polizza[], modAggregata: boolean, arrIdentificatori: any[]}) {
    this.subject.next(polizza);
  }

  // In AppComponent (subscribe)

  /**
   * Viene invocata alla subscribe in AppComponent.
   *
   * @returns {Observable} Ritorna un Observable
   */
  onOk(): Observable<{polizza: Polizza[], modAggregata: boolean, arrIdentificatori: any[]}> {
    return this.subject.asObservable();
  }


  sendInitHome() {
    this.subjectInitHome.next();
  }

  onSubInitHome() {
    return this.subjectInitHome.asObservable();
  }


  sendBackInitHome() {
    this.subjectBackInitHome.next();
  }

  onSubBackInitHome() {
    return this.subjectBackInitHome.asObservable();
  }
}
