<div fxFLex="100" fxLayout="row" fxLayoutAlign="space-between center" class="m-bottom">
    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon color="primary">help_outline</mat-icon>
        <h1 style="margin: 0 12px;">Richiesta di Assistenza</h1>
    </div>
</div>

<div>
    <span>Per richieste di assistenza vogliate aprire un Incident tramite <b>Help4you</b>.</span>
</div>
<div style="margin: 10px 0">
    <span>Qui di seguito il percorso da seguire:</span>
</div>

<div style="margin: 12px 0">
    <span><b>Home</b> > <b>Assistenza</b> > <b>Insurance</b> > <b>Area Divisione Insurance Assistenza</b> > <b>Prodotto Richiesta, menù a tendina </b> > <b>Portale Intermediari.</b></span>
</div>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Chiudi</button>
</mat-dialog-actions>