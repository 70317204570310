import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ApiErrorSheetComponent } from '../components/dialogs/api-error-sheet/api-error-sheet.component';

@Injectable({
  providedIn: 'root'
})
export class BottomsheetService {

  constructor(
    private _bottomSheet: MatBottomSheet,
  ) { }

  openBottomSheet(err: any, req: any): void {
    this._bottomSheet.open(ApiErrorSheetComponent, {
      disableClose: true,
      data: {
        error: err,
        request: req
      }
    });
  }

}
