import { Injectable } from '@angular/core';
import { LibraryController } from '../models/library-controller.model';
import { Settings } from '../models/settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  setting_config!: Settings;

  availableLibraries: LibraryController[] = [];

  constructor() { }
}
