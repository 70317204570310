<div fxFLex="100" fxLayout="row" fxLayoutAlign="space-between center" class="m-bottom">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon color="primary">account_circle</mat-icon>
    <h1 style="margin: 0 12px;">Dati Utente</h1>
  </div>
  <div>
    <button mat-icon-button color="primary" [mat-dialog-close]="true"><mat-icon>clear</mat-icon></button>
  </div>
</div>

<div style="margin-left: 37px;">
  <div fxLayout="row" fxLayoutAlign="start center">
    <span>
        Nome:
    </span>
    <h2 style="margin: 0 12px 6px;">
        {{setAccountDatas('nome')}}
    </h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <span>
        Cognome:
    </span>
    <h2 style="margin: 0 12px 6px;">
        {{setAccountDatas('cognome')}}
    </h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <span>
      Email/Matricola:
    </span>
    <h2 style="margin: 0 12px 6px;">
      {{setAccountDatas('email')}}
    </h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="setAccountDatas('operationalUnit')">
    <span>
      Punto Operativo:
    </span>
    <h2 style="margin: 0 12px 6px;">
      {{setAccountDatas('operationalUnit')}}
    </h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <span>
        Ruolo:
    </span>
    <h2 style="margin: 0 12px 6px;">
        {{setAccountDatas('ruolo')}}
    </h2>
  </div>
</div>
