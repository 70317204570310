import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, lastValueFrom, catchError, throwError, forkJoin, switchMap, map } from 'rxjs';
import { DettaglioPolizza } from '../models/dettaglio-polizza.model';
import { ListaPolizze } from '../models/lista-polizze.model';
import { MatSort } from '@angular/material/sort';
import { GeneralConfig } from '../models/general-config.model';
import { BottomsheetService } from './bottomsheet.service';
var jwt = require('jsonwebtoken');

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  token: any;
  correlationId: any;

  clickedPolizza: any;

  apiGatewayToken: any = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik1EY3hOemRHTnpGRFJrSTRPRGswTmtaRU1FSkdOekl5TXpORFJrUTROemd6TWtOR016bEdOdyJ9.eyJodHRwczovL21vcm5pbmdzdGFyLmNvbS9tc3Rhcl9pZCI6IjczMUYzQjlFLUQxMEQtNEJCNS04OTVBLTIzOEM2NDZBNDc2MSIsImh0dHBzOi8vbW9ybmluZ3N0YXIuY29tL2VtYWlsIjoiZWMtaW50ZXNhX3NhbnBhb2xvLTIwMjJAZWFtc2Vjc2VydmljZS5jb20iLCJodHRwczovL21vcm5pbmdzdGFyLmNvbS9yb2xlIjpbIkRldlMuQURFV2ViU2VydmljZXMiLCJFQy5TZXJ2aWNlLkNvbmZpZ3VyYXRpb24iLCJFQ1VTLkFQSS5BdXRvY29tcGxldGUiLCJFQ1VTLkFQSS5TY3JlZW5lciIsIkVDVVMuQVBJLlNlY3VyaXRpZXMiLCJQQUFQSVYxLkNvcmUiLCJQQUFQSVYxLlByZW1pdW0uRVNHIiwiUEFBUElWMS5QcmVtaXVtLlJpc2tTY29yZSJdLCJodHRwczovL21vcm5pbmdzdGFyLmNvbS9jb21wYW55X2lkIjoiNTM0YmIxMzQtMzA1Zi00OGFiLTgyZjEtY2NlMGVkMWVhODNhIiwiaHR0cHM6Ly9tb3JuaW5nc3Rhci5jb20vaW50ZXJuYWxfY29tcGFueV9pZCI6IkNsaWVudDAiLCJodHRwczovL21vcm5pbmdzdGFyLmNvbS9kYXRhX3JvbGUiOlsiUVMuTWFya2V0cyIsIlFTLlB1bGxxcyIsIlNBTC5TZXJ2aWNlIl0sImh0dHBzOi8vbW9ybmluZ3N0YXIuY29tL2NvbmZpZ19pZCI6IklOVEVTQV9TQU5QQU9MT19FQyIsImh0dHBzOi8vbW9ybmluZ3N0YXIuY29tL3VpbV9yb2xlcyI6IiIsImlzcyI6Imh0dHBzOi8vbG9naW4tcHJvZC5tb3JuaW5nc3Rhci5jb20vIiwic3ViIjoiYXV0aDB8NzMxRjNCOUUtRDEwRC00QkI1LTg5NUEtMjM4QzY0NkE0NzYxIiwiYXVkIjpbImh0dHBzOi8vYXV0aDAtYXdzcHJvZC5tb3JuaW5nc3Rhci5jb20vbWFhcyIsImh0dHBzOi8vdWltLXByb2QubW9ybmluZ3N0YXIuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTY2MTQyOTc3MywiZXhwIjoxNjYxNDMzMzczLCJhenAiOiJpUWtXeG9hcEo5UHhsOGNHWkx5YVhac2JYVjc5ZzY0bSIsInNjb3BlIjoib3BlbmlkIiwiZ3R5IjoicGFzc3dvcmQifQ.CrmuG4j_EpyvXVxhvgG2w2dx8kziw-zOnaBaKUaz7qaQ-ebRwGDsrmtd_kXdM32HwRCFoOVPbIbUPiYNhT9Xfkfa8L6mf2TqvVWJGbWmLPIXBvq6kSX7iGTjGI9Z-hg7oOKk71BSNqvxfsTuB7P0lvwDfRyN2MHOHdkzT38H5h9aw821zmKmcNZKEhoG_wAXqdHGcf60pEhUkvEVNprOnF_waGP7lG4sm5lEYHGpdAqgc6GjYUf3p7EZF_JVGcPLbCLAw0CdPcBqlP9TUkMD6HRpX_fr70nZOISflAQ0llCysWovyQ-wvZLVzPK3b1Che4NRp4gDs3Sfh0-5FeJ7LQ';

  constructor(
    private http: HttpClient,
    private sharedVariables: SharedVariablesService,
    private router: Router,
    private bottomsheetS: BottomsheetService,
    private notfyS: NotificationService
  ) { }

  postAuthLogin(accessToken: string) {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true
    };

    let body = {
      access_token: accessToken
    }

    return this.http.post(environment.baseUrl + environment.endPoints.authLogin, body, config)
  }

  generateToken() {
    this.token = jwt.sign(
      {
        sub: 'lhL2TuaCT2GAvgAQqGd7XMWm6XwES2qsgBETZHCeQcYPLlQrYkE3defDwAJEVF0frUK45AicfxvxuN7sKYfc8ZNdAVOT8pbAQ5LmzEB9ASUojMFr8i9ppu4Z',
        audience: 'web',
        roles: ['GUEST', 'BACK_OFFICE'],
        isEnabled: true,
        codClien: '58959760',
      },
      'secret',
      { expiresIn: 15 * 60 }
    );

    return this.token;
  }

  generateCorrelationId() {
    let seed: string =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let correlationId: string = '';

    for (var i = 0; i < 16; i++) {
      correlationId += seed.charAt(Math.floor(Math.random() * seed.length));
    }

    this.correlationId = correlationId;
  }
  generateHeaders() {
    let headers = new HttpHeaders({
      'X-Api-Key':
        '2iOBC3f3kVW9TMaqUPaJv5T2Kf1hgDUJnRW0R11rk0CMxERPaRYJo7NKApRsM5XT7o5qeqTEybEPGDjAZZkN2lij8pjw4qzPKtWKJeVFLtu0b9iKNG8VkVzK',
    });

    return headers;
  }

  generatePdfHeaders() {
    let headers = new HttpHeaders({
      'X-Api-Key':
        '2iOBC3f3kVW9TMaqUPaJv5T2Kf1hgDUJnRW0R11rk0CMxERPaRYJo7NKApRsM5XT7o5qeqTEybEPGDjAZZkN2lij8pjw4qzPKtWKJeVFLtu0b9iKNG8VkVzK',
      Accept: 'application/pdf',
    });

    return headers;
  }

  /**
   * @example 28xzw8slsg.execute-api.eu-south-1.amazonaws.com/dev/getpromoterpolicies/925/IWB/true
   *
   * @param {string | number} codUniverso  Codice Universo
   * @param {string | number} codPromotore  Codice Promotore
   *
   * @returns {Observable} La lista di tutte le polizze filtrato per codice Promotore
   */
  getListaPolizze(
    sort: MatSort,
    page: { pageIndex: number, pageSize: number },
    filter: string, //Parametri per Tabella Server Side
    sortCampoInit?: any | string, //sorta la colonna all'onInit in base ad un campo di Input
    sortDirectionInit?: any | string //Input della direzione del sort all'onInit
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true
    }
    let queryString = "?pageSize=" + page.pageSize + "&offset=" + page.pageIndex + "&sortDirection=" + (Object.keys(sort).length === 0 ? sortDirectionInit : sort.direction) + "&sortField=" + (Object.keys(sort).length === 0 ? sortCampoInit : (sort.active === 'contraente' ? 'COGNOME_CONTRAENTE' : sort.active)) + "&currentFilter=" + filter;

    return this.http.get<any>(environment.baseUrl + environment.endPoints.getListaPolizze + queryString, config).pipe(
      catchError(async (error: any, caught: Observable<any>) => {
        if (error.status == 401) {
          var result
          await lastValueFrom(this.getRefreshToken()).then(
            (resp) => result = true,
            (err) => result = false
          )
          if (result) {
            return caught.pipe(
              map((data: any) => {
                return data
              }),
              catchError((err: any, caught: Observable<any>) => {
                this.bottomsheetS.openBottomSheet(error, error);
                return throwError(() => err)
              })
            )
          } else {
            this.notfyS.subjectLogout.next();
            return throwError(() => error)
          }

        } else if (String(error.status).slice(0, 2) === "50") {
          this.sharedVariables.onMaintenance = await this.getMaintenanceStatus()
          if (this.sharedVariables.onMaintenance) {
            this.router.navigate(["/manutenzione"]);
            return throwError(() => error)
          } else {
            this.bottomsheetS.openBottomSheet(error, error);
            return throwError(() => error)
          }
        } else {
          this.bottomsheetS.openBottomSheet(error, error);
          return throwError(() => error)
        }
      })
    );
  }

  /**
   * @example 28xzw8slsg.execute-api.eu-south-1.amazonaws.com/dev/getpolicy/25/000005952940
   *
   * @param {string | number} codCompagnia  Codice Compagnia
   * @param {string | number} codPolizza  Codice Polizza
   *
   * @returns {Observable} I dettagli di una singola polizza
   */
  getDettaglioPolizza(
    codCompagnia: string | number,
    codPolizza: string | number
  ): Observable<any> {
    //Funziona in GET!
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get<{ polizza: DettaglioPolizza }>(
      environment.demo
        ? environment.baseUrl + environment.endPoints.getDettaglioPolizza + "dettaglio-polizza-" + codPolizza + ".json"
        : environment.baseUrl + environment.endPoints.getDettaglioPolizza + codCompagnia + '/' + codPolizza,
      config
    );
  }

  /**
   * @example 28xzw8slsg.execute-api.eu-south-1.amazonaws.com/dev/getpolicymovements/25/000005195651
   *
   * @param {string | number} codCompagnia  Codice Compagnia
   * @param {string | number} codPolizza  Codice Polizza
   *
   * @returns {Observable} I movimenti di una singola polizza
   */
  getMovimentiPolizza(
    codCompagnia: string | number,
    codPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.demo
        ? environment.baseUrl + environment.endPoints.getMovimentiPolizza + "movimenti-" + codPolizza + ".json"
        : environment.baseUrl + environment.endPoints.getMovimentiPolizza + codCompagnia + '/' + codPolizza,
      config
    );
  }

  //---------- FONDI ----------

  /**
   * @example baseUrl + '/fund/getPolicyFunds/'+codCompagnia = 25 +'/'+codPolizza = 000005164732 +'/'+tariffa = UB7
   *
   * @param {string | number} codCompagnia
   * @param {string | number} tariffa
   * @param {string | number} codPolizza
   *
   * @returns {Observable} Lista di fondi per Polizza
   */
  getSaldoFondiPerPolizza(
    codCompagnia: string | number,
    codPolizza: string | number,
  ): Observable<any> {
    //Funziona in GET!
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.demo
        ? environment.baseUrl + environment.endPoints.getSaldoFondiPerPolizza + "fondi-attuali-" + codPolizza + ".json"
        : environment.baseUrl + environment.endPoints.getSaldoFondiPerPolizza + codCompagnia + '/' + codPolizza,
      config
    );
  }

  getClickChartsFondi(codCompagnia?: any, codPolizza?: any, dateFilter?: any): any {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    if (environment.demo) {
      return this.http.get('../assets/mocked-data-demo/dettaglio-polizza/fondi-charts.json');
    } else {
      return this.http.get(environment.baseUrl + environment.endPoints.getSaldoFondiPerPolizza + codCompagnia + '/' + codPolizza + '/' + dateFilter, config);
    }
  }

  /**
   * @example baseUrl + '/fund/getPolicyFunds/'+codCompagnia = 25 +'/'+codPolizza = 000005164732 +'/'+tariffa = UB7
   *
   * @param {string | number} codCompagnia
   * @param {string | number} codPolizza
   * @param {string | number} dataOperazione
   * @param {string | number} dataCliccataDaVisualizzare
   *
   * @returns {Observable} Lista di fondi per Polizza
   */
  getLetterePerPolizza(
    codCompagnia: string | number,
    codPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    let body = {
      codCompagnia: codCompagnia,
      codPolizza: codPolizza,
    };

    // return this.http.post(environment.baseUrl + environment.endPoints.getLetterePerPolizza + codCompagnia + codPolizza, config)
    return this.http.get('./assets/mocked-data-pi/lettere.json');
  }

  /**
   * @example baseUrl + '/fund/getPolicyFunds/'+codCompagnia = 25 +'/'+codPolizza = 000005164732 +'/'+tariffa = UB7
   *
   * @returns {Observable} Lista di fondi per Polizza
   */
  getMockedKID(
    codCompagnia: string | number,
    numPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    // return this.http.get(environment.baseUrl + environment.endPoints.getSaldoFondiAllaData + codCompagnia + '/' + numPolizza + '/' + '/', config)
    return this.http.get('./assets/mocked-data-pi/mockedKID.json');
  }

  /**
   * @example baseUrl + '/fund/getPolicyFunds/'+codCompagnia = 25 +'/'+codPolizza = 000005164732 +'/'+tariffa = UB7
   *
   * @returns {Observable} Lista di fondi per Polizza
   */
  getFilteredMockedKID(
    codCompagnia: string | number,
    numPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
    };

    // return this.http.get(environment.baseUrl + environment.endPoints.getSaldoFondiAllaData + codCompagnia + '/' + numPolizza + '/' + '/', config)
    return this.http.get('./assets/mocked-data-pi/filteredMockedKID.json');
  }

  /**
   * @example baseUrl + '/fund/getPolicyFunds/'+codCompagnia = 25 +'/'+codPolizza = 000005164732 +'/'+tariffa = UB7
   *
   * @returns {Observable} Lista di fondi per Polizza
   */
  getInformative(
    codCompagnia: string | number,
    numPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.baseUrl +
      environment.endPoints.getInformative +
      codCompagnia +
      '/' +
      numPolizza,
      config
    );
  }

  // ----------------------- HOME SERVICE ------------------

  /**
   * @example
   *
   * @param {string} codUniverso
   * @param {string | undefined} codPromotore
   *
   *
   * @returns {Observable}
   */
  getFondiMovimentati(
    codCompagnia: string | number,
    codPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };


    return this.http.get(
      environment.demo
        ? environment.baseUrl + environment.endPoints.getFondiMovimentati
        : environment.baseUrl + environment.endPoints.getFondiMovimentati +
        codCompagnia +
        '/' +
        codPolizza +
        '?nProgressivo=00&dataOperazione=2022-02-28',
      config
    ); //TODO: Passare duprec e data CORRETTI
  }

  /**
   * @example
   *
   * @param {string} codUniverso
   * @param {string | undefined} codPromotore
   *
   *
   * @returns {Observable}
   */
  getStoricoFondi(
    codCompagnia: string | number,
    codPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.demo
        ? environment.baseUrl + environment.endPoints.getStoricoFondi + "storico-fondi-" + codPolizza + ".json"
        : environment.baseUrl + environment.endPoints.getStoricoFondi + codCompagnia + '/' + codPolizza,
      config
    );
  }

  /**
   * @example
   *
   * @param {string | number} codCompagnia
   * @param {string | number} codPolizza
   *
   * @returns {Observable}
   */
  getPdf(
    codCompagnia: string | number,
    codPolizza: string | number
  ): Observable<any> {
    let config = {
      headers: this.generatePdfHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.baseUrl +
      environment.endPoints.getPdf +
      codCompagnia +
      '/' +
      codPolizza,
      config
    );
  }

  /**
   * @example 28xzw8slsg.execute-api.eu-south-1.amazonaws.com/dev/getpromoterpolicies/925/IWB/true
   *
   * @param {string | number} codUniverso  Codice Universo
   * @param {string | number} codPromotore  Codice Promotore
   *
   * @returns {Observable} La lista di tutte le polizze filtrato per codice Promotore
   */
  getInvestimenti(): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };
    // let queryString = "?pageSize=" + page.pageSize + "&offset=" + page.pageIndex + "&sortDirection=" + (Object.keys(sort).length === 0 ? sortDirectionInit : sort.direction) + "&sortField=" + (Object.keys(sort).length === 0 ? sortCampoInit : sort.active) + "&currentFilter=" + filter; //TODO: GESTIRE SORT ACTIVE CON INPUT() VALUE di RISERVA! Potrebbe dare errore se si cambiano i nomi delle colonne
    let fakeQueryString =
      '?pageSize=50&offset=0&sortDirection=ASC&sortField=ISIN&currentFilter=';
    return this.http.get('./assets/mocked-data-pi/investimenti.json');
    return this.http.get<any>(
      environment.baseUrl +
      environment.endPoints.getInvestimenti +
      fakeQueryString,
      config
    );
  }

  /**
   * @example
   *
   * @param {any} x  Codice Universo
   * @param {any} y  Codice Promotore
   *
   * @returns {Observable} La lista di tutte le polizze filtrato per codice Promotore
   */
  getDatiGrafici(codCompagnia: any, codPolizza: any): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.demo
        ? environment.baseUrl + environment.endPoints.getDatiAllCharts + "dati-grafici-" + codPolizza + ".json"
        : environment.baseUrl + environment.endPoints.getDatiAllCharts + codCompagnia + '/' + codPolizza,
      config
    );
  }

  /**
   * @example
   *
   * @param {any} x  Codice Universo
   * @param {any} y  Codice Promotore
   *
   * @returns {Observable} La lista di tutte le polizze filtrato per codice Promotore
   */
  getDatiBarChart(): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.baseUrl + environment.endPoints.getBarChartChart,
      config
    );
  }

  /**
   * @example
   *
   * @param {any} x  Codice Universo
   * @param {any} y  Codice Promotore
   *
   * @returns {Observable} La lista di tutte le polizze filtrato per codice Promotore
   */
  getDashboardData(): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.baseUrl + environment.endPoints.getDashboardData,
      config
    );
  }

  setMSToken(msToken: any) {
    return localStorage.setItem('apiGatewayToken', msToken);
  }

  getMSToken() {
    return localStorage.getItem('apiGatewayToken');
  }

  getRefreshToken() {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.post(
      environment.baseUrl + environment.endPoints.getRefreshToken,
      {},
      config
    );
  }

  getLogout() {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.baseUrl + environment.endPoints.getLogout,
      config
    );
  }

  getMSTokenAPI() {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(
      environment.baseUrl + environment.endPoints.getMSToken,
      config
    );
  }

  savePolizza(dataRow: any) {
    this.clickedPolizza = dataRow;
  }

  getListaPreferiti(): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };
    return this.http.get(
      environment.baseUrl + environment.endPoints.getListaPreferiti,
      config
    );
  }

  async getMaintenanceStatus(): Promise<boolean> {
    let config = {
      headers: this.generateHeaders(),
    };

    let result!: boolean;

    await lastValueFrom(this.http.get(environment.baseUrl + environment.endPoints.getStatusManutenzione, config)).then(
      (resp: any) => {
        result = resp.manutenzione;
        this.sharedVariables.last_update_date_flussi = resp.data_ultima_elaborazione_flussi;
      },
      (error: any) => { result = false }
    );

    return result;
  }

  getMaintenanceRefresh(): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
    };

    return this.http.get(
      environment.baseUrl + environment.endPoints.getStatusManutenzione,
      config
    );
  }

  postListaPreferiti(polizze: any[], descrizione: string) {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    let body = {
      nome_gruppo: 'PREFERITI',
      descrizione: descrizione,
      polizze: polizze,
    };

    return this.http.post(
      environment.baseUrl + environment.endPoints.postListaPreferiti,
      body,
      config
    );
  }

  editListaPreferiti(newPolizzePreferite: any): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };
    return this.http.put(
      environment.baseUrl + environment.endPoints.putListaPreferiti,
      newPolizzePreferite,
      config
    );
  }

  deletepolizzaPreferita(id: string): Observable<any> {
    let config = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.delete(
      environment.baseUrl +
      environment.endPoints.deleteListaPreferiti +
      '/' +
      id,
      config
    );
  }

  getFAQDocuments(): any {
    let config: any = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get(environment.baseUrl + environment.endPoints.getFaqDocuments, config);
  }

  downloadFAQDocument(docName: any): any {
    let config: any = {
      headers: this.generateHeaders(),
      withCredentials: true,
    };

    return this.http.get<any>(
      environment.baseUrl + environment.endPoints.downloadFaqDocument + docName,
      config
    );
  }
}
