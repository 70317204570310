import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private subject = new Subject<number>();
  private chipSubject = new Subject<void>();
  private childChipSubject = new Subject<void>();
  private changePreferitiFromSidenav = new Subject<void>();
  private changePreferitiFromDettaglio = new Subject<void>();
  private changePreferitiDisableState = new Subject<void>();

  current_polizza_is_preferita: boolean = false;

  constructor() { }

  sendEvent(margin: number){
    this.subject.next(margin);
  }

  receiveMessage(){
    return this.subject.asObservable();
  }

  sendChipEvent(){
    this.chipSubject.next();
  }

  receiveChipMessage(){
    return this.chipSubject.asObservable();
  }

  sendChipEvtToChild(){
    this.childChipSubject.next();
  }

  sendChangePreferitiFromSidenav(){
    this.changePreferitiFromSidenav.next();
  }

  receiveChangePreferitiFromSidenav(){
    return this.changePreferitiFromSidenav.asObservable();
  }

  sendChangePreferitiFromDettaglio(){
    this.changePreferitiFromDettaglio.next();
  }

  receiveChangePreferitiFromDettaglio(){
    return this.changePreferitiFromDettaglio.asObservable();
  }

  sendChangePreferitiDisableState() {
    this.changePreferitiDisableState.next()
  }

  receiveChangePreferitiDisableState() {
    return this.changePreferitiDisableState.asObservable();
  }
}
