import { Theme } from './symbols';

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    '--primary': '#258900',
    '--secondary': '#fa9600',
    '--background-color': '#fafafa',
    '--text-dati-utente': '#ffffff',
    '--text-color': 'black',
    '--icon-color-on-bg': 'white',
    '--card-color': '#ffffff',
    '--sidenav-color': '#f5f5f5',
    '--background-tooltip': '#fffb',
    '--overlay-bg-color': '#f5f5f5',
    '--row-hover': '#efefef'
  }
};
