<div theme>

  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 2em;">
    <mat-icon color="primary">delete</mat-icon>
    <span style="font-size: 20px; padding-left: 0.2em;">Vuoi eliminare l' elemento selezionato dai preferiti?</span>
  </div>
  <!-- <p *ngIf="data.history > 0" style="margin-left: 28px;">{{this.data.history[this.data.indexContact].cognome}} - {{this.data.history[this.data.indexContact].nPolizza}}</p> -->

  <div align="end">
    <button (click)="onClickYes()" mat-raised-button color="primary" style="margin-right: 0.5em;" [disabled]="isDeleting">
      <div fxLayout="row" fxLayoutAlign="center center" style="padding-right: 0.3em;">
        <mat-icon style="transform: scale(0.7);">done</mat-icon><span>Sì</span>
      </div>
    </button>
    <button [mat-dialog-close]="false" mat-stroked-button>
      <div fxLayout="row" fxLayoutAlign="center center" style="padding-right: 0.3em;">
        <mat-icon style="transform: scale(0.7);">clear</mat-icon><span>No</span>
      </div>
    </button>
  </div>

</div>



