import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';

@Component({
  selector: 'app-back-office',
  templateUrl: './back-office.component.html',
  styleUrls: ['./back-office.component.scss']
})
export class BackOfficeComponent implements OnInit {

  constructor(
    public router: Router,
    public sharedVariables: SharedVariablesService,
  ) {
    this.router.events.pipe(
      filter((event: any): event is NavigationEnd => event instanceof NavigationEnd && event.urlAfterRedirects !== "/back-office")
    ).subscribe(() => {
      this.sharedVariables.isBackOfficeActive = '';
    })

   }

  ngOnInit(): void {
    if (this.sharedVariables.isBackOfficeActive !== 'active') this.router.navigate(['']);
  }

}
