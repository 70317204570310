<div #dettaglio_pol_container class="component" *ngIf="!easterEgg; else easterEggBlock">

  <app-spinner fxFlex="100" fxLayout="row" fxLayoutAlign="end center" *ngIf="!ready"></app-spinner>
  <!-- style="position: relative;" [ngStyle]="{'top': getDettPolH()-150 + 'px'}" TODO: trovare soluzione per calcolare metà altezza schermo (NG0100)-->

  <div fxFlex="100" class="m-top add-m-top" style="margin-bottom: 22em">

    <lib-s2e-pi-component-dettaglio-polizza id="Dettaglio-Polizza"
      *ngIf="ready && dettaglioPolActive && dataDettaglioPolizza.length > 0"
      [dettaglioPolizza]="dataDettaglioPolizza"
      [dettaglioPolizzaColumnsController]="dettaglioPolizzaColumnsController"
      [dateFormat]="dateFormat"
      [modPolizzeAggregate]="modPolizzeAggregate"
      [arrPolizze]="polizzeAggregate"
      [endPoint]="getEndPointPdf()"
      [baseUrl]="getBaseUrl()"
      [isPreferito]="sidenavServ.current_polizza_is_preferita"
      [changePreferiti]="sidenavServ.receiveChangePreferitiFromSidenav()"
      [changePreferitiDisableState]="sidenavServ.receiveChangePreferitiDisableState()"
      (emitPolizza)="transfer($event)"
      (emitPreferiti)="transferChangePreferiti()"
      [isDemo]="getEnvDemo()"
      (exportPdf)="exportPdf($event)"
      [subjectPdf]="eventsPdf.asObservable()"
    ></lib-s2e-pi-component-dettaglio-polizza>

    <lib-s2e-pi-component-lista-fondi id="Fondi"
      *ngIf="ready && fondiActive && (dataFondi.length > 0 || storicoFondi.length > 0)"
      [fondi]="dataFondi"
      [dateFormat]="dateFormat"
      [storicoFondi]="storicoFondi"
      [selectionObs]="notificaAggregato.onOk()"
      [modPolizzeAggregate]="modPolizzeAggregate"
      [arrayNumPolizzeInput]="polizzeAggregate"
      [baseUrl]="getBaseUrl()"
      [endPOint]="getEndPointMsToken()"
      (csvEventAttuale)="exportCsvAttuale($event)"
      (csvEventStorico)="exportCsvStorico($event)"
      [isDemo]="isDemo"
      (exportMSToken)="isDemo ? null : exportMSToken()"
      [subjectMSToken]="eventsMSToken"
      [isDarkModeOn]="sharedVariables.activeTheme.name !== 'light'"
    ></lib-s2e-pi-component-lista-fondi>

    <div *ngIf="ready && graficiActive" [ngStyle]="{'display': checkDataGraphEmpty() ? 'block' : 'none'}" id="Analisi-Portafoglio" #analisiPortafoglio>
      <lib-s2e-pi-component-grafici
        [numeroFondiCasoStandard]="10"
        [allData]="this.graphData"
        [polizzaSelectionChange]="notificaAggregato.onOk()"
        [events]="eventsSubject.asObservable()"
        [onInitEvent]="onInitEvent.asObservable()"
        [chipEvents]="sidenavServ.receiveChipMessage()"
        [modPolizzeAggregate]="modPolizzeAggregate"
        [areCostsCashFlows]="areCostsCashFlows"
        [dateFormat]="dateFormat"
        [disableConsultativePieCharts]="true"
        [polizzeAggregateController]="polizzeAggregate"
        [isDemo]="getEnvDemo()"
        [graphsPerformanceColor]="this.graphsPerformanceColor"
        [dialogSubject]="clickChartSubject"

        (clickChartEmitter)="clickChart($event)"
        (exportCsvPerformance)="exportCsvPerformance($event)"
      ></lib-s2e-pi-component-grafici>
    </div>

    <lib-s2e-pi-component-lista-movimenti
      *ngIf="ready && movimentiActive && dataMovimenti.length > 0"
      id="Movimenti"
      [movimentiPolizza]="dataMovimenti"
      [selectionChange]="notificaAggregato.onOk()"
      [movimentiPolizzaController]="movimentiPolizzaController"
      [dateFormat]="dateFormat"
      [arrayNumPolizzeInput]="polizzeAggregate"

      (exportDataDialog)="exportDataDialog($event)"
      [subjectDialog]="eventsDataDialogSubject.asObservable()"

      [demo]="getEnvDemo()"
      [tableRowName]="'Importo investito'"

      [modPolizzeAggregate]="modPolizzeAggregate"
    ></lib-s2e-pi-component-lista-movimenti>

    <!-- FIXME: -->
    <lib-s2e-pi-component-lista-documenti id="Comunicazioni"
      *ngIf="ready && documentiActive && dataLettere.length > 0"
      [dataLettere]="dataLettere[0]"
      [dateFormat]="dateFormat"
    ></lib-s2e-pi-component-lista-documenti>

    <!-- FIXME: -->
    <lib-s2e-pi-component-lista-kid id="Informative"
      *ngIf="ready && kidActive && filteredKID.length > 0"
      [fondiAttivi]="filteredKID[0]"
      [fondiNonAttivi]="KID[0]"
    ></lib-s2e-pi-component-lista-kid>

  </div>

</div>

<ng-template #easterEggBlock>
  <div class="picture" style="transform: scale(1.5);">
    <div class="forest">
      <div class="forest__tree"></div>
      <div class="forest__monster-1">
        <div></div>
        <div></div>
      </div>
      <div class="forest__monster-2">
        <div></div>
      </div>
      <div class="forest__ghost">
        <div></div>
      </div>
      <div class="forest__moss"></div>
    </div>
    <div class="house">
      <div class="house__wall">
        <div class="bat-1">
          <div></div>
        </div>
        <div class="bat-2">
          <div></div>
        </div>
      </div>
      <div class="house__window">
        <div class="greenery"></div>
        <div class="leaf-1"></div>
        <div class="leaf-2"></div>
        <div class="leaf-3"></div>
        <div class="leaf-4"></div>
        <div class="leaf-5"></div>
        <div class="leaf-6"></div>
        <div class="leaf-7"></div>
        <div class="mushroom-1"></div>
        <div class="mushroom-2"></div>
        <div class="lamp"></div>
        <div class="casket"></div>
        <div class="pineapple"></div>
        <div class="berries"></div>
      </div>
      <div class="shelf">
        <div class="shelf__staff-1"></div>
        <div class="shelf__staff-2">
          <div class="dust-1"></div>
          <div class="dust-2"></div>
          <div class="dust-3"></div>
          <div class="dust-4"></div>
        </div>
        <div class="shelf__staff-3"></div>
        <div class="shelf__staff-4"></div>
        <div class="shelf__staff-5"></div>
        <div class="shelf__staff-6"></div>
        <div class="shelf__staff-7"></div>
        <div class="shelf__staff-8">
          <div class="dust-1"></div>
          <div class="dust-2"></div>
          <div class="dust-3"></div>
          <div class="dust-4"></div>
        </div>
        <div class="shelf__staff-9"></div>
        <div class="shelf__staff-10"></div>
        <div class="shelf__staff-11"></div>
        <div class="shelf__herb-rope-1"></div>
        <div class="shelf__herbs-1"></div>
        <div class="shelf__herbs-2">
          <div class="shelf__herb-rope-2"></div>
        </div>
        <div class="shelf__herbs-3">
          <div class="shelf__herb-rope-3"></div>
        </div>
        <div class="shelf__herbs-4">
          <div class="shelf__herb-rope-4"></div>
        </div>
        <div class="shelf__thing">
          <div class="shelf__circles"></div>
        </div>
      </div>
      <div class="besom">
        <div class="rope"></div>
      </div>
      <div class="moth-rope"></div>
      <div class="moth">
        <div class="moth__wings"></div>
      </div>
      <div class="ladder"></div>
      <div class="wreath"></div>
      <div class="hook">
        <div class="dried-flower">
          <div class="dried-flower__petals"></div>
        </div>
      </div>
      <div class="chair"></div>
      <div class="alchemist">
        <div class="alchemist__coat-1"></div>
        <div class="alchemist__leg-r"></div>
        <div class="alchemist__leg-l"></div>
        <div class="alchemist__body"></div>
        <div class="alchemist__arm-r"></div>
        <div class="alchemist__arm-l"></div>
        <div class="alchemist__coat-2"></div>
        <div class="alchemist__coat-3"></div>
        <div class="alchemist__hat">
          <div class="alchemist__cat-tail"></div>
          <div class="alchemist__hat-1"></div>
          <div class="alchemist__hat-2"></div>
          <div class="alchemist__hat-3"></div>
          <div class="alchemist__hat-4"></div>
          <div class="alchemist__cat">
            <div class="alchemist__cat-face"></div>
          </div>
          <div class="alchemist__hat-5"></div>
          <div class="alchemist__hat-6"></div>
          <div class="alchemist__hat-7"></div>
          <div class="alchemist__forelock"></div>
        </div>
        <div class="alchemist__head">
          <div class="alchemist__ear-r"></div>
          <div class="alchemist__ear-l"></div>
          <div class="alchemist__beard"></div>
          <div class="alchemist__mustache"></div>
          <div class="alchemist__eye-r"></div>
          <div class="alchemist__eye-l"></div>
        </div>
      </div>
      <div class="pedal">
        <div>
          <div></div>
        </div>
      </div>
      <div class="table">
        <div class="snail">
          <div class="snail__tail">
            <div></div>
          </div>
          <div class="snail__body">
            <div class="snail__head"></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="piggy">
          <div class="piggy__front-legs"></div>
          <div class="piggy__back-legs"></div>
          <div class="piggy__hair"></div>
          <div class="piggy__body"></div>
          <div class="piggy__ear-right"></div>
          <div class="piggy__ear-left"></div>
          <div class="piggy__tail"></div>
          <div class="piggy__eyes"></div>
          <div class="piggy__mouth"></div>
        </div>
        <div class="wasp">
          <div class="wasp__legs">
            <div></div>
          </div>
          <div class="wasp__body">
            <div></div>
          </div>
          <div class="wasp__wing"></div>
        </div>
        <div class="bottle-1">
          <div></div>
          <div></div>
        </div>
        <div class="bottle-2">
          <div>
            <div class="bottle-2__water"></div>
            <div class="bottle-2__dude-1"></div>
            <div class="bottle-2__dude-2"></div>
            <div class="bottle-2__dude-3"></div>
          </div>
        </div>
        <div class="bottle-3">
          <div>
            <div class="bottle-3__dude-1"></div>
            <div class="bottle-3__dude-2"></div>
            <div class="bottle-3__dude-3"></div>
          </div>
          <div class="dust-1"></div>
          <div class="dust-2"></div>
          <div class="dust-3"></div>
          <div class="dust-4"></div>
          <div class="dust-5"></div>
          <div class="dust-6"></div>
          <div class="dust-7"></div>
          <div class="dust-8"></div>
          <div class="dust-9"></div>
          <div class="dust-10"></div>
        </div>
        <div class="mushroom-3">
          <div></div>
        </div>
        <div class="mushroom-4"></div>
        <div class="plants">
          <div></div>
        </div>
        <div class="plants-2">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="nut"></div>
        <div class="fire">
          <div class="fire__item1"></div>
          <div class="fire__item2"></div>
          <div class="fire__item3"></div>
          <div class="fire__item4"></div>
          <div class="fire__item5"></div>
          <div class="fire__item6"></div>
          <div class="fire__item7"></div>
          <div class="fire__item8"></div>
          <div class="fire__item9"></div>
          <div class="fire__item10"></div>
        </div>
        <div class="skulp">
          <div></div>
          <div></div>
        </div>
        <div class="soul-1">
          <div class="soul-1__part1"></div>
          <div class="soul-1__part2"></div>
          <div class="soul-1__part3"></div>
          <div class="soul-1__part4"></div>
          <div class="soul-1__part5"></div>
          <div class="soul-1__part6"></div>
          <div class="soul-1__part7"></div>
          <div class="soul-1__part8"></div>
          <div class="soul-1__part9"></div>
          <div class="soul-1__part10"></div>
          <div class="soul-1__part11"></div>
          <div class="soul-1__part12"></div>
          <div class="soul-1__part13"></div>
          <div class="soul-1__part14"></div>
          <div class="soul-1__part15"></div>
          <div class="soul-1__part16"></div>
        </div>
        <div class="soul-2">
          <div class="soul-2__part1"></div>
          <div class="soul-2__part2"></div>
          <div class="soul-2__part3"></div>
          <div class="soul-2__part4"></div>
          <div class="soul-2__part5"></div>
          <div class="soul-2__part6"></div>
          <div class="soul-2__part7"></div>
          <div class="soul-2__part8"></div>
          <div class="soul-2__part9"></div>
          <div class="soul-2__part10"></div>
          <div class="soul-2__part11"></div>
          <div class="soul-2__part12"></div>
          <div class="soul-2__part13"></div>
          <div class="soul-2__part14"></div>
          <div class="soul-2__part15"></div>
          <div class="soul-2__part16"></div>
        </div>
        <div class="soul-3">
          <div class="soul-3__part1"></div>
          <div class="soul-3__part2"></div>
          <div class="soul-3__part3"></div>
          <div class="soul-3__part4"></div>
          <div class="soul-3__part5"></div>
          <div class="soul-3__part6"></div>
          <div class="soul-3__part7"></div>
          <div class="soul-3__part8"></div>
          <div class="soul-3__part9"></div>
          <div class="soul-3__part10"></div>
          <div class="soul-3__part11"></div>
          <div class="soul-3__part12"></div>
          <div class="soul-3__part13"></div>
          <div class="soul-3__part14"></div>
          <div class="soul-3__part15"></div>
          <div class="soul-3__part16"></div>
        </div>
        <div class="pot">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div class="pot__drop"></div>
          <div class="pot__bubble-1"></div>
          <div class="pot__bubble-2"></div>
          <div class="pot__bubble-3"></div>
        </div>
        <div class="bottle-4">
          <div class="bottle-4__dude">
            <div class="bottle-4__dude-head">
              <div class="bottle-4__dude-hair-1"></div>
              <div class="bottle-4__dude-hair-2"></div>
              <div class="bottle-4__dude-hair-3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="basket">
        <div></div>
      </div>
      <div class="cage"></div>
      <div class="staff">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-template>
