import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { ApiService } from 'src/app/services/api.service';
import { ThemeService } from 'src/app/theme';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqDataSource!: MatTableDataSource<any> | any;
  displayedColumns: any[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  isFAQChecked: boolean = false;
  viewFAQClicked: string = 'table';
  isGetFaqApiReady: boolean = false;

  description: string = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, ipsa?"

  isHovered: boolean = false;
  isHoveredCard: boolean = false;


  constructor(
    public apiService: ApiService,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.apiService.getFAQDocuments().subscribe((documents: any) => {
      documents.forEach((doc: any, docIndex: number) => {
        documents[docIndex]["LastModified"] = this.formatDateTime(documents[docIndex]["LastModified"]);
        // documents[docIndex]["Description"] = this.description;
        documents[docIndex]["Download"] = "download"; //Add icona download al DS
        delete documents[docIndex]["Size"];
      });

      this.displayedColumns = Object.keys(documents[0]);
      this.faqDataSource = new MatTableDataSource<any>(documents);
      this.isGetFaqApiReady = true;
    });
  }

  downloadFAQ(faq: any, event: any) {
    event.preventDefault()
    const faqName: string = faq.Key;

    this.apiService.downloadFAQDocument(environment.demo ? "" : faqName).subscribe((data: any) => {
      this.downloadPDF(data.body, environment.demo ? "TEST" : faqName);
    });
  }

  downloadPDF(decodedPDF: string, fileName: string) {
    const byteCharacters = atob(decodedPDF);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    link.click();
    URL.revokeObjectURL(link.href);
  }

  formatDateTime(inputDateString: any) {
    const inputDate = new Date(inputDateString);

    const formattedDate = `${inputDate.getDate()}/${inputDate.getMonth() + 1}/${inputDate.getFullYear()}`;
    const formattedTime = `${inputDate.getHours()}:${inputDate.getMinutes()}`;

    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  }

  handleChangeView() {
    if (this.viewFAQClicked === 'table') {
      this.isFAQChecked = false
    } else if (this.viewFAQClicked === 'grid') {
      this.isFAQChecked = true
    }
  }

}
