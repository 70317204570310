<div class="sk-folding-cube" *ngIf="!configurationDemo">
  <div class="sk-cube1 sk-cube"></div>
  <div class="sk-cube2 sk-cube"></div>
  <div class="sk-cube4 sk-cube"></div>
  <div class="sk-cube3 sk-cube"></div>
</div>

<div class="s2e-scia-position" *ngIf="configurationDemo">
  <div class="s2e-spinner-scia-div">
    <div class="s2e-spinner-title-full"></div>
    <div class="s2e-spinner-title-short"></div>
    <div class="s2e-spinner-scia"></div>

  </div>
</div>

<div class="loader" *ngIf="configurationDemo">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>

