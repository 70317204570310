import { environment } from 'src/environments/environment';
import { Subject, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Theme } from '../theme/symbols';

@Injectable({
  providedIn: 'root'
})
export class SharedVariablesService {

  statusReady: boolean = false

  frontend_config: any;

  userData: any;

  activeTheme!: Theme;
  changePasswordForceStatus: string = "";
  changePasswordStatus: string = "";

  onMaintenance?: any;

  last_update_date_flussi!: string;

  dettPolizzaTempCacheData: any = {};
  barChartCache: any;
  dashboardDataCache: any

  isBackOfficeActive: string = '';
  showElementsLoggedIn: boolean = false;

  guardReadySubject = new Subject<void>();
  guardReady$ = this.guardReadySubject.asObservable();

  constructor() { }

}
