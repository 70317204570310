import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ApiService } from './../services/api.service';
import { SharedVariablesService } from 'src/app/services/shared-variables.service';
import { Subject, lastValueFrom, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  onFirstInit: boolean = true;
  isUserLoggedIn: boolean = true;
  stopLogout: boolean = false;
  subjectStart = new Subject<void>();
  logoutSubs: Subscription;

  constructor(
    private router: Router,
    public _snackBar: MatSnackBar,
    private apiS: ApiService,
    private notifyS: NotificationService,
    private dbService: NgxIndexedDBService,
    private sharedVariables: SharedVariablesService
  ) {
    this.logoutSubs = this.notifyS.subjectLogout.asObservable().subscribe(() => {
      this.stopLogout = true;
      this.logout(true);
    })
  }

  async logout(fromerrInterceptor?: boolean) {
    this.clearIndexedDb();
    this.sharedVariables.showElementsLoggedIn = false;

    if (this.router.url !== "/login" && (!fromerrInterceptor)) {
      await lastValueFrom(this.apiS.getLogout()).then(
        () => {
          let poolData = {
            UserPoolId: environment.variables.cognitoUserPoolId,
            ClientId: environment.variables.cognitoAppClientId
          };
          let userPool = new CognitoUserPool(poolData);
          let cognitoUser = userPool.getCurrentUser();
          cognitoUser?.signOut();
          this.isUserLoggedIn = false;
          this.logoutRedirect();
        },
        (error: any) => {
          if (error.status == 401) {
            let poolData = {
              UserPoolId: environment.variables.cognitoUserPoolId,
              ClientId: environment.variables.cognitoAppClientId
            };
            let userPool = new CognitoUserPool(poolData);
            let cognitoUser = userPool.getCurrentUser();
            cognitoUser?.signOut();
            this.isUserLoggedIn = false;
            this.logoutRedirect();
          }
        }
      )
    } else if (fromerrInterceptor) {
      let poolData = {
        UserPoolId: environment.variables.cognitoUserPoolId,
        ClientId: environment.variables.cognitoAppClientId
      };
      let userPool = new CognitoUserPool(poolData);
      let cognitoUser = userPool.getCurrentUser();
      cognitoUser?.signOut();
      this.isUserLoggedIn = false;
      this.logoutRedirect();
    }

  }

  clearIndexedDb() {
    sessionStorage.clear();
    this.dbService.clear('dett_polizza').subscribe(() => {});
    this.dbService.clear('dashboard').subscribe(() => {});

    this.sharedVariables.dashboardDataCache = undefined;
    this.sharedVariables.barChartCache = undefined;
    this.sharedVariables.dettPolizzaTempCacheData = {};
  }

  getCognitoUser(){
    let poolData = {
      UserPoolId: environment.variables.cognitoUserPoolId,
      ClientId: environment.variables.cognitoAppClientId
    };
    let userPool = new CognitoUserPool(poolData);
    let cognitoUser = userPool.getCurrentUser();
    return cognitoUser;
  }

  setLocalUsername(user: string){
    window.localStorage.setItem("user", user);
  }

  getLocalUsername(){
    return window.localStorage.getItem("user");
  }

  logoutRedirect(){
    let isLoginCognito = window.localStorage.getItem("isCognitoLogin");
    if (isLoginCognito && isLoginCognito === "true"){
      console.log("logout cognito");
      this.router.navigate(["/login"]);
    } else if (isLoginCognito && isLoginCognito === "false") {
      //TODO: sostituire con redirect a pagina iniziale SSO
      console.log("logout sso");
      this.router.navigate(["/logout"]);
    } else {
      console.log("first landing");
      this.router.navigate(["/login"]);
    }
  }
}