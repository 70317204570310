import { Theme } from './symbols';

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--primary': '#237600',
    '--secondary': '#e69f36',
    '--background-color': '#141414',
    '--text-color': 'white',
    '--icon-color-on-bg': 'white',
    '--text-dati-utente': '#ffffff',
    '--card-color': '#3d3d40',
    '--sidenav-color': '#3d3d40',
    '--background-tooltip': 'rgb(0 0 0 / 54%)',
    '--overlay-bg-color': '#46464a',
    '--row-hover': '#26262c66'
  }
};
