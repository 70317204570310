import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DemoFakeBeService {

  LSvarName: string = "listaPreferiti"

  constructor() { }

  getListaPreferiti() {
    return localStorage.getItem(this.LSvarName) ? JSON.parse(localStorage.getItem(this.LSvarName)!) : [];
  }

  postListaPreferiti(pol: any, desc: any) {
    pol.forEach((el: any) => {
      el["numPolizza"] = el.numpolizza;
      delete el.numpolizza;
    });

    let pref = this.getListaPreferiti();
    let id = pref.length;
    let newPol = {
      nomeGruppo: 'PREFERITI',
      descrizione: desc,
      polizze_preferite: pol,
      id: id
    };
    pref.push(newPol);
    localStorage.setItem(this.LSvarName, JSON.stringify(pref));

    return newPol;
  }

  editListaPreferiti(modPol: any) {
    let pref = this.getListaPreferiti();
    let index = pref.findIndex((obj: any) => {
      return obj.id === modPol.id;
    });
    pref.splice(index, 1)
    pref.push(modPol);
    this.deletepolizzaPreferita(index);
    localStorage.setItem(this.LSvarName,JSON.stringify(pref));
  }

  deletepolizzaPreferita(id: string|number) {
    let pref = this.getListaPreferiti();
    pref.splice(Number(id), 1)
    localStorage.setItem(this.LSvarName,JSON.stringify(pref));
  }

}
