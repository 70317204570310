<div class="component">
    <div style="display: flex; flex-direction: row; justify-content: center;">
        <mat-icon class="icon">
            watch_later
        </mat-icon>
    </div>
    <div class="text">
        <h1>Sessione conclusa.</h1>
        <h3>Si prega di chiudere la scheda corrente.</h3>
    </div>
</div>