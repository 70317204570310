<div fxLayout="column" fxLayoutAlign="start start">

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-icon color="primary">report_problem</mat-icon>
    <h1 style="margin-bottom: 0px;" class="api-error-color">Informazioni Errore Servizio</h1>
    <button mat-icon-button>
      <mat-icon class="close-sheet" color="primary" (click)="close()" >close</mat-icon>
    </button>
  </div>

  <div fxLayout="row" style="margin-top: 1em;" class="api-error-color">
    - Status: <strong style="margin-left: 0.5em;" class="api-error-color">{{data.error.status}}</strong>
  </div>

  <div fxLayout="row" style="margin-top: 1em;" class="api-error-color">
    - Status Text: <strong style="margin-left: 0.5em;" class="api-error-color">{{data.error.statusText}}</strong>
  </div>

  <div fxLayout="row" style="margin-top: 1em;" class="api-error-color">
    - Url: <strong style="margin-left: 0.5em;" class="api-error-color">{{data.error.url.split(".it")[0] + ".it"}}</strong>
  </div>

  <div fxLayout="row" style="margin-top: 1em;" class="api-error-color">
    - EndPoint: <strong style="margin-left: 0.5em;" class="api-error-color">{{data.error.url.split(".it")[1].split("?")[0]}}</strong>
  </div>

  <div *ngIf="data.error.url.split('.it')[1].split('?')[1]" fxLayout="row" style="margin-top: 1em;" class="api-error-color">
    - Parametri Query: <strong style="margin-left: 0.5em;" class="api-error-color">{{data.error.url.split(".it")[1].split("?")[1]}}</strong>
  </div>

</div>
