import { BottomsheetService } from './services/bottomsheet.service';
import { DemoFakeBeService } from './services/demo-fake-be.service';
import { Title } from '@angular/platform-browser';
import { ThemeService } from './theme/theme.service';
import { SnackBarTokenDeadlineComponent } from './components/snackbars/snack-bar-token-deadline/snack-bar-token-deadline.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedVariablesService } from './services/shared-variables.service';
import { ListaPreferiti } from './models/lista-preferiti.model';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LibraryController } from './models/library-controller.model';
import { Subscription, timer, Observable, map, switchMap, forkJoin, Subject, catchError, retry, lastValueFrom, of, throwError } from 'rxjs';
import { filter, mergeMap, retryWhen, shareReplay, startWith, tap } from "rxjs/operators";
import { ApiService } from './services/api.service';
import { NotificationService } from './services/notification.service';
import { Polizza } from './models/polizza.model';
import { SidenavService } from './services/sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingsDialogComponent } from './components/dialogs/settings-dialog/settings-dialog.component';
import { SettingsService } from './services/settings.service';
import { DatiUtenteComponent } from './components/dialogs/dati-utente/dati-utente.component';
import { ConfirmDeleteComponent } from './components/dialogs/confirm-delete/confirm-delete.component';
import { EditPolizzePreferiteComponent } from './components/dialogs/edit-polizze-preferite/edit-polizze-preferite.component';
import { DeletePreferitiComponent } from './components/dialogs/delete-preferiti/delete-preferiti.component';
import { MatAccordion } from '@angular/material/expansion';
import { environment } from 'src/environments/environment';
import { selectAll } from 'd3';
import { AuthService } from './auth/auth.service';
import { DatePipe } from '@angular/common';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as CryptoJS from 'crypto-js';
import { HelpDialogComponent } from './components/dialogs/help-dialog/help-dialog.component';

var yaml = require('js-yaml');


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  pathLogo: any = environment.pathLogo
  pathLogoLight: any = environment.pathLogoLight

  isDemo: any = environment.demo;
  pathLogoMini: any = environment.pathLogoMini

  isCookieEnabled: any;

  @ViewChild('accordion') sidenavExpPanel!: MatAccordion;

  MSToken!: string;

  title = 's2e-intesa-pi';

  firstName!: string;
  lastName!: string;
  role!: string;
  email!: string;
  operationalUnit!: string;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  isMenuOpen: boolean = true;
  library_investimenti_available!: boolean;

  contentMargin: number = 240;

  historyNotEmpty!: boolean;

  subNotifyServ!: Subscription;

  iconExpand: boolean = false;

  chipList: LibraryController[] = [];

  portafoglioHistory: any = [];

  nPolizza: string = "";

  modPolizzeAggregate: boolean = false;
  arrIdentificatori!: any[];

  polizze_preferite: any = [];
  colorStars: any = [];
  starIcon: string = "star_outline";

  subChangePreferiti!: Subscription;

  counter$!: Observable<number>;

  frontedYaml: any;

  deletePreferitiIsAvailable: boolean = true;
  addPreferitiIsAvailable: boolean = true;
  settingsReady: boolean = false;

  subscriptionStartInit!: Subscription;
  subscriptionStartInitBack!: Subscription;
  homeSubsBack!: Subscription;

  constructor(
    public settingsServ: SettingsService,
    public settingsDialog: MatDialog,
    public router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    private notifyService: NotificationService,
    public sidenavServ: SidenavService,
    private apiS: ApiService,
    public route: ActivatedRoute,
    public sharedVariables: SharedVariablesService,
    public _snackBar: MatSnackBar,
    private themeService: ThemeService,
    private titleService: Title,
    private datePipe: DatePipe,
    private fakeBeDemo: DemoFakeBeService,
    private authS: AuthService,
    private dbService: NgxIndexedDBService,
    private bottomsheetS: BottomsheetService,
  ) {
  // Controllo se i cookie del browser sono abilitati
    this.isCookieEnabled = navigator.cookieEnabled;

    let that = this;
    window.addEventListener("unload", function (event) {
      if (!document.referrer) {
        that.authS.clearIndexedDb();
      }
    });

    this.router.events.pipe(
      filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if (event.urlAfterRedirects === "/dashboard" && this.sharedVariables.dashboardDataCache) {
        this.subscriptionStartInitBack = this.notifyService.onSubBackInitHome().subscribe({
          next: () => this.notifyService.sendInitHome(),
          complete: () => this.subscriptionStartInitBack.unsubscribe()
        })
      } else if (event.urlAfterRedirects === "/back-office") {
        this.sharedVariables.isBackOfficeActive = 'active';
      }
    })

    if (environment.demo) {
      let favIcon: HTMLLinkElement = document.querySelector('#appIcon')!;
      favIcon.href = favIcon.href.split("/assets/logo/")[0] + "/assets/logo/" + "s2e-abbraccio.ico";
      this.titleService.setTitle(environment.title);
    }

    this.subNotifyServ = this.notifyService.onOk().subscribe((notification: {polizza: Polizza[], modAggregata: boolean, arrIdentificatori: any[]}) => {
      this.modPolizzeAggregate = notification.modAggregata
      this.arrIdentificatori = notification.arrIdentificatori
      this.setPortafoglioHistory(notification)
    })
  }

  async ngOnInit(): Promise<void>{
    if (this.sharedVariables.onMaintenance == undefined && !environment.demo) {
      this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus();
      this.sharedVariables.guardReadySubject.next();
    }
    if (!this.sharedVariables.dashboardDataCache && !this.isDemo) {
      await lastValueFrom(this.dbService.getAll("dashboard")).then(async (d: any) => {
        if (d.length > 0 && sessionStorage.getItem("dbKeyStats")) {
          // Decrypt
          var bytes  = CryptoJS.AES.decrypt(d[0].data, sessionStorage.getItem("dbKeyStats")!);
          var parsed = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          if (parsed.update_date && parsed.update_date === this.sharedVariables.last_update_date_flussi) {
            this.sharedVariables.dashboardDataCache = parsed.dashboard;
            this.sharedVariables.barChartCache = parsed.barChart;

          } else {
            await lastValueFrom(this.dbService.clear('dashboard')).then(() => {});
          }
        }
      })
    }

    try {
      this.toExecOnInit()
    } finally {
      addEventListener('transitionstart', (event: TransitionEvent) => {
        if (event.propertyName === "opacity" && (event.target! as any).parentNode.className === "cdk-overlay-container") {
          for (const key in this.sharedVariables.activeTheme.properties) {
            //d3 style
            selectAll(".cdk-overlay-container").style(key, this.sharedVariables.activeTheme.properties[key]);

            //normal style
            // document.querySelectorAll("[class='cdk-overlay-container']").forEach((el: any) => {
            //   el.style.setProperty(key, this.sharedVariables.activeTheme.properties[key]);
            // });
          }
        }
      });

      this.subChangePreferiti = this.sidenavServ.receiveChangePreferitiFromDettaglio().subscribe(() => {
        this.clickPreferiti(0, true);
      })

      this.http.get("./assets/frontend.config.yaml", {responseType: 'text'}).subscribe((_yaml: any) => {
        const frontendJSONConfig = _yaml;
        this.frontedYaml = yaml.load(frontendJSONConfig)
        this.sharedVariables.frontend_config = this.frontedYaml;

        this.frontedYaml.libraries_config.pages_libraries.forEach((element: LibraryController) => {

          if (element.library === "Investimenti") {
            this.library_investimenti_available = element.active;
          }

          if (element.active) {
            this.settingsServ.availableLibraries.push(element);
          }

        })

        this.frontedYaml.libraries_config.dettaglioPolizza_libraries.forEach((element: LibraryController) => {

          if (element.active) {
            this.settingsServ.availableLibraries.push(element);
            this.chipList.push(element);
          }

        })

        this.settingsServ.setting_config = this.frontedYaml.settings_config;

        this.settingsReady = true;
      })

      this.subscriptionStartInit = this.authS.subjectStart.asObservable().subscribe(() => {
        this.toExecOnInit();
      })
    }
  }

  toExecOnInit() {
    if (this.isDemo) {
      this.sharedVariables.userData = {
        firstName: "S2E_nome",
        lastName: "S2E_cognome",
        email: "S2E_email",
        role: "S2E_ruolo",
        operationalUnit: "S2E_punto_op"
      }
      localStorage.setItem('firstName', this.sharedVariables.userData.firstName)
      localStorage.setItem('lastName', this.sharedVariables.userData.lastName)
      localStorage.setItem('email', this.sharedVariables.userData.email)
      localStorage.setItem('role', this.sharedVariables.userData.role)
      localStorage.setItem('operationalUnit', this.sharedVariables.userData.operationalUnit)

      this.polizze_preferite = [...this.fakeBeDemo.getListaPreferiti()];

      if (!localStorage.getItem('portafoglioHistory')) {
        localStorage.setItem('portafoglioHistory', JSON.stringify([]))
        this.historyNotEmpty = false;
      } else {
        this.portafoglioHistory = JSON.parse(localStorage.getItem("portafoglioHistory")!);
        if (this.portafoglioHistory.length > 0) {
          for (let index = 0; index < this.portafoglioHistory.length; index++) {
            let _history = this.portafoglioHistory[index];
            let preferredPolizza = false,
            id = "";
            for (let i = 0; i < this.polizze_preferite.length; i++) {
              const el: ListaPreferiti = this.polizze_preferite[i];
              if (_history.polizza.polizza.length !== el.polizze_preferite.length) continue;
              else {
                if (el.polizze_preferite.length > 1) {
                  let result = true;
                  id = el.id;
                  for (let j = 0; j < el.polizze_preferite.length; j++) {
                    const currentPref = el.polizze_preferite[j];
                    if (_history.polizza.polizza.filter((v: any) => v.numPolizza === currentPref.numPolizza && currentPref.compagnia === currentPref.compagnia).length === 0) {
                      result = false;
                      id = "";
                      break;
                    }
                  }
                  preferredPolizza = result;
                } else if (el.polizze_preferite.length === 1 && _history.polizza.polizza[0].numPolizza === el.polizze_preferite[0].numPolizza && _history.polizza.polizza[0].compagnia === el.polizze_preferite[0].compagnia){
                  preferredPolizza = true;
                  id = el.id;
                  break;
                } else continue;
              }
            }
            _history.id_lista_pref = id;
            _history.preferita = preferredPolizza;
          }

          this.historyNotEmpty = true;
        } else {
          this.historyNotEmpty = false;
        }
      }

      this.apiS.getDashboardData().subscribe({
        next: this.nextForkjoin,
        complete: () => {
          this.homeSubsBack = this.notifyService.onSubBackInitHome().subscribe({
            next: () => {
              this.notifyService.sendInitHome();
            },
            complete: () => this.homeSubsBack.unsubscribe()
          })
        }
      })
    } else {
      this.getUserDataFromQueryParams();
      if (!this.sharedVariables.dashboardDataCache) {
        forkJoin({
          preferiti: this.apiS.getListaPreferiti(),
          statistiche: this.apiS.getDashboardData()
        }).subscribe({
          next: this.nextForkjoin,
          error: (async error => {
            if (error.status == 401) {
              await lastValueFrom(this.apiS.getRefreshToken()).then(
                (resp) => {
                  forkJoin({
                    preferiti: this.apiS.getListaPreferiti(),
                    statistiche: this.apiS.getDashboardData()
                  }).subscribe({
                    next: this.nextForkjoin,
                    error: (err) => {
                      this.bottomsheetS.openBottomSheet(err, err)
                      this.sharedVariables.statusReady = true;
                      this.sharedVariables.showElementsLoggedIn = false;
                    },
                    complete: () => {
                      this.notifyService.sendInitHome();
                    }
                  })
                },
                (err) => {
                  this.authS.stopLogout = true;
                  this.authS.logout(true);
                  this.sharedVariables.statusReady = true;
                  this.sharedVariables.showElementsLoggedIn = false;
                }
              )
            } else if (String(error.status).slice(0, 2) === "50") {
              this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
              if (this.sharedVariables.onMaintenance) {
                this.sharedVariables.statusReady = true;
                this.sharedVariables.showElementsLoggedIn = false;
                this.router.navigate(["/manutenzione"]);
              } else {
                this.sharedVariables.statusReady = true;
                this.sharedVariables.showElementsLoggedIn = false;
                this.bottomsheetS.openBottomSheet(error, error);
              }
            } else {
              this.sharedVariables.statusReady = true;
              this.sharedVariables.showElementsLoggedIn = false;
              this.bottomsheetS.openBottomSheet(error, error);
            }
          }),
          complete: () => {
            this.notifyService.sendInitHome();
          }
        })
      } else {
        this.apiS.getListaPreferiti().subscribe({
          next: this.nextSingol,
          error: (async error => {
            if (error.status == 401) {
              await lastValueFrom(this.apiS.getRefreshToken()).then(
                (resp) => {
                  this.apiS.getListaPreferiti().subscribe({
                    next: this.nextSingol,
                    error: (error) => {
                      this.authS.stopLogout = true;
                      this.authS.logout(true);
                      this.sharedVariables.statusReady = true;
                      this.sharedVariables.showElementsLoggedIn = false;
                    },
                    complete: () => {
                      this.notifyService.sendInitHome()
                    }
                  })
                },
                (err) => {
                  this.authS.stopLogout = true;
                  this.authS.logout(true);
                  this.sharedVariables.statusReady = true;
                  this.sharedVariables.showElementsLoggedIn = false;
                }
              )
            } else if (String(error.status).slice(0, 2) === "50") {
              this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
              if (this.sharedVariables.onMaintenance) {
                this.sharedVariables.statusReady = true;
                this.sharedVariables.showElementsLoggedIn = false;
                this.router.navigate(["/manutenzione"]);
              } else {
                this.sharedVariables.statusReady = true;
                this.sharedVariables.showElementsLoggedIn = false;
                this.bottomsheetS.openBottomSheet(error, error);
              }
            } else {
              this.sharedVariables.statusReady = true;
              this.sharedVariables.showElementsLoggedIn = false;
              this.bottomsheetS.openBottomSheet(error, error);
            }
          }),
          complete: () => {
            this.notifyService.sendInitHome()
          }
        })
      }
    }
  }

  nextForkjoin = async (resp: any) => {
    this.sharedVariables.userData = {
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      email: localStorage.getItem('email'),
      role: localStorage.getItem('role'),
      operationalUnit: localStorage.getItem('operationalUnit'),
    }

    this.sharedVariables.dashboardDataCache = resp.statistiche.dashboard;
    this.sharedVariables.barChartCache = resp.statistiche.tipologia;

    var key;
    // Encrypt
    if (sessionStorage.getItem("dbKeyStats")) {
      key = sessionStorage.getItem("dbKeyStats");
    } else {
      key = Array.from(window.crypto.getRandomValues(new Uint8Array((40))), (dec: any) => dec.toString(16).padStart(2, "0")).join('');
      sessionStorage.setItem("dbKeyStats", key);
    }
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify({dashboard: resp.statistiche.dashboard, barChart: resp.statistiche.tipologia, update_date: this.sharedVariables.last_update_date_flussi}), key!).toString();

    await lastValueFrom(this.dbService.update('dashboard', {
      id: 0,
      data: ciphertext
    })).then(() => {
    });

    if (!this.isDemo) {
      this.polizze_preferite = [...resp.preferiti];

      if (!localStorage.getItem('portafoglioHistory')) {
        localStorage.setItem('portafoglioHistory', JSON.stringify([]))
        this.historyNotEmpty = false;
      } else {
        this.portafoglioHistory = JSON.parse(localStorage.getItem("portafoglioHistory")!);
        if (this.portafoglioHistory.length > 0) {
          for (let index = 0; index < this.portafoglioHistory.length; index++) {
            let _history = this.portafoglioHistory[index];
            let preferredPolizza = false,
            id = "";
            for (let i = 0; i < this.polizze_preferite.length; i++) {
              const el: ListaPreferiti = this.polizze_preferite[i];
              if (_history.polizza.polizza.length !== el.polizze_preferite.length) continue;
              else {
                if (el.polizze_preferite.length > 1) {
                  let result = true;
                  id = el.id;
                  for (let j = 0; j < el.polizze_preferite.length; j++) {
                    const currentPref = el.polizze_preferite[j];
                    if (_history.polizza.polizza.filter((v: any) => v.numPolizza === currentPref.numPolizza && currentPref.compagnia === currentPref.compagnia).length === 0) {
                      result = false;
                      id = "";
                      break;
                    }
                  }
                  preferredPolizza = result;
                } else if (el.polizze_preferite.length === 1 && _history.polizza.polizza[0].numPolizza === el.polizze_preferite[0].numPolizza && _history.polizza.polizza[0].compagnia === el.polizze_preferite[0].compagnia){
                  preferredPolizza = true;
                  id = el.id;
                  break;
                } else continue;
              }
            }
            _history.id_lista_pref = id;
            _history.preferita = preferredPolizza;
          }

          this.historyNotEmpty = true;
        } else {
          this.historyNotEmpty = false;
        }
      }
    }
    this.sharedVariables.statusReady = true;
    this.sharedVariables.showElementsLoggedIn = true
  }

  nextSingol = async (resp: any) => {
    var cognitoUser = this.authS.getCognitoUser();
    this.sharedVariables.userData = {
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      email: localStorage.getItem('email'),
      role: localStorage.getItem('role'),
      operationalUnit: localStorage.getItem('operationalUnit'),
    }

    this.polizze_preferite = [...resp];

    if (!localStorage.getItem('portafoglioHistory')) {
      localStorage.setItem('portafoglioHistory', JSON.stringify([]))
      this.historyNotEmpty = false;
    } else {
      this.portafoglioHistory = JSON.parse(localStorage.getItem("portafoglioHistory")!);
      if (this.portafoglioHistory.length > 0) {
        for (let index = 0; index < this.portafoglioHistory.length; index++) {
          let _history = this.portafoglioHistory[index];
          let preferredPolizza = false,
          id = "";
          for (let i = 0; i < this.polizze_preferite.length; i++) {
            const el: ListaPreferiti = this.polizze_preferite[i];
            if (_history.polizza.polizza.length !== el.polizze_preferite.length) continue;
            else {
              if (el.polizze_preferite.length > 1) {
                let result = true;
                id = el.id;
                for (let j = 0; j < el.polizze_preferite.length; j++) {
                  const currentPref = el.polizze_preferite[j];
                  if (_history.polizza.polizza.filter((v: any) => v.numPolizza === currentPref.numPolizza && currentPref.compagnia === currentPref.compagnia).length === 0) {
                    result = false;
                    id = "";
                    break;
                  }
                }
                preferredPolizza = result;
              } else if (el.polizze_preferite.length === 1 && _history.polizza.polizza[0].numPolizza === el.polizze_preferite[0].numPolizza && _history.polizza.polizza[0].compagnia === el.polizze_preferite[0].compagnia){
                preferredPolizza = true;
                id = el.id;
                break;
              } else continue;
            }
          }
          _history.id_lista_pref = id;
          _history.preferita = preferredPolizza;
        }

        this.historyNotEmpty = true;
      } else {
        this.historyNotEmpty = false;
      }
    }
    this.sharedVariables.statusReady = true;
    this.sharedVariables.showElementsLoggedIn = true
  }

  ngOnDestroy(): void {
    if (this.subNotifyServ) this.subNotifyServ.unsubscribe();
    if (this.subChangePreferiti) this.subChangePreferiti.unsubscribe();
    if (this.subscriptionStartInit) this.subscriptionStartInit.unsubscribe();
    if (this.authS.logoutSubs) this.authS.logoutSubs.unsubscribe();
  }

  changeTheme() {
    const active = this.themeService.getActiveTheme();
    if (active.name === 'light') {
      this.themeService.setTheme('dark');
    } else {
      this.themeService.setTheme('light');
    }

    for (const key in this.sharedVariables.activeTheme.properties) {
      //d3 style
      selectAll(".mat-menu-panel").style(key, this.sharedVariables.activeTheme.properties[key]);

      //normal style
      // document.querySelectorAll("[class='mat-menu-panel']").forEach((el: any) => {
      //   el.style.setProperty(key, this.sharedVariables.activeTheme.properties[key]);
      // });
      selectAll('#d3-tooltip').style(key, this.sharedVariables.activeTheme.properties[key]);
    }
  }

  goToChangePassword(){
    this.sharedVariables.changePasswordStatus = "active";
    this.router.navigate(["changepassword"]);
  }

  checkRouteDettaglioPolizza(route: any){
    let splittedRoute = route.split('/')
    if (splittedRoute[1] === "dettaglio" && this.notifyService.dettaglioPolizzaSuscribeReady){
      return true;
    } else return false;
  }

  scroll(target: any){
    let element = document.getElementById(target);
    element!.scrollIntoView({ behavior: 'smooth'})
    setTimeout(() => {
      this.sidenavServ.sendChipEvent();
    }, 800);
  }

  getRes() {
    return document.body.clientWidth;
  }

  getHeight() {
    return document.body.clientHeight;
  }

  ToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;

    if(!this.isMenuOpen) {
      this.contentMargin = 60;
      this.sidenavServ.sendEvent(90);
    } else {
      this.sidenavServ.sendEvent(175);
      this.contentMargin = 240;
    }

  }

  confirmDeleteContact(event:any, i: number) {
    let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      disableClose: true,
      data: {
        history: this.portafoglioHistory,
        indexContact: i,
      }
    });
    event.stopPropagation();
    dialogRef.afterClosed().subscribe( data => {
      if (data) {
        this.portafoglioHistory = data.history
        localStorage.setItem('portafoglioHistory', JSON.stringify(this.portafoglioHistory))
      }
      if (this.portafoglioHistory.length === 0){
        this.historyNotEmpty = false
      }
    })
  }

  resetDashboardSubcriptionState(compagnia: any, polizza: any){
    let url = window.location.origin + '/' + 'dettaglio' + '/' + compagnia + '/' + polizza;
    if (url !== window.location.href) {
      this.notifyService.dettaglioPolizzaSuscribeReady = false;
    }
  }

  setPortafoglioHistory(_polizza: {polizza: Polizza[], modAggregata: boolean, arrIdentificatori: any[]}){
    let _nome, _cognome;

    if (_polizza.polizza.length === 1) {
      _nome = _polizza.polizza[0].contraente.nome;
      _cognome = _polizza.polizza[0].contraente.cognome;
    } else {
      _nome = "";
      _cognome = "";
    }

    let arrayNumPolizza: string[] = [];
    let arrayCompagnia: string[] = [];

    _polizza.polizza.forEach(element => {
      arrayNumPolizza.push(element.numPolizza);
      arrayCompagnia.push(element.compagnia);
    });

    this.nPolizza = arrayNumPolizza.join();
    let _compagnia = arrayCompagnia.join();

    let preferredPolizza = false,
    id = "";
    for (let i = 0; i < this.polizze_preferite.length; i++) {
      const el: ListaPreferiti = this.polizze_preferite[i];
      if (_polizza.polizza.length !== el.polizze_preferite.length) continue;
      else {
        if (el.polizze_preferite.length > 1) {
          let result = true;
          id = el.id;
          for (let j = 0; j < el.polizze_preferite.length; j++) {
            const currentPref = el.polizze_preferite[j];
            if (_polizza.polizza.filter((v: any) => v.numPolizza === currentPref.numPolizza && currentPref.compagnia === currentPref.compagnia).length === 0) {
              result = false;
              id = "";
              break;
            }
          }
          preferredPolizza = result;
        } else if (el.polizze_preferite.length === 1 && this.nPolizza === el.polizze_preferite[0].numPolizza){
          preferredPolizza = true;
          id = el.id;
          break;
        } else continue;
      }
    }

    let objPolizza = {
      polizza: _polizza,
      nPolizza: this.nPolizza,
      compagnia: _compagnia,
      nome: _nome,
      cognome: _cognome,
      preferita: preferredPolizza,
      id_lista_pref: id
    };

    let indexFound;
    this.portafoglioHistory.forEach((element: any, i: number) => {
      // cerco se il numero di polizza n è già presente fra i portafogli
      if ( element.nPolizza === this.nPolizza ) {
        indexFound=i;
        return;
      }
    });

    if (indexFound == undefined) {
      // se non è presente, elimino l'ultimo elemento dell'array se ha raggiunto lunghezza max - 1 (per poi aggiungere n in testa) MODIFICATO DA >= 3 A == 5
      if (this.portafoglioHistory.length === 5) {
        this.portafoglioHistory.pop();
      }
    } else {
      // se è già presente, lo elimino per poi inserirlo in testa all'array
      this.portafoglioHistory.splice(indexFound, 1);
    }
    // infine, lo aggiungo in prima posizione
    this.portafoglioHistory.unshift(objPolizza);

    localStorage.setItem('portafoglioHistory', JSON.stringify(this.portafoglioHistory))

    this.sidenavServ.current_polizza_is_preferita = preferredPolizza;

    this.historyNotEmpty = true;
    this.notifyService.dettaglioPolizzaSuscribeReady = true;

  }

  openSettings(){
    const dialogRef = this.settingsDialog.open(SettingsDialogComponent, {

      height: '50%',
      width: '50%'
    });
  }

  openHelpDialog() {
    const dialogRef = this.settingsDialog.open(HelpDialogComponent, {
      width: '400px'

    });
  }

  leftPad(nPol: string | number){
    return String(nPol).padStart(12, '0')
  }

  formatNumPolizza(nPol: string){
    return nPol.split(',').join(', ');
  }

  setAccountDatas(localStorageOutput: string) {
    if (localStorageOutput === 'nome') {
      return localStorage.getItem('firstName')
    }else if(localStorageOutput === 'cognome') {
      return localStorage.getItem('lastName')
    }else if(localStorageOutput === 'operationalUnit') {
      return localStorage.getItem('operationalUnit')
    }else {
      return localStorage.getItem('role')
    }
  }

  logoutExe() {
    this.authS.logout()
    this.sharedVariables.showElementsLoggedIn = false;
  }

  openDatiUtenteDialog() {
    let dialogRef = this.dialog.open(DatiUtenteComponent, {
      width: 'auto',
      disableClose: true
    })
  }

  navigateFromSidenav(compagnia: any, polizza: any, event: any) {
    if (event.type === 'auxclick') {
      this.router.navigate([]).then(result => {
        window.open(`dettaglio/${compagnia}/${polizza}`, '_blank');
      });
    }else {
      this.router.navigate(['dettaglio/', compagnia, polizza]);
    }
  }

  navigateFromPreferiti(polizze_pref: any[], event: MouseEvent) {
    let compagnia = polizze_pref[0].compagnia, nPolizza = polizze_pref[0].numPolizza ? polizze_pref[0].numPolizza : polizze_pref[0].numpolizza;
    if (polizze_pref.length > 1) {
      polizze_pref.forEach((el: any, i: number) => {
        if (i > 0) {
          compagnia = compagnia.concat(",", el.compagnia);
          nPolizza = nPolizza.concat(",", el.numPolizza ? el.numPolizza : el.numpolizza);
        }
      })
    }

    this.resetDashboardSubcriptionState(compagnia, nPolizza);
    this.navigateFromSidenav(compagnia, nPolizza, event);
  }

  clickPreferiti(i: any, fromDettaglio: boolean) {
    this.addPreferitiIsAvailable = false;
    this.deletePreferitiIsAvailable = false;
    this.portafoglioHistory[i].preferita = !this.portafoglioHistory[i].preferita;
    if(!fromDettaglio && i === 0) this.sidenavServ.sendChangePreferitiFromSidenav();
    if (this.portafoglioHistory[i].preferita) {
      let _polizzeBE: any = []
      let _polizzeFE: any = []
      this.portafoglioHistory[i].polizza.polizza.forEach((el: any) => {
        _polizzeBE.push({
          "compagnia": el.compagnia,
          "numpolizza": el.numPolizza
        })
        _polizzeFE.push({
          "compagnia": el.compagnia,
          "numPolizza": el.numPolizza
        })
      })

      if (this.isDemo) {
        var pol = this.fakeBeDemo.postListaPreferiti(_polizzeBE, this.portafoglioHistory[i].nPolizza)
        this.portafoglioHistory[i].id_lista_pref = pol.id;
        this.polizze_preferite.push({
          id: pol.id,
          nome_gruppo: pol.nomeGruppo,
          descrizione: pol.descrizione,
          polizze_preferite: _polizzeFE
        });
        localStorage.setItem('portafoglioHistory', JSON.stringify(this.portafoglioHistory))
        this.addPreferitiIsAvailable = true;
        this.deletePreferitiIsAvailable = true;
        this.sidenavServ.sendChangePreferitiDisableState()
      } else {
        this.apiS.postListaPreferiti(_polizzeBE, this.portafoglioHistory[i].nPolizza).subscribe({
          next: this.nextListaPre(_polizzeBE, i),
          error: async error => {
            if (error.status == 401) {
              await lastValueFrom(this.apiS.getRefreshToken()).then(
                (resp) => {
                  this.apiS.postListaPreferiti(_polizzeBE, this.portafoglioHistory[i].nPolizza).subscribe({
                    next: this.nextListaPre(_polizzeBE, i),
                    error: err => console.log(err)
                  })
                },
                (err) => {
                  this.portafoglioHistory[i].preferita = !this.portafoglioHistory[i].preferita;
                  this.addPreferitiIsAvailable = true;
                  this.deletePreferitiIsAvailable = true;
                  this.sidenavServ.sendChangePreferitiDisableState()
                  this.authS.stopLogout = true;
                  this.authS.logout(true);
                }
              )
            } else if (String(error.status).slice(0, 2) === "50") {
              this.sharedVariables.onMaintenance = await this.apiS.getMaintenanceStatus()
              if (this.sharedVariables.onMaintenance) {
                this.router.navigate(["/manutenzione"]);
              } else {
                this.bottomsheetS.openBottomSheet(error, error);
              }
            } else {
              this.bottomsheetS.openBottomSheet(error, error);
            }
          }
        })
      }

    } else {
      this.deletePolizzePreferite(this.portafoglioHistory[i].id_lista_pref, false)
    }
  }

  nextListaPre(_polizzeBE: any, i: number) {
    return (data: any) => {
      this.portafoglioHistory[i].id_lista_pref = data.id;
      this.polizze_preferite.push({
        id: data.id,
        nome_gruppo: data.nome_gruppo,
        descrizione: data.descrizione,
        polizze_preferite: _polizzeBE
      })
      localStorage.setItem('portafoglioHistory', JSON.stringify(this.portafoglioHistory))
      this.addPreferitiIsAvailable = true;
      this.deletePreferitiIsAvailable = true;
      this.sidenavServ.sendChangePreferitiDisableState()
    }
  }

  editPolizzePreferite(index: number,) {
    let dialogRef = this.dialog.open(EditPolizzePreferiteComponent, {
      data: {
        polizze_preferite: this.polizze_preferite[index]
      },
      disableClose: true
    })

    dialogRef.afterClosed().subscribe((new_desc: string) => {
      if (new_desc !== this.polizze_preferite[index].descrizione) {
        this.polizze_preferite[index].descrizione = new_desc;

        let polizze: any = []
        this.polizze_preferite[index].polizze_preferite.forEach((p: any) => {
          polizze.push({
            numpolizza: p.numPolizza ? p.numPolizza : p.numpolizza,
            compagnia: p.compagnia
          })
        })

        if (environment.demo) {
          let body = {
            descrizione: new_desc,
            id: this.polizze_preferite[index].id,
            nome_gruppo: this.polizze_preferite[index].nomeGruppo,
            polizze_preferite: polizze
          }

          this.fakeBeDemo.editListaPreferiti(body);
        } else {
          let body = {
            descrizione: new_desc,
            id: this.polizze_preferite[index].id,
            nome_gruppo: this.polizze_preferite[index].nomeGruppo,
            polizze: polizze
          }

          this.apiS.editListaPreferiti(body).subscribe(() => {});
        }
      }
    })
  }

  deletePolizzePreferite(id: any, inputFromPreferiti: boolean) {
    let index = this.portafoglioHistory.map((e: any) => e.id_lista_pref).indexOf(id);
    if (this.isDemo) {
      this.fakeBeDemo.deletepolizzaPreferita(id);
      this.polizze_preferite.splice(this.polizze_preferite.map((e: any) => e.id).indexOf(id), 1);
      if (inputFromPreferiti && index >= 0) {
        this.portafoglioHistory[index].id_lista_pref = "";
        this.portafoglioHistory[index].preferita = false;
        if (index === 0) this.sidenavServ.sendChangePreferitiFromSidenav();
      }

      localStorage.setItem('portafoglioHistory', JSON.stringify(this.portafoglioHistory))
      this.deletePreferitiIsAvailable = true;
      this.addPreferitiIsAvailable = true;
      this.sidenavServ.sendChangePreferitiDisableState()
    } else {
      this.apiS.deletepolizzaPreferita(id).subscribe({
        next: () => {
          this.polizze_preferite.splice(this.polizze_preferite.map((e: any) => e.id).indexOf(id), 1);
          if (inputFromPreferiti && index >= 0) {
            this.portafoglioHistory[index].id_lista_pref = "";
            this.portafoglioHistory[index].preferita = false;
            if (index === 0) this.sidenavServ.sendChangePreferitiFromSidenav();
          }

          localStorage.setItem('portafoglioHistory', JSON.stringify(this.portafoglioHistory))
        },
        complete: () => {
          this.deletePreferitiIsAvailable = true;
          this.addPreferitiIsAvailable = true;
          this.sidenavServ.sendChangePreferitiDisableState()
        },
        error: err => {
          console.log(err);
          this.portafoglioHistory[index].preferita = !this.portafoglioHistory[index].preferita;
          this.deletePreferitiIsAvailable = true;
          this.addPreferitiIsAvailable = true;
          this.sidenavServ.sendChangePreferitiDisableState()
        }
      })
    }
  }

  confirmDeletePolizzePreferite(id: any, fromPreferiti: boolean) {
    let dialogRef = this.dialog.open(DeletePreferitiComponent, {
      disableClose: true,
      data: {
        id: id,
        polizze_preferite: this.polizze_preferite
      }
    });
    dialogRef.afterClosed().subscribe( data => {
      if (data) {
        this.polizze_preferite = data.polizze_preferite
        let index = this.portafoglioHistory.map((e: any) => e.id_lista_pref).indexOf(id)
        if (fromPreferiti && index >= 0) {
          this.portafoglioHistory[index].id_lista_pref = "";
          this.portafoglioHistory[index].preferita = false;
          if (index === 0) this.sidenavServ.sendChangePreferitiFromSidenav();
        }
        localStorage.setItem('portafoglioHistory', JSON.stringify(this.portafoglioHistory))
      }
    })
  }

  closeAllExpansionPanel(){
    this.sidenavExpPanel.closeAll();
  }

  goToHome() {
    this.router.navigate(['home']);
    this.sharedVariables.changePasswordForceStatus = '';
    this.sharedVariables.changePasswordStatus = '';
    if (this.router.url == '/back-office' && !this.isMenuOpen) {
      setTimeout(() => {
        this.sidenavExpPanel.closeAll()
      }, 1);
    }
  }

  getUserDataFromQueryParams(){
    this.route.queryParams.subscribe(params => {
      //properties saved on local storage ONLY if query params are present (SSO login)
      if (params && params['info']){
        let encodedUserInfo = params['info'];
        let decodedUserInfo = window.atob(encodedUserInfo);
        let parsedUserInfo = JSON.parse(decodedUserInfo);

        if (parsedUserInfo.firstName) localStorage.setItem('firstName', parsedUserInfo.firstName)
        if (parsedUserInfo.lastName) localStorage.setItem('lastName', parsedUserInfo.lastName)
        if (parsedUserInfo.userId) localStorage.setItem('email', parsedUserInfo.userId)
        if (parsedUserInfo.role) localStorage.setItem('role', parsedUserInfo.role)
        if (parsedUserInfo.operationalUnit) localStorage.setItem('operationalUnit', parsedUserInfo.operationalUnit)
      }
    })
  }

  public get dataUltimoAggiornamento(): string | Date {
    let date: Date | string = "";
    if (this.sharedVariables.dashboardDataCache && this.sharedVariables.dashboardDataCache.data_ultima_estrazione && this.settingsServ.setting_config.date_settings.date_format) {
      date = this.datePipe.transform(this.sharedVariables.dashboardDataCache.data_ultima_estrazione, this.settingsServ.setting_config.date_settings.date_format)!
    }
    return date;
  }

  public get isLocalhost() : boolean {
    return location.hostname === 'localhost';
  }

  refreshButton() {
    window.location.reload()
  }

  checkRoute() {
    return ''
  }

  checkX() {
    console.log(this.router.url);

  }
}
