import { Pipe, PipeTransform } from '@angular/core';
import { ColumnController } from '../models/column-controller.model';
import { DettaglioPolizza } from '../models/dettaglio-polizza.model';
import { Person } from '../models/person.model';

@Pipe({
  name: 'dettaglioPolizzaLabelFormatter'
})
export class DettaglioPolizzaLabelFormatterPipe implements PipeTransform {

  transform(value: DettaglioPolizza | Person): ColumnController[] {
    let columns: string[] = Object.keys(value);
    let result: ColumnController[] = [];
    columns.forEach((element: string) => {
      switch (element) {
        //Polizza.model.ts
        case 'accumuloPremi': result.push({ value: element, label: 'Premi Investiti' }); break; //FIXME: Premi Versati prima
        case 'categoria': result.push({ value: element, label: 'Categoria' }); break;
        case 'causaleLife': result.push({ value: element, label: 'Causale Life' }); break; //TODO: da controllare
        case 'codAgenzia': result.push({ value: element, label: 'Codice Agenzia' }); break;
        case 'codRecord': result.push({ value: element, label: 'Codice Record' }); break;
        case 'codUniverso': result.push({ value: element, label: 'Codice Universo' }); break;
        case 'codiciISIN': result.push({ value: element, label: 'Codici ISIN' }); break;
        case 'compagnia': result.push({ value: element, label: 'Codice Compagnia' }); break;
        case 'controvalore': result.push({ value: element, label: 'Valore Complessivo Investimento' }); break;
        case 'dataControvalori': result.push({ value: element, label: 'Data Controvalori' }); break;
        case 'dataDecorrenza': result.push({ value: element, label: 'Data Decorrenza' }); break;
        case 'dataEstrazione': result.push({ value: element, label: 'Data Estrazione' }); break;
        case 'dataRiscatto': result.push({ value: element, label: 'Data Riscatto' }); break;
        case 'dataScadenza': result.push({ value: element, label: 'Data Scadenza' }); break;
        case 'descAgenzia': result.push({ value: element, label: 'Agenzia' }); break;
        case 'descStato': result.push({ value: element, label: 'Stato' }); break;
        case 'descTariffa': result.push({ value: element, label: 'Prodotto' }); break;
        case 'descTipoRateazione': result.push({ value: element, label: 'Frequenza Premio' }); break;
        case 'durataPagPre': result.push({ value: element, label: 'Durata Pag Pre' }); break; //TODO: da controllare
        case 'durataVitaPoli': result.push({ value: element, label: 'Durata Vita Polizza' }); break; //TODO: da controllare
        case 'filiale': result.push({ value: element, label: 'Filiale' }); break;
        case 'importoRiscatto': result.push({ value: element, label: 'Importo Liquidato Lordo' }); break; //FIXME: Totale Liquidazioni prima
        case 'listaOpzioni': result.push({ value: element, label: 'Lista Opzioni' }); break;
        case 'numPolizza': result.push({ value: element, label: 'Numero Polizza' }); break;
        case 'numProposta': result.push({ value: element, label: 'Numero Proposta' }); break;
        case 'premio_lordo_iniziale': result.push({ value: element, label: 'Premio Lordo Iniziale' }); break;
        case 'promotore': result.push({ value: element, label: 'Promotore' }); break;
        case 'statoLife': result.push({ value: element, label: 'Stato Life' }); break; //TODO: da controllare
        case 'statoPolizza': result.push({ value: element, label: 'Stato Polizza' }); break;
        case 'tariffa': result.push({ value: element, label: 'Tariffa' }); break;
        case 'tipoPolizza': result.push({ value: element, label: 'Tipo Polizza' }); break;
        case 'tipoRateazione': result.push({ value: element, label: 'Codice Tipo Rateazione' }); break;
        // case 'dControvaloreR1': result.push({ value: element, label: 'Data Controvalore Ramo I' }); break;
        // case 'dControvaloreR3': result.push({ value: element, label: 'Data Controvalore Ramo III' }); break;
        case 'controvalorePrecedente': result.push({ value: element, label: 'Controvalore Precedente' }); break;
        case 'descCompagnia': result.push({ value: element, label: 'Compagnia' }); break;
        // case 'iControvaloreR1': result.push({ value: element, label: 'Importo Controvalore Ramo I' }); break;
        // case 'iControvaloreR3': result.push({ value: element, label: 'Importo Controvalore Ramo III' }); break;
        case 'lineaVendita': result.push({ value: element, label: 'Linea Vendita' }); break;
        case 'ndgassicurato': result.push({ value: element, label: 'Ndg Assicurato' }); break;
        case 'ndgcontraente': result.push({ value: element, label: 'Ndg Contraente' }); break;
        case 'valoreRiscatto': result.push({ value: element, label: 'Valore Riscatto' }); break;
        // case 'dValoreRiscattoR1': result.push({ value: element, label: 'Data Valore Riscatto Ramo I' }); break;
        // case 'dValoreRiscattoR3': result.push({ value: element, label: 'Data Valore Riscatto Ramo III' }); break;
        // case 'iValoreRiscattoR1': result.push({ value: element, label: 'Importo Valore Riscatto Ramo I' }); break;
        // case 'iValoreRiscattoR3': result.push({ value: element, label: 'Importo Valore Riscatto Ramo III' }); break;
        case 'iLordoVersato': result.push({ value: element, label: 'Premi Versati' }); break;
        case 'iNettoPagato': result.push({ value: element, label: 'Importo Liquidato Netto' }); break;
        case 'abi': result.push({ value: element, label: 'Abi' }); break;
        case 'cab': result.push({ value: element, label: 'Cab' }); break;
        case 'mwrrComplessivoPercentuale': result.push({ value: element, label: 'Rendimento lordo complessivo dalla sottoscrizione'}); break;

        //person.model.ts
        case 'cap': result.push({ value: element, label: 'Cap' }); break;
        case 'codiceFiscale': result.push({ value: element, label: 'Codice Fiscale' }); break;
        case 'cognome': result.push({ value: element, label: 'Cognome' }); break;
        case 'dataNascita': result.push({ value: element, label: 'Data di Nascita' }); break;
        case 'domCap': result.push({ value: element, label: 'Domicilio Cap' }); break; //TODO: da controllare
        case 'domLocalita': result.push({ value: element, label: 'Domicilio Localita' }); break;
        case 'domProv': result.push({ value: element, label: 'Domicilio Provincia' }); break;
        case 'domVia': result.push({ value: element, label: 'Domicilio Via' }); break;
        case 'localita': result.push({ value: element, label: 'Localita' }); break;
        case 'luogoNascita': result.push({ value: element, label: 'Luogo di Nascita' }); break;
        case 'ndg': result.push({ value: element, label: 'Ndg' }); break; //TODO: da controllare
        case 'nome': result.push({ value: element, label: 'Nome' }); break;
        case 'paese': result.push({ value: element, label: 'Paese' }); break;
        case 'persona': result.push({ value: element, label: 'Persona' }); break;
        case 'prov': result.push({ value: element, label: 'Provincia' }); break;
        case 'provNascita': result.push({ value: element, label: 'Provincia di Nascita' }); break;
        case 'tipoCliente': result.push({ value: element, label: 'Tipo Cliente' }); break;
        case 'via': result.push({ value: element, label: 'Via' }); break;
      }
    });
    return result

  }

}
